;(function() {
"use strict";

/**
 * Lista Kandydatów do egzaminu online
 *
 * @Controller OnlineStudentSelectController
 * @module online
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineStudentSelectController', OnlineStudentSelectController);

    OnlineStudentSelectController.$inject = ['$scope', '$stateParams', 'onlineStudentService', '$uibModalInstance', 'tableDefinitions', 'examService'];
    function OnlineStudentSelectController($scope, $stateParams, onlineStudentService, $uibModalInstance, tableDefinitions, examService) {
        const vm = this;
        vm.collectionId = $stateParams.id;

        const formParams = {
            name: 'student',
            title: "{0} student {1}",
            restService: onlineStudentService,
        };

        vm.dictionary = {};
        examService.getFilter().then(
            function (response) {
                const dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    vm.dictionary.professions = dictionary.items;
                });
            },
            function () {
                console.log("Problem with fetching exam filter dict ...");
            }
        );

        const customSearch = [];
        customSearch.push({key: 'examScheduleId', value: vm.collectionId});
        customSearch.push({key: 'examScheduleMode', value: 'EXCLUDE'});
        vm.customSearch = customSearch;

        const studentTableDefinition = tableDefinitions.getTableDefinitionOnlineStudentSelect(formParams);

        vm.listingColumnDefinitions = studentTableDefinition.listingColumnDefinitions;
        vm.tableActions = studentTableDefinition.tableActions;
        vm.onlineStudentService = onlineStudentService;

        vm.search = function () {
            vm.customSearch = studentTableDefinition.searchByFields(vm);
        };

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
}());
