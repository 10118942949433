;(function() {
"use strict";

/**
 * Lista zadań do egzaminu online
 *
 * @Controller OnlineTaskController
 * @module online
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineTaskController', OnlineTaskController);

    OnlineTaskController.$inject = ['$scope','onlineScheduleService', '$uibModalInstance', 'tableDefinitions', 'formFactory', 'constants', 'watchService', 'notifyService', 'dataItem'];
    function OnlineTaskController($scope, onlineScheduleService, $uibModalInstance, tableDefinitions, formFactory, constants, watchService, notifyService, dataItem) {
        const vm = this;
        vm.dictionary = {};
        vm.searchFields = {};

        const formParams = {
            name: 'task',
            apiCall: 'getTasks',
            restService: onlineScheduleService,
            collectionId: dataItem.collectionId,
            studentId: dataItem.studentId
        };

        onlineScheduleService.getTaskFilter().then(
            function (response) {
                var dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    if (dictionary.name === 'profession') {
                        vm.dictionary.professions = dictionary.items;
                    }
                    if (dictionary.name === 'examMode') {
                        vm.dictionary.examModes = dictionary.items;
                    }
                    if (dictionary.name === 'topic') {
                        vm.dictionary.topics = dictionary.items;
                        vm.dictionary.topics.unshift({name: 'Temat ogólny', value: 0});
                    }
                    if (dictionary.name === 'taskLevel') {
                        vm.dictionary.taskLevels = dictionary.items;
                    }
                });
            },
            function (response, status) {}
        );

        const taskTableDefinition = tableDefinitions.getTableDefinitionOnlineTask(formParams);

        vm.listingColumnDefinitions = taskTableDefinition.listingColumnDefinitions;
        vm.tableActions = taskTableDefinition.tableActions;
        vm.onlineScheduleService = onlineScheduleService;

        vm.search = function () {
            vm.customSearch = taskTableDefinition.searchByFields(vm);
        };

        vm.close = function close() {
            $uibModalInstance.close();
        }

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
}());
