;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('mgmtProfessionService', MgmtProfessionService);

MgmtProfessionService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function MgmtProfessionService(http, utils, API_CONFIG) {
    const service = {
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem,
        activate: activate,
        deactivate: deactivate
    };

    return service;

    function getList(params) {
        const url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_PROFESSION, params);
        return http.get(url);
    }

    function get(id) {
        const url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_PROFESSION, id);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_PROFESSION;
        if (item.id) {
            url = '{0}/{1}'.format(url, item.id);
            return http.put(url, item);
        }

        return http.post(url, item);
    }

    function deleteItem(id) {
        const url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_PROFESSION, id);
        return http.delete(url);
    }

    function activate(id) {
        const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_PROFESSION, id, 'activate');
        return http.put(url);
    }

    function deactivate(id) {
        const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_PROFESSION, id, 'deactivate');
        return http.put(url);
    }

}
}());
