;(function() {
"use strict";

angular
    .module('ewr', [

        // angular modules
        'ui.router',
        'pascalprecht.translate',
        'ngDialog',
        'angular.filter',
        'ngMessages',
        'ui-notification',
        'ngCookies',
        'formly',
        'formlyBootstrap',
        'ui.bootstrap',
        'ngIdle',
        'angular-loading-bar',
        'angularFileUpload',
        'youtube-embed',
        'slickCarousel',

        // application modules that are reused in other views
         'ewr.layout',
         'ewr.common',

        // Moduły systemowe
        'ewr.auth',
        'ewr.resources',
        'ewr.organizations',
        'ewr.exams',
        'ewr.info',
        'ewr.admin',
        'ewr.home',
        'ewr.online'

    ])
    .config(configInternationalization)
    .config(configLogging)
    .config(configureSessionWatcher)
    .config(configLoadingBar)
    .run(checkLogin)
    .run(defineFormatPrototype)
    .run(configThumbDimension)
    .run(defineCookieValuePrototype)
    .run(validationMessages)
    .run(redirectTo)
    .run(configureForms)
    .run(googleAnalytics);



configInternationalization.$inject = ['$translateProvider'];

/**
 * Configures internationalization module
 * @param $translateProvider
 */
function configInternationalization($translateProvider) {
    $translateProvider
        .useStaticFilesLoader({
            prefix: 'assets/languages/locale-',
            suffix: '.json'
        })
        .useSanitizeValueStrategy('escape')
        .preferredLanguage('pl');
}

configLoadingBar.$inject = ['cfpLoadingBarProvider'];
function configLoadingBar(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.parentSelector = '#loading-bar-container';

}

configThumbDimension.$inject = ['$rootScope'];

function configThumbDimension($rootScope) {

    $rootScope.thumbDimension = {
        front: {
            resource: {
                width: 800,
                height: 450,
                details: {
                    width: 800,
                    height: 450
                }
            },
            carousel: {
                width: 1600,
                height: 1100
            },
            article: {
                width: 800,
                height: 450
            },
            page: {
                width: 1600,
                height: 200
            }
        },
        admin: {
            selectfile: {
                width: 100,
                height: 100
            }
        }
    }
}

configLogging.$inject = ['$logProvider'];

/**
 * Configures $log
 * @param $logProvider
 */
function configLogging($logProvider) {
    $logProvider.debugEnabled(true);
}

checkLogin.$inject = ['$rootScope', 'authenticationService'];

/**
 * Called on each state change. Checks if user is logged in. If not redirects to
 * login, and then, after successful login, to desired page. Uses authService.redirect to determine
 * that page.
 * @param $rootScope
 * @param authService
 * @param $window
 * @param url
 */
function checkLogin($rootScope, authenticationService) {
    $rootScope.$on('$stateChangeStart', function (event, toState) {
        if (toState.auth !== false && authenticationService.isAuthenticated() === false) {
            window.location = "/"
        }
    });
}

redirectTo.$inject = ['$rootScope', '$state'];

/**
 * Enables to define redirect state in state config
 * Used when parent has to redirect to default child
 * @param $rootScope
 * @param $state
 */
function redirectTo($rootScope, $state) {
    $rootScope.$on('$stateChangeStart', function (evt, to, params) {
        if (to.redirectTo) {
            evt.preventDefault();
            $state.go(to.redirectTo, params);
        }
    });
}


/**
 * Custom prototypes definitions.
 */


/**
 * Defines format function on String.prototype
 *
 * .format() usage:
 * '{0} is an awesome {1}'.format('js', 'language') will yield 'js is an awesome language'
 */
function defineFormatPrototype() {
    String.prototype.format = function () {
        var formatted = this;
        for (var i = 0; i < arguments.length; i++) {
            var regexp = new RegExp('\\{' + i + '\\}', 'gi');
            formatted = formatted.replace(regexp, arguments[i]);
        }
        return formatted;
    };
}

function defineCookieValuePrototype() {
    String.prototype.cookieValue = function () {
        var cookieValue = this;
        for (var i = 0; i < arguments.length; i++) {
            var name = arguments[i] + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }
            return "";
        }
        return cookieValue;
    };
}

/**
 * Configure Idle settings
 */
configureSessionWatcher.$inject = ['IdleProvider', 'constants'];
function configureSessionWatcher(IdleProvider, constants) {

    IdleProvider.idle(constants.SESSION_IDLE_TIME);
    IdleProvider.timeout(constants.SESSION_USER_ACTION_TIMEOUT);
    IdleProvider.keepalive(false);
}

validationMessages.$inject = ['formlyConfig', 'formlyValidationMessages'];
function validationMessages(formlyConfig, formlyValidationMessages) {
    formlyConfig.extras.ngModelAttrsManipulatorPreferBound = true;
    formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = function($viewValue, $modelValue, scope) {
        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
    };

    formlyValidationMessages.addStringMessage('maxlength', 'Wartość pola przekracza dozwoloną liczbę znaków');
    formlyValidationMessages.addStringMessage('minlength', 'Wartość pola jest zbyt króka');
    formlyValidationMessages.addStringMessage('required', 'Pole jest wymagane');
    formlyValidationMessages.addStringMessage('email', 'Nieprawidłowy adres e-mail');

    formlyValidationMessages.addTemplateOptionValueMessage('max', 'max', '', 'jest wartością max', 'Too big');
    formlyValidationMessages.addTemplateOptionValueMessage('min', 'min', '', 'jest wartością min', 'Too small');

}

googleAnalytics.$inject = ['$rootScope', '$location'];
function googleAnalytics($rootScope, $location) {
    $rootScope.$on('$stateChangeStart', function(event, toState) {
        ga('send', 'pageview', $location.path());

    });
}

/**
 * Customizacja pól formularzy.
 */
configureForms.$inject = ['formlyConfig'];
function configureForms(formlyConfig) {
    formlyConfig.setWrapper([{
        name: 'loader',
        template: [
            '<label for="{{::id}}">{{options.templateOptions.label}} {{options.templateOptions.required ? \'*\' : \'\'}}</label>',
            '<formly-transclude></formly-transclude>',
            '<span class="glyphicon glyphicon-refresh loader" ng-show="to.loading"></span>'
        ].join(' ')
    },{
        name: 'fa-user',
        template: [
            '<div class="input-group"><span class="input-group-addon"><i class="fa fa-user" aria-hidden="true"></i></span>',
            '<formly-transclude></formly-transclude>',
            '</div>'
        ].join(' ')
    }, ,{
        name: 'fa-lock',
        template: [
            '<div class="input-group"><span class="input-group-addon"><i class="fa fa-lock" aria-hidden="true"></i></span>',
            '<formly-transclude></formly-transclude>',
            '</div>'
        ].join(' ')
    }, {
        template: '<formly-transclude></formly-transclude><div my-messages="options"></div>',
        types: ['input', 'checkbox', 'select', 'textarea', 'radio', 'input-loader', 'input-fa-user']
    },{
        overwriteOk: true,
        template: [
            '<div class="form-ewr">',
            '<formly-transclude></formly-transclude>',
            '<ng-messages-include="assets/config/definitions/forms/templates/validation.html">',
            '<div class="validation-wrapper"',
            'ng-if="!options.validation.errorExistsAndShouldBeVisible">',
            '&nbsp',
            '</div>',
            '</ng-messages-include>',
            '<div class="validation"',
            'ng-if="options.validation.errorExistsAndShouldBeVisible"',
            'ng-messages="options.formControl.$error">',
            '<div ng-message="{{::name}}" ng-repeat="(name, message) in ::options.validation.messages" class="form-validation-msg">',
            '{{message(options.formControl.$viewValue, options.formControl.$modelValue, this)}}',
            '</div>',
            '</div>',
            '</div>'
        ].join(' ')
    }]);
    formlyConfig.setType([
    {
        name: 'ckeditor',
        templateUrl: 'assets/config/definitions/forms/templates/ckeditor.html',
        overwriteOk: true
    },
    {
        name: 'password-reminder',
        templateUrl: 'assets/config/definitions/forms/templates/password-reminder.html',
        overwriteOk: true
    },{
        name: 'selectfile',
        templateUrl: 'assets/config/definitions/forms/templates/selectfile.html',
        overwriteOk: true,
        wrapper: ['loader']
    },{
        name: 'input-loader',
        extends: 'input',
        wrapper: ['loader']
    },{
        name: 'input-fa-user',
        extends: 'input',
        wrapper: ['fa-user']
    },{
        name: 'input-fa-lock',
        extends: 'input',
        wrapper: ['fa-lock']
    },{
        name: 'datepicker',
        templateUrl: 'assets/config/definitions/forms/templates/datepicker.html',
        controller: ['$scope', function ($scope) {
            $scope.datepicker = {};
            $scope.datepicker.opened = false;
            $scope.datepicker.open = function ($event) {
                $scope.datepicker.opened = !$scope.datepicker.opened;
            };
        }]
    },{
        name: 'list',
        templateUrl: 'assets/config/definitions/forms/templates/list.html',
        overwriteOk: true
    }
    ]);
}
}());
