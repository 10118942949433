;(function() {
"use strict";

angular
    .module('ewr.common')
    .filter('cdnUrl', cdnUrl);

cdnUrl.$inject = ['API_CONFIG'];

function cdnUrl(API_CONFIG) {
    return function(input, width, height) {
      if (! input) {
        return;
      }
      var imageSrc = API_CONFIG().CDN_URL;
      if(width && height) {
        imageSrc += "{0}x{1}_".format(width, height);
      }
      return "{0}{1}".format(imageSrc, input);
    };
}
}());
