;(function() {
"use strict";

angular
    .module('ewr.common')
    .filter('firstLetter', firstLetter)
    .filter('stringLimit', stringLimit)
    .filter('makeLowercase', makeLowercase)
    .filter('trustHtml', trustHtml);

stringLimit.$inject = ['$filter'];

function stringLimit($filter) {
    return function(input, limit) {
      if (! input) {
        return;
      }
      if (input.length <= limit) {
          return input;
      }
      return $filter('limitTo')(input, limit) + '...';
    };
}

function firstLetter() {
    return function(text) {
        if(text && text.length > 0) {
            return text[0];
        }
        return text;
    };
}

function makeLowercase() {
    return function(text) {
        if(text && text.length > 0) {
            return text.toLowerCase();
        }
        return text;
    };
}

trustHtml.$inject = ['$sce'];
function trustHtml($sce) {
    return function(text) {
        return $sce.trustAsHtml(text);
    };
}
}());
