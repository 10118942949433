;(function() {
"use strict";

/**
 * Fotografie.
 *
 * @Controller PhotosController
 * @module resources
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('PhotosController', PhotosController);

    PhotosController.$inject = ['CONFIG_TABLE', 'resourceService', 'slideshowService'];
    function PhotosController(CONFIG_TABLE, resourceService, slideshowService) {
        var vm = this;
        vm.items = new Array();
        vm.paginate = paginate;
        vm.loadData = loadData;
        vm.openSlideshow = openSlideshow;

        vm.loadData(urlParams);

        var urlParams = {
            offset: 0,
            limit: CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE,
        };

        /**
         *
         * @param offset
         */
        function paginate(offset) {
            urlParams.offset = offset;
            urlParams.limit = CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE;
            vm.loadData(urlParams);
            return;
        }

        /**
         *
         * @param urlParams
         */
        function loadData(urlParams) {
            resourceService.getPhotos(urlParams).then(
                function (response) {
                    vm.items = response.data.items;
                    vm.meta = response.data.meta;
                },
                function (response, status) {
                }
            );
        }

        /**
         *
         * @param item
         * @param $event
         */
        function openSlideshow(item, $event) {
            $event.preventDefault();

            slideshowService.open(
                {
                    items: vm.items,
                    selectedItem: item
                }
            );
        }
    }
})();
}());
