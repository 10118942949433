;(function() {
"use strict";

/**
 * Lista Kandydatów do egzaminu online
 *
 * @Controller OnlineStudentController
 * @module online
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineStudentController', OnlineStudentController);

    OnlineStudentController.$inject = ['$scope','onlineStudentService', 'tableDefinitions', 'examService'];
    function OnlineStudentController($scope, onlineStudentService, tableDefinitions, examService) {
        const vm = this;

        const formParams = {
            name: 'student',
            title: "{0} kandydata {1}",
            restService: onlineStudentService,
            show: false
        };

        vm.dictionary = {};
        examService.getFilter().then(
            function (response) {
                const dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    vm.dictionary.professions = dictionary.items;
                });
            },
            function () {
                console.log("Problem with fetching exam filter dict ...");
            }
        );

        const studentTableDefinition = tableDefinitions.getTableDefinitionOnlineStudent(formParams);

        vm.listingColumnDefinitions = studentTableDefinition.listingColumnDefinitions;
        vm.tableActions = studentTableDefinition.tableActions;
        vm.onlineStudentService = onlineStudentService;

        vm.search = function () {
            vm.customSearch = studentTableDefinition.searchByFields(vm);
        };

    }
})();
}());
