;(function() {
"use strict";

/**
 * Przykładowe zadanie egzaminacyjne.
 *
 * @Controller ExamSamplesController
 * @module resources
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('ExamSamplesController', ExamSamplesController);

    ExamSamplesController.$inject = ['PAGES', 'testService'];
    function ExamSamplesController(PAGES, testService) {
        var vm = this;
        vm.PAGES = PAGES;
        vm.test = null;

        vm.test = null;
        testService.getTest().then(
            function (response) {
                vm.test = response.data;
            },
            function (response, status) {
                // TODO
            }
        );

    }
})();
}());
