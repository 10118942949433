;(function() {
"use strict";

/**
 * Serwis obsługujący definicje tabel.
 *
 * @service tableDefinitions
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('tableDefinitions', tableDefinitions);

tableDefinitions.$inject = ['$sce', '$rootScope', '$compile', '$state', '$uibModal', 'CONFIG_TABLE_TYPES', 'formFactory', 'Notification', 'constants', 'watchService', 'tableFiltersService', 'confirmDialogService', 'labels', 'examService', 'testService', 'fileUtils', 'notifyService', 'tooltipService'];

function tableDefinitions($sce, $rootScope, $compile, $state, $uibModal, CONFIG_TABLE_TYPES, formFactory, Notification, constants, watchService, tableFiltersService, confirmDialogService, labels, examService, testService, fileUtils, notifyService, tooltipService) {
    const service = {

        // organization table
        getTableDefinitionOrgMessage: getTableDefinitionOrgMessage,
        getTableDefinitionOrgProfession: getTableDefinitionOrgProfession,
        getTableDefinitionOrgCommission: getTableDefinitionOrgCommission,

        // admin table
        getTableDefinitionAdminArticle: getTableDefinitionAdminArticle,
        getTableDefinitionAdminPage: getTableDefinitionAdminPage,
        getTableDefinitionAdminCarousel: getTableDefinitionAdminCarousel,
        getTableDefinitionAdminMessage: getTableDefinitionAdminMessage,
        getTableDefinitionAdminRole: getTableDefinitionAdminRole,
        getTableDefinitionAdminUser: getTableDefinitionAdminUser,
        getTableDefinitionAdminProfession: getTableDefinitionAdminProfession,
        getTableDefinitionAdminFile: getTableDefinitionAdminFile,
        getTableDefinitionAdminResource: getTableDefinitionAdminResource,

        // exams table
        getTableDefinitionMgmtTask: getTableDefinitionMgmtTask,
        getTableDefinitionMgmtTestCollection: getTableDefinitionMgmtTestCollection,
        getTableDefinitionMgmtTestCollectionTask: getTableDefinitionMgmtTestCollectionTask,
        getTableDefinitionMgmtExamCollection: getTableDefinitionMgmtExamCollection,
        getTableDefinitionMgmtExamCollectionTask: getTableDefinitionMgmtExamCollectionTask,
        getTableDefinitionMgmtCollectionTask: getTableDefinitionMgmtCollectionTask,

        // public table
        getTableDefinitionCommission: getTableDefinitionCommission,

        // online exam tables
        getTableDefinitionOnlineStudent: getTableDefinitionOnlineStudent,
        getTableDefinitionOnlineStudentSelect: getTableDefinitionOnlineStudentSelect,
        getTableDefinitionOnlineSchedule: getTableDefinitionOnlineSchedule,
        getTableDefinitionOnlineScheduleCollectionTask: getTableDefinitionOnlineScheduleCollectionTask,
        getTableDefinitionOnlineExaminatorBoard: getTableDefinitionOnlineExaminatorBoard,
        getTableDefinitionOnlineTask: getTableDefinitionOnlineTask
    };

    return service;

    function getTableDefinitionOrgMessage(formParams) {

        return {
            listingColumnDefinitions : {
                title: {
                    value: 'Tytuł', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false,
                    filter: function(item) {
                        return item.read === true ? item.title : ('<strong>' + item.title + '</strong>');
                    },
                    tooltip: function(item) {
                        return item.title;
                    }
                },
                createDate: {
                    value: 'Data', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.date(item.createDate, constants.TABLE_DATE_FORMAT);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;

                        if (item.read !== true) {
                            // oznacz wiadomość jako przeczytaną zanim wyświetlimy dialog
                            formParams.restService.markAsRead(item.id).then(function (response) {
                                $uibModal.open({
                                    templateUrl: function () {
                                        return 'app/organizations/templates/message/preview.html';
                                    },
                                    controller: 'OrgMessagePreviewModalInstanceController',
                                    controllerAs: 'vm',
                                    resolve: {
                                        data: function () {
                                            return item;
                                        }
                                    }
                                });
                            });
                        } else {
                            $uibModal.open({
                                templateUrl: function () {
                                    return 'app/organizations/templates/message/preview.html';
                                },
                                controller: 'OrgMessagePreviewModalInstanceController',
                                controllerAs: 'vm',
                                resolve: {
                                    data: function () {
                                        return item;
                                    }
                                }
                            });
                        }

                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'ORGANIZATION_MESSAGE_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto wiadomość");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                return []; //customSearch
            }
        }
    }

    /**
     * Izby - tabela zawodów
     */
    function getTableDefinitionOrgProfession(formParams) {

        return {
            listingColumnDefinitions : {
                'profession.name': {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false
                },
                mode: {
                    value: 'Rodzaj egzaminu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                },
                'profession.identifier': {
                    value: 'Numer standardu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.profession.identifier);
                    }
                },
                'profession.standardCode': {
                    value: 'Symbol cyfrowy', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.profession.standardCode);
                    }
                }
            },
            tableActions : [
            ],
            searchByFields : function () {
                return []; //customSearch
            }
        }
    }

    /**
     * Izby - tabela komisji egzaminacyjnych
     */
    function getTableDefinitionOrgCommission(formParams) {

        return {
            listingColumnDefinitions : {
                'profession.name': {
                    value: 'Komisja w zawodzie', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false
                },
                mode: {
                    value: 'Rodzaj egzaminu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'ORGANIZATION_COMMITTEE_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'ORGANIZATION_COMMITTEE_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto komisję egzaminacyjną");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                return []; //customSearch
            }
        }
    }

    function getTableDefinitionAdminArticle(formParams) {

        return {
            listingColumnDefinitions : {

                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.nullValue(item.id);
                    }
                },
                title: {
                    value: 'Tytuł', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                publishDate: {
                    value: 'Data publikacji', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: true, filter: function(item) {
                        return tableFiltersService.date(item.publishDate, constants.TABLE_DATE_FORMAT);
                    }
                },
                published: {
                    value: 'Opublikowany', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.trueFalse(item.published);
                    }
                },
                archival: {
                    value: 'Archiwalny', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.trueFalse(item.archival);
                    }
                }

            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'ARTICLE_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'publishAction',
                    permissions: 'ARTICLE_MANAGEMENT_PUBLISH,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.published !== true) {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="publishAction({0})" title="Publikuj"> <i class="fa fa-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.publish(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Opublikowano artykuł");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'deactiveAction',
                    permissions: 'ARTICLE_MANAGEMENT_DEACTIVATE,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.published === true) {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deactiveAction({0})" title="Dezaktywuj"> <i class="fa fa-circle-o fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deactivate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Dezaktywowano artykuł");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'ARTICLE_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto artykuł");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                const customSearch = [];
                if (vm.filterParams.archival) {
                    customSearch.push({key: 'archival', value: vm.filterParams.archival});
                }
                if (vm.filterParams.organizationAssigned) {
                    customSearch.push({key: 'organizationAssigned', value: vm.filterParams.organizationAssigned});
                }
                if (vm.filterParams.organizationId) {
                    customSearch.push({key: 'organizationId', value: vm.filterParams.organizationId});
                }
                return customSearch;
            }
        }
    }

    /**
     * Administracja - tabela stron
     */
    function getTableDefinitionAdminPage(formParams) {

        return {
            listingColumnDefinitions : {

                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                name: {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-10',
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'PAGE_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                }
            ],
            searchByFields : function (vm) {
                return []; //customSearch
            }
        }
    }

    /**
     * Administracja - tabela karuzeli
     */
    function getTableDefinitionAdminCarousel(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                orderNo: {
                    value: 'Kolejność', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.nullValue(item.orderNo);
                    }
                },
                header: {
                    value: 'Nagłówek', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                published: {
                    value: 'Opublikowany', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.trueFalse(item.published);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'CAROUSEL_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'CAROUSEL_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto pozycję");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'activateAction',
                    permissions: 'CAROUSEL_MANAGEMENT_ACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.published === false) {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="activateAction({0})" title="Aktywuj"> <i class="fa fa-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.activate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Aktywny");
                                });
                            }
                        };
                        confirmDialogService.open(params);

                    }
                },
                {
                    actionName: 'deactiveAction',
                    permissions: 'CAROUSEL_MANAGEMENT_DEACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.published === true) {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deactiveAction({0})" title="Dezaktywuj"> <i class="fa fa-circle-o fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deactivate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Nieaktywny");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                return customSearch;
            }
        }
    }

    /**
     * Administracja - tabela korespondencji
     */
    function getTableDefinitionAdminMessage(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1',
                },
                title: {
                    value: 'Tytuł', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-9',
                },
                createDate: {
                    value: 'Data', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.date(item.createDate, constants.TABLE_DATE_FORMAT);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;

                        $uibModal.open({
                            templateUrl: function () {
                                return 'app/admin/templates/message/preview.html';
                            },
                            controller: 'AdminMessagePreviewModalInstanceController',
                            controllerAs: 'vm',
                            resolve: {
                                data: function () {
                                    return item;
                                }
                            }
                        });
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'MESSAGE_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto wiadomość");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                return customSearch;
            }
        }
    }

    /**
     * Administracja - tabela ról
     */
    function getTableDefinitionAdminRole(formParams) {

        return {
            listingColumnDefinitions : {

                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                name: {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-4',
                },
                createDate: {
                    value: 'Data utworzenia', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-3', filter: function(item) {
                        return tableFiltersService.date(item.createDate, constants.TABLE_DATE_FORMAT);
                    }
                },
                modifyDate: {
                    value: 'Data modyfikacji', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-3', filter: function(item) {
                        return tableFiltersService.date(item.modifyDate, constants.TABLE_DATE_FORMAT);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'ROLE_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formParams['dictName'] = constants.FORM_DICT_ROLE;

                        formParams['fieldsTranform'] = {
                            onRead: [
                                {
                                    permissions: function (permissionField) {
                                        var model = angular.copy(permissionField);
                                        var transformedValue = [];
                                        angular.forEach(model, function (object) {
                                            transformedValue.push(object.id.toString());
                                        });
                                        return transformedValue;
                                    }
                                }
                            ],
                            onSave: [
                                {
                                    permissions: function (permissionField, key, dict) {
                                        return permissionField;
                                    }
                                }
                            ]
                        };


                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'ROLE_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.code === 'GUEST' || item.code === 'ADMIN') {
                            Notification.error("Nie można usunąć predefiniowanej roli");
                        } else {
                            var params = {
                                title: labels.CONFIRMATION_DIALOG_TITLE,
                                confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                                confirmationAction: function (modal) {
                                    formParams.restService.deleteItem(item.id).then(function (response) {
                                        modal.dismiss('cancel');
                                        watchService.reload();
                                        Notification.success("Usunięto uprawnienie");
                                    });
                                }
                            };
                            confirmDialogService.open(params);
                        }
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.search.name) {
                    customSearch.push({key: 'name', value: vm.search.name});
                }
                return customSearch;
            }
        }
    }

    /**
     * Administracja - tabela użytkowników
     */
    function getTableDefinitionAdminUser(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                firstName: {
                    value: 'Imię', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                lastName: {
                    value: 'Nazwisko', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                login: {
                    value: 'Login', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                email: {
                    value: 'Email', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.userStatus(item.status);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'USER_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formParams['dictName'] = constants.FORM_DICT_USER;
                        formParams['fieldsTranform'] = {
                            onRead: [
                                {
                                    roles: function (roleField) {
                                        var model = angular.copy(roleField);
                                        var transformedValue = [];
                                        angular.forEach(model, function (object) {
                                            transformedValue.push(object.id.toString());
                                        });
                                        return transformedValue;
                                    },
                                    organization: function (organizationField) {
                                        var model = angular.copy(organizationField);
                                        var transformedValue = [];
                                        angular.forEach(model, function (object) {
                                            transformedValue.push(object.id.toString());
                                        });
                                        return transformedValue;
                                    }
                                }
                            ],
                            onSave: [
                                {
                                    roles: function (roleField, key, dict) {
                                        return roleField;
                                    },
                                    organization: function (organizationField, key, dict) {
                                        return organizationField;
                                    }
                                }
                            ]
                        };

                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'USER_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto konto");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'lockAction',
                    permissions: 'USER_MANAGEMENT_LOCK',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="lockAction({0})" title="Blokuj"> <i class="fa fa-lock fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.lock(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Zablokowano konto");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'unlockAction',
                    permissions: 'USER_MANAGEMENT_UNLOCK',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status !== 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="unlockAction({0})" title="Odblokuj"> <i class="fa fa-unlock fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.unlock(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Odblokowano konto");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'resetPasswordAction',
                    permissions: 'USER_MANAGEMENT_RESET_PASSWORD',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="resetPasswordAction({0})" title="Resetuj hasło"> <i class="fa fa-undo fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.resetPassword(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Zresetowano hasło");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.search.firstName) {
                    customSearch.push({key: 'firstName', value: vm.search.firstName});
                }
                if (vm.search.lastName) {
                    customSearch.push({key: 'lastName', value: vm.search.lastName});
                }
                if (vm.search.login) {
                    customSearch.push({key: 'login', value: vm.search.login});
                }
                if (vm.search.email) {
                    customSearch.push({key: 'email', value: vm.search.email});
                }
                return customSearch;
            }
        }
    }

    /**
     * Administracja - tabela zawodów
     */
    function getTableDefinitionAdminProfession(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                name: {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-6',
                    tooltip: function(item) {
                        return item.name;
                    }
                },
                identifier: {
                    value: 'Numer standardu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.identifier);
                    }
                },
                standardCode: {
                    value: 'Symbol cyfrowy', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.standardCode);
                    }
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.professionStatus(item.status);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'PROFESSION_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'PROFESSION_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto zawód");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'activateAction',
                    permissions: 'PROFESSION_MANAGEMENT_ACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status !== 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="activateAction({0})" title="Aktywuj"> <i class="fa fa-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        // TODO add confirm dialog
                        formParams.restService.activate(item.id).then(function(response) {
                            watchService.reload();
                            Notification.success("Ustawiono status Aktywny");
                        });
                    }
                },
                {
                    actionName: 'deactiveAction',
                    permissions: 'PROFESSION_MANAGEMENT_DEACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deactiveAction({0})" title="Dezaktywuj"> <i class="fa fa-circle-o fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        // TODO add confirm dialog
                        formParams.restService.deactivate(item.id).then(function(response) {
                            watchService.reload();
                            Notification.success("Ustawiono status Nieaktywny");
                        });
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.search.name) {
                    customSearch.push({key: 'name', value: vm.search.name});
                }
                if (vm.search.standardCode) {
                    customSearch.push({key: 'standardCode', value: vm.search.standardCode});
                }
                if (vm.search.identifier) {
                    customSearch.push({key: 'identifier', value: vm.search.identifier});
                }
                return customSearch;
            }
        }
    }


    /**
     * Online - tabela kandydatów do egzaminu online
     */
    function getTableDefinitionOnlineStudent(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                surname: {
                    value: 'Imię i nazwisko', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1', filter: function(item) {
                        return "{0} {1}".format(item.forename, item.surname);
                    }
                },
                email: {
                    value: 'Adres e-mail', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return item.email;
                    }
                },
                phone: {
                    value: 'Telefon', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.phone);
                    }
                },
                pesel: {
                    value: 'PESEL', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: true, css: 'col-sm-1', filter: function(item) {
                        return item.pesel;
                    }
                },
                status: {
                    value: 'status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1', filter: function(item) {
                        return item.taskStatus !== null ?
                                tableFiltersService.scheduleStudentTaskStatus(item.taskStatus)
                                :
                                tableFiltersService.studentStatus(item.status);
                    }
                },
                score: {
                    value: 'Ocena', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1', show: formParams.show, filter: function(item) {
                        if (formParams.show === true) {
                            return tableFiltersService.examScore(item.score);
                        }
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'STUDENT_MANAGEMENT_UPDATE,',
                    condition: function(serializedItem) {
                        if (typeof formParams.isExamDetails === 'undefined' || formParams.isExamDetails === false) {
                            return true;
                        } else if (formParams.isExamDetails === true) {
                            return false;
                        }
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status !== 'VERIFIED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'STUDENT_MANAGEMENT_DELETE,',
                    condition: function(serializedItem) {
                        if (typeof formParams.isExamDetails === 'undefined' || formParams.isExamDetails === false) {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto kandydata");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'verifyAction',
                    permissions: 'STUDENT_MANAGEMENT_VERIFIED,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (typeof formParams.isExamDetails === 'undefined' || formParams.isExamDetails === false) {
                            if (item.status === 'VERIFIED') {
                                return false;
                            }
                            return true;
                        } else if (formParams.isExamDetails === true) {
                            return false;
                        }
                        return false;
                    },
                    button: '<a href ng-click="verifyAction({0})" title="Zweryfikuj"> <i class="fa fa-check fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams.restService.verified(item.id).then(function(response) {
                            watchService.reload();
                            Notification.success("Zweryfikowano kandydata");
                        });
                    }
                },
                {
                    actionName: 'blockedAction',
                    permissions: 'STUDENT_MANAGEMENT_BLOCKED,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (typeof formParams.isExamDetails === 'undefined' || formParams.isExamDetails === false) {
                            if (item.status === 'BLOCKED') {
                                return false;
                            }
                            return true;
                        } else if (formParams.isExamDetails === true) {
                            return false;
                        }
                        return false;
                    },
                    button: '<a href ng-click="blockedAction({0})" title="Zablokuj"> <i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams.restService.blocked(item.id).then(function(response) {
                            watchService.reload();
                            Notification.success("Zablokowano kandydata");
                        });
                    }
                },
                {
                    actionName: 'unBlockedAction',
                    permissions: 'STUDENT_MANAGEMENT_APPROVE,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (typeof formParams.isExamDetails === 'undefined' || formParams.isExamDetails === false) {
                            if (item.status === 'BLOCKED') {
                                return true;
                            }
                            return false;
                        } else if (formParams.isExamDetails === true) {
                            return false;
                        }
                        return false;
                    },
                    button: '<a href ng-click="unBlockedAction({0})" title="Odblokuj"> <i class="fa fa-reply fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams.restService.unBlocked(item.id).then(function(response) {
                            watchService.reload();
                            Notification.success("Odblokowano kandydata");
                        });
                    }
                },
                {
                    actionName: 'removeFromExamAction',
                    permissions: 'SCHEDULE_MANAGEMENT_REMOVE_STUDENT,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (formParams.isExamDetails === true && item.examStatus === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="removeFromExamAction({0})" title="Usuń z egzaminu"> <i class="fa fa-minus-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_REMOVE_FROM_EXAM_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_REMOVE_FROM_EXAM_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.removeFromExam(formParams.collectionId, item.id).then(function (response) {
                                    watchService.reload();
                                    modal.dismiss('cancel');
                                    Notification.success("Usunięto kandydata z egzaminu");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'editCollection',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_COLLECTION,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.taskStatus === 'TO_APPROVE') {
                            return true;
                        }
                       return false;
                    },
                    button: '<a href ng-click="editCollection({0})" title="Zarządzanie zestawem egzaminacyjnym"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var modalInstance = $uibModal.open({
                            templateUrl: function () {
                                return 'app/online/templates/schedule/collection.html';
                            },
                            controller: 'OnlineScheduleCollectionController',
                            controllerAs: 'vm',
                            size: constants.MODAL_SIZE_LARGE,
                            resolve: {
                                dataItem: function () {
                                    return item;
                                }
                            }
                        });
                        modalInstance.result.then(function() {
                            watchService.reload();
                        }, function() {
                            watchService.reload();
                        });
                    }
                },
                {
                    actionName: 'approveTaskCollectionAction',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_TASK_APPROVE,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.taskStatus === 'TO_APPROVE') {
                            return true;
                        }
                       return false;
                    },
                    button: '<a href ng-click="approveTaskCollectionAction({0})" title="Zaakceptuj zestaw egzaminacyjny"> <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                       const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                       formParams.restService.approveTaskCollection(formParams.collectionId, item.id).then(function (response) {
                          watchService.reload();
                          Notification.success("Zaakceptowano zestaw egzaminacyjny");
                       });
                    }
                },
                {
                    actionName: 'toApproveTaskCollectionAction',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_TASK_APPROVE,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.taskStatus === 'APPROVED' && item.examStatus === 'CREATED') {
                            return true;
                        }
                       return false;
                    },
                    button: '<a href ng-click="toApproveTaskCollectionAction({0})" title="Przekaż do akceptacji zestawu"> <i class="fa fa-mail-reply fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                       const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                       formParams.restService.toApproveTaskCollection(formParams.collectionId, item.id).then(function (response) {
                          watchService.reload();
                          Notification.success("Zmieniono status zestawu egzaminacyjnego");
                       });
                    }
                },
                {
                    actionName: 'showScoreDetails',
                    permissions: 'SCHEDULE_MANAGEMENT_PREVIEW,',
                    condition: function(serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.examStatus === 'FINISHED') {
                            return true;
                        }
                       return false;
                    },
                    button: '<a href ng-click="showScoreDetails({0})" title="Szczegółowe wyniki egzaminu"> <i class="fa fa-bar-chart fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var modalInstance = $uibModal.open({
                            templateUrl: 'app/online/templates/schedule/exam-score.html',
                            controller: 'ExamScoreController',
                            controllerAs: 'vm',
                            animation: true,
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    var dataItem = {
                                        studentId:item.id,
                                        examId: formParams.collectionId
                                    }
                                    return dataItem;
                                }
                            }
                        });
                    }
                }
            ],
            searchByFields : function (vm) {
                const customSearch = [];
                if (vm.search.surname) {
                    customSearch.push({key: 'surname', value: vm.search.surname});
                }
                if (vm.search.forename) {
                    customSearch.push({key: 'forename', value: vm.search.forename});
                }
                if (vm.search.pesel) {
                    customSearch.push({key: 'pesel', value: vm.search.pesel});
                }
                if (vm.search.email) {
                    customSearch.push({key: 'email', value: vm.search.email});
                }
                if (vm.search.phone) {
                    customSearch.push({key: 'phone', value: vm.search.phone});
                }
                if (vm.search.status) {
                    customSearch.push({key: 'status', value: vm.search.status});
                }
                if (vm.search.profession) {
                    customSearch.push({key: 'professionId', value: vm.search.profession});
                }
                return customSearch;
            }
        }
    }

    /**
     * Online - tabela kandydatów (select) do egzaminu online
     */
    function getTableDefinitionOnlineStudentSelect(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                surname: {
                    value: 'Imię i nazwisko', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return "{0} {1}".format(item.forename, item.surname);
                    }
                },
                email: {
                    value: 'Adres e-mail', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return item.email;
                    }
                },
                phone: {
                    value: 'Telefon', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.phone);
                    }
                },
                pesel: {
                    value: 'PESEL', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: true, css: 'col-sm-1', filter: function(item) {
                        return item.pesel;
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'addAction',
                    permissions: 'SCHEDULE_MANAGEMENT_ADD_STUDENT,',
                    button: '<a href ng-click="addAction({0})" title="Dodaj"> <i class="fa fa-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var modalInstance = $uibModal.open({
                            templateUrl: function () {
                                return 'app/online/templates/schedule/student-add.html';
                            },
                            controller: 'OnlineScheduleStudentAddController',
                            controllerAs: 'vm',
                            resolve: {
                                dataItem: function () {
                                    return item;
                                }
                            }
                        });
                        modalInstance.result.then(function() {
                            watchService.reload();
                        }, function() {
                            watchService.reload();
                        });
                    }
                }
            ],
            searchByFields : function (vm) {
                const customSearch = [];
                customSearch.push({key: 'examScheduleId', value: vm.collectionId});
                customSearch.push({key: 'examScheduleMode', value: 'EXCLUDE'});

                if (vm.search.surname) {
                    customSearch.push({key: 'surname', value: vm.search.surname});
                }
                if (vm.search.forename) {
                    customSearch.push({key: 'forename', value: vm.search.forename});
                }
                if (vm.search.pesel) {
                    customSearch.push({key: 'pesel', value: vm.search.pesel});
                }
                if (vm.search.phone) {
                    customSearch.push({key: 'phone', value: vm.search.phone});
                }
                if (vm.search.profession) {
                    customSearch.push({key: 'professionId', value: vm.search.profession});
                }
                return customSearch;
            }
        }
    }

    /**
     * online - tabela prezentująca terminy examinów online
     */
    function getTableDefinitionOnlineSchedule(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                registerNo: {
                    value: 'Nr. rejestru', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.registerNo);
                    }
                },
                profession: {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.profession);
                    }
                },
                examDate: {
                    value: 'Termin egzaminu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                    tooltip: function(item) {
                        return tableFiltersService.date(item.examDate, constants.TABLE_DATE_FORMAT);
                    }
                },
                examMode: {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1',
                        filter: function(item) {
                            return tableFiltersService.examMode(item.examMode);
                        },
                        tooltip: function(item) {
                            return tableFiltersService.examMode(item.examMode);
                        }
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, css: 'col-sm-1',
                    filter: function(item) {
                        return tableFiltersService.scheduleStatus(item.status);
                    },
                    tooltip: function(item) {
                        return tableFiltersService.scheduleStatus(item.status);
                    }
                },
                place: {
                    value: 'Miejsce egzaminu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.place);
                    }
                },
                studentNo: {
                    value: 'Liczba kandydatów', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return item.studentNo;
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'SCHEDULE_MANAGEMENT_UPDATE',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $state.go('onlineScheduleDetails', {id: item.id});
                    }
                },
                {
                    actionName: 'previewAction',
                    permissions: 'SCHEDULE_MANAGEMENT_PREVIEW',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status !== 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $state.go('onlineScheduleDetails', {id: item.id});
                    }
                },
                {
                    actionName: 'examAction',
                    permissions: 'ONLINE_EXAM_MANAGEMENT',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'APPROVED' || item.status === 'IN_PROGRESS' || item.status === 'FINISHED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="examAction({0})" title="Egzamin"> <i class="fa fa-list fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $state.go('onlineScheduleExam', {id: item.id});
                    }
                }
            ],
            searchByFields : function (vm) {
                const customSearch = [];
                if (vm.filterParams.professionId) {
                    customSearch.push({key: 'profession', value: vm.filterParams.professionId});
                }
                if (vm.filterParams.examDate) {
                    customSearch.push({key: 'examDate', value: vm.filterParams.examDate});
                }
                if (vm.filterParams.examMode) {
                    customSearch.push({key: 'examMode', value: vm.filterParams.examMode});
                }
                if (vm.filterParams.status) {
                    customSearch.push({key: 'status', value: vm.filterParams.status});
                }

                return customSearch;
            }
        }
    }

    /**
     * Egzaminy online - tabela zadań zestawu egzaminacyjnego online
     */
    function getTableDefinitionOnlineScheduleCollectionTask(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false,
                    tooltipPlacement: 'right',
                    tooltip: function(item) {
                        return tooltipService.taskTooltip(item);
                    }
                },
                orderNo: {
                    value: 'Kolejność', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.orderNo);
                    }
                },
                topic: {
                    value: 'Temat', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-6', sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.topic);
                    }
                },
                level: {
                    value: 'Poziom', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examLevel(item.level);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $uibModal.open({
                            templateUrl: function () {
                                return 'app/online/templates/schedule/collection-task-preview.html';
                            },
                            controller: 'OnlineScheduleCollectionTaskPreviewController',
                            controllerAs: 'vm',
                            resolve: {
                                data: function () {
                                    return item;
                                }
                            }
                        });
                    }
                },
                {
                    actionName: 'changeOrderUp',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_COLLECTION,',
                    button: '<a href ng-click="changeOrderUp({0})" title="Zmień kolejność o jedną pozycje w górę"> <i class="fa fa-arrow-circle-up fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var data = {orderDirection: 'UP'};
                        formParams.restService.changeCollectionTaskOrderDirection(formParams.collectionId, item.id, data).then(
                            function (response) {
                                watchService.reload();
                                Notification.success("Zmieniono kolejność zadania");
                            }
                        );
                    }
                },
                {
                    actionName: 'changeOrderDown',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_COLLECTION,',
                    button: '<a href ng-click="changeOrderDown({0})" title="Zmień kolejność o jedną pozycje w dół"> <i class="fa fa-arrow-circle-down fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var data = {orderDirection: 'DOWN'};
                        formParams.restService.changeCollectionTaskOrderDirection(formParams.collectionId, item.id, data).then(
                            function (response) {
                                watchService.reload();
                                Notification.success("Zmieniono kolejność zadania");
                            }
                        );
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_COLLECTION,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteCollectionTask(formParams.collectionId, item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto zadanie z zestawu");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                return customSearch;
            },
            defaultSort: {
                sortAsc: true,
                sortBy: 'orderNo'
            }
        }
    }

    /**
     * Online - tabela komisji egzaminacyjnej dla egzaminu online
     */
    function getTableDefinitionOnlineExaminatorBoard(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                firstName: {
                    value: 'Imię', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return item.firstName;
                    }
                },
                lastName: {
                    value: 'Nazwisko', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return item.lastName;
                    }
                },
                email: {
                    value: 'Adres e-mail', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return item.email;
                    }
                },
                phone: {
                    value: 'Telefon', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.phone);
                    }
                },
            },
            tableActions : [
                {
                    actionName: 'removeAction',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAMINATION_BOARD_DELETE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (formParams.isExamDetails === true && item.examStatus === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="removeAction({0})" title="Usuń z egzaminu"> <i class="fa fa-minus-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        const item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        const params = {
                            title: labels.CONFIRMATION_DIALOG_REMOVE_FROM_EXAM_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_REMOVE_FROM_EXAM_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.removeExaminationBoardFromExam(formParams.collectionId, item.id).then(function (response) {
                                    watchService.reload();
                                    modal.dismiss('cancel');
                                    Notification.success("Usunięto członka komisji z egzaminu");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'addAction',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAMINATION_BOARD_ADD,',
                    condition: function(serializedItem) {
                        if (typeof formParams.isExamDetails === 'undefined') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="addAction({0})" title="Dodaj"> <i class="fa fa-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams.restService.addExaminationBoardExam(formParams.collectionId, item.id).then(function(response) {
                            watchService.reload();
                            Notification.success("Dodano członka komisji");
                        },
                         function (response, status) {
                             notifyService.error("Wystąpił problem podczas zapisywania zmian, skontaktuj się z administratorem serwisu ...");
                         });
                    }
                }
            ],
            searchByFields : function (vm) {
                const customSearch = [];
                customSearch.push({key: 'examScheduleId', value: vm.collectionId});

                if (formParams.isExamDetails !== true) {
                    customSearch.push({key: 'examScheduleMode', value: 'EXCLUDE'});
                }

                if (vm.search.surname) {
                    customSearch.push({key: 'surname', value: vm.search.surname});
                }
                if (vm.search.forename) {
                    customSearch.push({key: 'forename', value: vm.search.forename});
                }
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy online- tabela zadań
     */
    function getTableDefinitionOnlineTask(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1',
                },
                profession: {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.profession);
                    }
                },
                topic: {
                    value: 'Temat', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.nullValue(item.topic);
                    }
                },
                examMode: {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.examMode(item.examMode);
                    }
                },
                level: {
                    value: 'Poziom', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, css: 'col-sm-1', filter: function(item) {
                        return tableFiltersService.examLevel(item.level);
                    }
                },
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $uibModal.open({
                            templateUrl: function () {
                                return 'app/online/templates/schedule/collection-task-preview.html';
                            },
                            controller: 'OnlineScheduleCollectionTaskPreviewController',
                            controllerAs: 'vm',
                            resolve: {
                                data: function () {
                                    return item;
                                }
                            }
                        });
                    }
                },
                {
                    actionName: 'addToCollection',
                    permissions: 'SCHEDULE_MANAGEMENT_EXAM_COLLECTION',
                    button: '<a href ng-click="addToCollection({0})" title="Dodaj do zestawu"> <i class="fa fa-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams.restService.addTaskToCollection(formParams.collectionId, formParams.studentId, item.id).then(function(response) {
                            Notification.success("Dodano zadanie do zestawu");
                        },
                         function (response, status) {
                             notifyService.error("Wystąpił problem podczas zapisywania zmian, skontaktuj się z administratorem serwisu ...");
                         });
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.search.professionId) {
                    customSearch.push({key: 'professionId', value: vm.search.professionId});
                }
                if (vm.search.examMode) {
                    customSearch.push({key: 'mode', value: vm.search.examMode});
                }
                if (vm.search.topicId) {
                    customSearch.push({key: 'topicId', value: vm.search.topicId});
                }
                if (vm.search.level) {
                    customSearch.push({key: 'level', value: vm.search.level});
                }
                return customSearch;
            }
        }
    }

    /**
     *
     * @param formParams
     * @returns {{listingColumnDefinitions: {id: {value: string, type: string, searchable: boolean, filter: listingColumnDefinitions.id.filter}, header: {value: string, type: string, searchable: boolean}, publishDate: {value: string, type: string, searchable: boolean, sortable: boolean, filter: listingColumnDefinitions.publishDate.filter}, published: {value: string, type: string, searchable: boolean, filter: listingColumnDefinitions.published.filter}, archival: {value: string, type: string, searchable: boolean, filter: listingColumnDefinitions.archival.filter}}, tableActions: *[], searchByFields: searchByFields}}
     */
    function getTableDefinitionAdminFile(formParams) {

        return {
            listingColumnDefinitions : {

                name: {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                type: {
                    value: 'Typ pliku', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.fileType(item.type);
                    }
                },
                url: {
                    value: 'Podgląd', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.fileStatus(item.status);
                    }
                }

            },
            tableActions : [
                {
                    actionName: 'activateAction',
                    permissions: 'FILE_MANAGEMENT_ACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status !== 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="activateAction({0})" title="Aktywuj"> <i class="fa fa-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                    var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.activate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('FILE.ACTIVATE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'deactiveAction',
                    permissions: 'FILE_MANAGEMENT_DEACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status !== 'DRAFT') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deactiveAction({0})" title="Dezaktywuj"> <i class="fa fa-circle-o fa-2x" aria-hidden="true"></i></a>',
                        action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deactivate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('FILE.DEACTIVATE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'FILE_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('FILE.DELETE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.filterParams.name) {
                    customSearch.push({key: 'name', value: vm.filterParams.name});
                }
                if (vm.filterParams.type) {
                    customSearch.push({key: 'type', value: vm.filterParams.type});
                }
                if (vm.filterParams.status) {
                    customSearch.push({key: 'status', value: vm.filterParams.status});
                }
                return customSearch;
            }
        }
    }

    function getTableDefinitionAdminResource(formParams) {

        return {
            listingColumnDefinitions : {
                title: {
                    value: 'Nazwa zasobu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                type: {
                    value: 'Typ zasobu', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.fileType(item.type);
                    }
                },
                url: {
                    value: 'Podgląd', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false
                }
            },
            tableActions : [
                {
                    permissions: 'RESOURCE_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    },
                    actionName: 'editAction'
                },
                {
                    permissions: 'RESOURCE_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    },
                    actionName: 'deleteAction'
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.filterParams.title) {
                    customSearch.push({key: 'title', value: vm.filterParams.title});
                }
                if (vm.filterParams.type) {
                    customSearch.push({key: 'type', value: vm.filterParams.type});
                }
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy - tabela zadań
     */
    function getTableDefinitionMgmtTask(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    tooltipPlacement: 'right',
                    tooltip: function(item) {
                        return tooltipService.taskTooltip(item);
                    }
                },
                profession: {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    filter: function(item) {
                        return tableFiltersService.professionName(item.profession);
                    },
                    tooltip: function(item) {
                        return item.profession;
                    }
                },
                topic: {
                    value: 'Temat', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    filter: function(item) {
                        return tableFiltersService.nullValue(item.topic);
                    },
                    tooltip: function(item) {
                        return item.topic;
                    }
                },
                mode: {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false,
                    filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    },
                    tooltip: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                },
                type: {
                    value: 'Etap', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false,
                    filter: function(item) {
                        return tableFiltersService.examType(item.type);
                    },
                    tooltip: function(item) {
                        return tableFiltersService.examType(item.type);
                    }
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false,
                    filter: function(item) {
                        return tableFiltersService.taskStatus(item.status);
                    },
                    tooltip: function(item) {
                        return tableFiltersService.taskStatus(item.status);
                    }
                },
                level: {
                    value: 'Poziom', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    filter: function(item) {
                        return tableFiltersService.examLevel(item.level);
                    },
                    tooltip: function(item) {
                        return tableFiltersService.examLevel(item.level);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    permissions: 'TASK_MANAGEMENT_PREVIEW,',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams.restService.get(item.id).then(function (response) {
                            $uibModal.open({
                                templateUrl: function () {
                                    return 'app/exams/templates/task/task-preview.html';
                                },
                                controller: 'TaskPreviewController',
                                controllerAs: 'vm',
                                resolve: {
                                    data: function () {
                                        return response.data;
                                    }
                                }
                            });
                        });
                    }
                },
                {
                    actionName: 'editAction',
                    permissions: 'TASK_MANAGEMENT_UPDATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        formParams['objId'] = item.id;
                        formFactory.open(formParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'TASK_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto zadanie");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'sendToApproveAction',
                    permissions: 'TASK_MANAGEMENT_SEND_TO_APPROVE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="sendToApproveAction({0})" title="Wyślij do zatwierdzenia"> <i class="fa fa-send fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.sendToApprove(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Do zatwierdzenia");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'approveAction',
                    permissions: 'TASK_MANAGEMENT_APPROVE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'TO_APPROVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="approveAction({0})" title="Akceptuj"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.approve(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Nieaktywny");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'rejectAction',
                    permissions: 'TASK_MANAGEMENT_REJECT,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'TO_APPROVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="rejectAction({0})" title="Odrzuć"> <i class="fa fa-close fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.reject(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Niezatwierdzony");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'activateAction',
                    permissions: 'TASK_MANAGEMENT_ACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'INACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="activateAction({0})" title="Aktywuj"> <i class="fa fa-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.activate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Aktywny");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'deactivateAction',
                    permissions: 'TASK_MANAGEMENT_DEACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deactivateAction({0})" title="Dezaktywuj"> <i class="fa fa-circle-o fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deactivate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status Nieaktywny");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'resumptionAction',
                    permissions: 'TASK_MANAGEMENT_RESUMPTION,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'INACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="resumptionAction({0})" title="Edycja"> <i class="fa fa-undo fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.resumption(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Ustawiono status W edycji");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.filterParams.professionId) {
                    customSearch.push({key: 'professionId', value: vm.filterParams.professionId});
                }
                if (vm.filterParams.examMode) {
                    customSearch.push({key: 'mode', value: vm.filterParams.examMode});
                }
                if (vm.filterParams.examType) {
                    customSearch.push({key: 'type', value: vm.filterParams.examType});
                }
                if (vm.filterParams.topicId) {
                    customSearch.push({key: 'topicId', value: vm.filterParams.topicId});
                }
                if (vm.filterParams.level) {
                    customSearch.push({key: 'level', value: vm.filterParams.level});
                }
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy - tabela zestawów testowych
     */
    function getTableDefinitionMgmtTestCollection(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                name: {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.nullValue(item.name);
                    }
                },
                mode: {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                },
                type: {
                    value: 'Etap', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.examType(item.type);
                    }
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.collectionStatus(item.status);
                    }
                },
                professionName: {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.nullValue(item.professionName);
                    }
                },
                createDate: {
                    value: 'Data', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.date(item.createDate, constants.TABLE_DATE_FORMAT);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'TEST_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $state.go('examTestDetails', {id: item.id});
                    }
                },
                {
                    actionName: 'printAction',
                    permissions: 'TEST_MANAGEMENT_PRINT,',
                    button: '<a href ng-click="printAction({0})" title="Drukuj"> <i class="fa fa-print fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        testService.print(item.id).then(function (response) {
                            fileUtils.downloadFile(response.data);
                        }, function (response) {
                            Notification.error("Wystąpił błąd podczas drukowania egzaminu ...")
                        });
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'TEST_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto zestaw");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.filterParams.examMode) {
                    customSearch.push({key: 'mode', value: vm.filterParams.examMode});
                }
                if (vm.filterParams.examType) {
                    customSearch.push({key: 'type', value: vm.filterParams.examType});
                }
                if (vm.filterParams.status) {
                    customSearch.push({key: 'status', value: vm.filterParams.status});
                }
                if (vm.filterParams.professionId) {
                    customSearch.push({key: 'professionId', value: vm.filterParams.professionId});
                }
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy - tabela zadań zestawu testowego
     */
    function getTableDefinitionMgmtTestCollectionTask(formParams) {

        return {
            listingColumnDefinitions : {
                "task.id": {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    tooltipPlacement: 'right',
                    tooltip: function(item) {
                        return tooltipService.taskTooltip(item.task);
                    }
                },
                orderNo: {
                    value: 'Kolejność', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                "task.profession": {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.professionName(item.task.profession);
                    }
                },
                "task.topic": {
                    value: 'Temat', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.task.topic);
                    }
                },
                "task.type": {
                    value: 'Etap', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examType(item.task.type);
                    }
                },
                "task.mode": {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examMode(item.task.mode);
                    }
                },
                "task.status": {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.taskStatus(item.task.status);
                    }
                },
                "task.level": {
                    value: 'Poziom', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.examLevel(item.task.level);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));

                        formParams.restService.getCollectionTask(formParams.collectionId, item.task.id).then(function (response) {
                            $uibModal.open({
                                templateUrl: function () {
                                    return 'app/exams/templates/task-preview.html';
                                },
                                controller: 'TaskPreviewController',
                                controllerAs: 'vm',
                                resolve: {
                                    data: function () {
                                        return response.data;
                                    }
                                }
                            });
                        });
                    }
                },
                {
                    actionName: 'changeOrder',
                    permissions: 'TEST_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="changeOrder({0})" title="Zmień kolejność"> <i class="fa fa-list-ol fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var taskOrderFormParams = {
                            name: 'task-order',
                            title: "Zmień kolejność zadania",
                            restService: formParams.restService,
                            model: item,
                            customSubmitAction: function (params) {
                                var data = {orderNo: params.model.orderNo};
                                formParams.restService.changeCollectionTaskOrder(formParams.collectionId, item.task.id, data).then(
                                    function() {
                                        params.uibModalInstance.dismiss('cancel');
                                        watchService.reload();
                                        Notification.success("Zmieniono kolejność zadania");
                                    }, function () {
                                        Notification.error("Wystąpił problem ...");
                                    }

                                );
                            }
                        };
                        formFactory.open(taskOrderFormParams);
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'TEST_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteCollectionTask(formParams.collectionId, item.task.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto zadanie z zestawu");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy - tabela zadań wykorzystywana do dodawania zadania do zestawu.
     * Tabela wykorzystywana zarówno do zestawów testowych jak i egzaminacyjnych.
     *
     * @param formParams - parametry formularza
     */
    function getTableDefinitionMgmtCollectionTask(formParams) {
        return {
            listingColumnDefinitions : {
                id: {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    tooltipPlacement: 'right',
                    tooltip: function(item) {
                        return tooltipService.taskTooltip(item);
                    }
                },
                profession: {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.professionName(item.profession);
                    }
                },
                topic: {
                    value: 'Temat', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.topic);
                    }
                },
                type: {
                    value: 'Etap', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examType(item.type);
                    }
                },
                mode: {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                },
                level: {
                    value: 'Poziom', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examLevel(item.level);
                    }
                }
            },
            tableActions : [
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.searchFields.professionId) {
                    customSearch.push({key: 'professionId', value: vm.searchFields.professionId});
                }
                if (vm.searchFields.topicId) {
                    customSearch.push({key: 'topicId', value: vm.searchFields.topicId});
                }
                if (vm.searchFields.examMode) {
                    customSearch.push({key: 'mode', value: vm.searchFields.examMode});
                }
                if (vm.searchFields.examType) {
                    customSearch.push({key: 'type', value: vm.searchFields.examType});
                }
                if (vm.searchFields.level) {
                    customSearch.push({key: 'level', value: vm.searchFields.level});
                }
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy - tabela zestawów egzaminacyjnych
     */
    function getTableDefinitionMgmtExamCollection(formParams) {

        return {
            listingColumnDefinitions : {
                id: {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                name: {
                    value: 'Nazwa', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.nullValue(item.name);
                    }
                },
                professionName: {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.nullValue(item.professionName);
                    }
                },
                mode: {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                },
                type: {
                    value: 'Etap', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.examType(item.type);
                    }
                },
                status: {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, filter: function(item) {
                        return tableFiltersService.collectionStatus(item.status);
                    }
                },
                createDate: {
                    value: 'Data', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.date(item.createDate, constants.TABLE_DATE_FORMAT);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'editAction',
                    permissions: 'EXAM_MANAGEMENT_UPDATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="editAction({0})" title="Edytuj"> <i class="fa fa-pencil fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        $state.go('examExamDetails', {id: item.id});
                    }
                },
                {
                    actionName: 'finishAction',
                    permissions: 'EXAM_MANAGEMENT_FINISH,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'CREATED') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="finishAction({0})" title="Zakończ tworzenie"> <i class="fa fa-star fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.finish(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.FINISH_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'rejectAction',
                    permissions: 'EXAM_MANAGEMENT_REJECT,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'TO_APPROVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="rejectAction({0})" title="Odrzuć"> <i class="fa fa-close fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.reject(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.REJECT_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'approveAction',
                    permissions: 'EXAM_MANAGEMENT_APPROVE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'TO_APPROVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="approveAction({0})" title="Akceptuj"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.approve(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.APPROVE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'activateAction',
                    permissions: 'EXAM_MANAGEMENT_ACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'INACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="activateAction({0})" title="Aktywuj"> <i class="fa fa-circle fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.activate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.ACTIVATE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'resumptionAction',
                    permissions: 'EXAM_MANAGEMENT_RESUMPTION,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'INACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="resumptionAction({0})" title="Edycja"> <i class="fa fa-undo fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.resumption(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.RESUMPTION_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'deactivateAction',
                    permissions: 'EXAM_MANAGEMENT_DEACTIVATE,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="deactivateAction({0})" title="Dezaktywuj"> <i class="fa fa-circle-o fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deactivate(item.id).then(function(response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.DEACTIVATE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                },
                {
                    actionName: 'printAction',
                    permissions: 'EXAM_MANAGEMENT_PRINT,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'ACTIVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="printAction({0})" title="Drukuj"> <i class="fa fa-print fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        examService.print(item.id).then(function (response) {
                            fileUtils.downloadFile(response.data);
                        }, function (response) {
                            Notification.error("Wystąpił błąd podczas drukowania egzaminu ...")
                        });
                    }
                },
                {
                    actionName: 'previewAction',
                    permissions: 'EXAM_MANAGEMENT_PREVIEW,',
                    condition: function(serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        if (item.status === 'INACTIVE' || item.status === 'TO_APPROVE') {
                            return true;
                        }
                        return false;
                    },
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                            examService.preview(item.id).then(function (response) {
                            fileUtils.downloadFile(response.data);
                        }, function (response) {
                            Notification.error("Wystąpił błąd podczas drukowania podglądu ...")
                        });
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'EXAM_MANAGEMENT_DELETE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteItem(item.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    notifyService.success('EXAM.DELETE_SUCCESS');
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.filterParams.examMode) {
                    customSearch.push({key: 'mode', value: vm.filterParams.examMode});
                }
                if (vm.filterParams.examType) {
                    customSearch.push({key: 'type', value: vm.filterParams.examType});
                }
                if (vm.filterParams.status) {
                    customSearch.push({key: 'status', value: vm.filterParams.status});
                }
                if (vm.filterParams.professionId) {
                    customSearch.push({key: 'professionId', value: vm.filterParams.professionId});
                }
                return customSearch;
            }
        }
    }

    /**
     * Egzaminy - tabela zadań zestawu egzaminacyjnego
     */
    function getTableDefinitionMgmtExamCollectionTask(formParams) {

        return {
            listingColumnDefinitions : {
                "task.id": {
                    value: 'Id', type: CONFIG_TABLE_TYPES.TEXT, searchable: true,
                    tooltipPlacement: 'right',
                    tooltip: function(item) {
                        return tooltipService.taskTooltip(item.task);
                    }
                },
                orderNo: {
                    value: 'Kolejność', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                },
                "task.profession": {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.professionName(item.task.profession);
                    }
                },
                "task.topic": {
                    value: 'Temat', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.task.topic);
                    }
                },
                "task.type": {
                    value: 'Etap', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examType(item.task.type);
                    }
                },
                "task.mode": {
                    value: 'Rodzaj', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examMode(item.task.mode);
                    }
                },
                "task.status": {
                    value: 'Status', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.taskStatus(item.task.status);
                    }
                },
                "task.level": {
                    value: 'Poziom', type: CONFIG_TABLE_TYPES.TEXT, searchable: true, sortable: false, filter: function(item) {
                        return tableFiltersService.examLevel(item.task.level);
                    }
                }
            },
            tableActions : [
                {
                    actionName: 'previewAction',
                    button: '<a href ng-click="previewAction({0})" title="Podgląd"> <i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));

                        formParams.restService.getCollectionTask(formParams.collectionId, item.task.id).then(function (response) {
                            $uibModal.open({
                                templateUrl: function () {
                                    return 'app/exams/templates/task-preview.html';
                                },
                                controller: 'TaskPreviewController',
                                controllerAs: 'vm',
                                resolve: {
                                    data: function () {
                                        return response.data;
                                    }
                                }
                            });
                        });
                    }
                },
                {
                    actionName: 'changeOrderUp',
                    permissions: 'EXAM_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="changeOrderUp({0})" title="Zmień kolejność o jedną pozycje w górę"> <i class="fa fa-arrow-circle-up fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var data = {orderDirection: 'UP'};
                        formParams.restService.changeCollectionTaskOrderDirection(formParams.collectionId, item.task.id, data).then(
                            function (response) {
                                watchService.reload();
                                Notification.success("Zmieniono kolejność zadania");
                            }
                        );
                    }
                },
                {
                    actionName: 'changeOrderDown',
                    permissions: 'EXAM_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="changeOrderDown({0})" title="Zmień kolejność o jedną pozycje w dół"> <i class="fa fa-arrow-circle-down fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var data = {orderDirection: 'DOWN'};
                        formParams.restService.changeCollectionTaskOrderDirection(formParams.collectionId, item.task.id, data).then(
                            function (response) {
                                watchService.reload();
                                Notification.success("Zmieniono kolejność zadania");
                            }
                        );
                    }
                },
                {
                    actionName: 'deleteAction',
                    permissions: 'EXAM_MANAGEMENT_UPDATE,',
                    button: '<a href ng-click="deleteAction({0})" title="Usuń"> <i class="fa fa-trash fa-2x" aria-hidden="true"></i></a>',
                    action: function (serializedItem) {
                        var item = angular.fromJson(decodeURI(window.atob(serializedItem)));
                        var params = {
                            title: labels.CONFIRMATION_DIALOG_TITLE,
                            confirmation: labels.CONFIRMATION_DIALOG_CONFIRMATION,
                            confirmationAction: function (modal) {
                                formParams.restService.deleteCollectionTask(formParams.collectionId, item.task.id).then(function (response) {
                                    modal.dismiss('cancel');
                                    watchService.reload();
                                    Notification.success("Usunięto zadanie z zestawu");
                                });
                            }
                        };
                        confirmDialogService.open(params);
                    }
                }
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                return customSearch;
            },
            defaultSort: {
                sortAsc: true,
                sortBy: 'orderNo'
            }
        }
    }

    /**
     * Informacje - wykaz komisji egzaminacyjnych
     */
    function getTableDefinitionCommission(formParams) {

        return {
            listingColumnDefinitions : {
                'profession.name': {
                    value: 'Zawód', type: CONFIG_TABLE_TYPES.TEXT, searchable: false, sortable: false, filter: function(item) {
                        return tableFiltersService.nullValue(item.profession.name);
                    }
                },
                mode: {
                    value: 'Rodzaj egzaminu', type: CONFIG_TABLE_TYPES.TEXT, searchable: false, sortable: false, filter: function(item) {
                        return tableFiltersService.examMode(item.mode);
                    }
                },
                organizationName: {
                    value: 'Nazwa Izby', type: CONFIG_TABLE_TYPES.TEXT, searchable: true
                }
            },
            tableActions : [
            ],
            searchByFields : function (vm) {
                var customSearch = [];
                if (vm.professionId) {
                    customSearch.push({key: 'professionId', value: vm.professionId});
                }

                return customSearch;
            }
        }
    }

}
}());
