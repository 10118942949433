;(function() {
"use strict";

/**
 * Komisje egzaminacyjne.
 *
 * @Controller OrgCommissionsController
 * @module organizations
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.organizations')
        .controller('OrgCommissionsController', OrgCommissionsController);

    OrgCommissionsController.$inject = ['$sce', 'PAGES', 'pageService', 'orgCommissionService', 'tableDefinitions', 'formFactory', 'constants'];

    function OrgCommissionsController($sce, PAGES, pageService, orgCommissionService, tableDefinitions, formFactory, constants) {
        const vm = this;
        const formParams = {
            name: 'commission',
            dictName: constants.FORM_DICT_COMMISSION,
            restService: orgCommissionService,
            title: "{0} komisja egzaminacyjna {1}"
        };

        pageService.getByGuid(PAGES.ORG_COMMISSIONS).then(
            function (response) {
                vm.pageTitle = response.data.title;
                vm.pageContent = $sce.trustAsHtml(response.data.content); 
            },
            function (response, status) {
            }
        );

        const tableDefinition = tableDefinitions.getTableDefinitionOrgCommission(formParams);
        vm.listingColumnDefinitions = tableDefinition.listingColumnDefinitions;
        vm.tableActions = tableDefinition.tableActions;
        vm.orgCommissionService = orgCommissionService;

        vm.addCommission = function () {
            delete formParams['objId'];
//            formParams['title'] = 'Powołaj komisję egzaminacyjną';
            formFactory.open(formParams, true);
        };

    }

})();
}());
