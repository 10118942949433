;(function() {
"use strict";

/**
 * Fasada filtrów dla tabeli
 *
 * @filter table
 * @module filters
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('tableFiltersService', tableFiltersService);

tableFiltersService.$inject = ['$filter'];

function tableFiltersService($filter) {
    const service = {
        date: date,
        nullValue: nullValue,
        trueFalse: trueFalse,
        examType: examType,
        examMode: examMode,
        examLevel: examLevel,
        taskStatus: taskStatus,
        userStatus: userStatus,
        collectionStatus: collectionStatus,
        professionStatus: professionStatus,
        professionName: professionName,
        fileStatus: fileStatus,
        fileType: fileType,
        studentStatus: studentStatus,
        scheduleStatus: scheduleStatus,
        scheduleStudentTaskStatus: scheduleStudentTaskStatus,
        examScore: examScore
    };

    return service;


    function date(date, format) {
        return (date) ? $filter('date')(date, format) : '';
    }

    function nullValue(value) {
        return (value) ? value : '';
    }

    function trueFalse(value) {
        return (value) ? 'Tak' : 'Nie';
    }

    function examType(value) {
        return $filter('examType')(value);
    }

    function examMode(value) {
        return $filter('examMode')(value);
    }

    function examLevel(value) {
        return $filter('examLevel')(value);
    }

    function taskStatus(value) {
        return $filter('taskStatus')(value);
    }

    function userStatus(value) {
        return $filter('userStatus')(value);
    }

    function collectionStatus(value) {
        return $filter('collectionStatus')(value);
    }

    function professionStatus(value) {
        return $filter('professionStatus')(value);
    }

    function professionName(value) {
        return $filter('professionName')(value);
    }

    function fileStatus(value) {
        return $filter('fileStatus')(value);
    }

    function fileType(value) {
        return $filter('fileType')(value);
    }

    function studentStatus(value) {
        return $filter('studentStatus')(value);
    }

    function scheduleStatus(value) {
        return $filter('scheduleStatus')(value);
    }

    function scheduleStudentTaskStatus(value) {
        return $filter('scheduleStudentTaskStatus')(value);
    }

    function examScore(value) {
        return $filter('examScore')(value);
    }

}
}());
