;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('pagerService', pagerService);


pagerService.$inject = ['$stateParams', 'constants'];


function pagerService($stateParams, constants) {

    return {
        getPager: getPager,
        getOffset: getOffset,
        getCurrentPage: getCurrentPage,
        getLimit: getLimit
    };

    function getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || getLimit();

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = _.range(startPage, endPage + 1);

        // prev/next pages
        var prevPage = (currentPage !== 1) ? currentPage - 1 : null;
        var nextPage = totalPages === 0 ? null : ( (currentPage !== totalPages) ? currentPage + 1 : null);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
            prevPage: prevPage,
            nextPage: nextPage
        };
    }

    function getOffset() {
        var page = $stateParams.page;
        page = page && parseInt(page) >= 1 ? parseInt(page) : 1;
        return (page - 1) * getLimit();
    }

    function getCurrentPage() {
        var page = $stateParams.page;
        page = page && parseInt(page) >= 1 ? parseInt(page) : 1;
        return page;
    }

    function getLimit() {
        return constants.PAGINATE_DEFAULT_PAGE_SIZE;
    }

}
}());
