;(function() {
"use strict";

/**
 * Serwis obsługujący formularz dodawania plików.
 *
 * @service formUploadFactory
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('formUploadService', formUploadService)
    .controller('FormUploadModalInstanceController', FormUploadModalInstanceController);

formUploadService.$inject = ['$uibModal'];
FormUploadModalInstanceController.$inject = ['$state', 'fileService', 'watchService', 'authenticationService', '$uibModalInstance', 'formData', 'Notification', 'FileUploader', 'formValidators', 'API_CONFIG'];

/**
 *
 * @param $uibModal
 * @returns {{open: open}}
 */
function formUploadService($uibModal) {


    var service = {
        open: open
    };

    return service;

    /**
     * 
     * @param params
     */
    function open(params) {
        var result = $uibModal.open({
            templateUrl: 'app/common/services/forms/templates/formUploadModalInstanceController.html',
            controller: 'FormUploadModalInstanceController',
            controllerAs: 'vm',
            size: 'lg',
            backdrop: 'static',
            keyboard  : false,
            resolve: {
                formData: function () {
                    return {
                        params: params
                    }
                }
            }
        }).result;
    };
}

/**
 *
 * @param fileService
 * @param $uibModalInstance
 * @param formData
 * @param Notification
 * @param FileUploader
 * @param authenticationService
 * @param formValidators
 * @param API_CONFIG
 * @constructor
 */
function FormUploadModalInstanceController($state, fileService, watchService, authenticationService, $uibModalInstance, formData, Notification, FileUploader, formValidators, API_CONFIG) {
    var vm = this;
    vm.saveYoutube = saveYoutube;
    vm.cancel = cancel;
    vm.onRemoveItem = onRemoveItem;
    vm.configure = configure;

    vm.files = new Array();
    vm.title = formData.params.title;
    vm.showProgress = false;
    vm.showFilesTable = false;
    vm.fileTypePhoto = 'PHOTO';
    vm.fileTypeYoutube = 'YOUTUBE';
    vm.hasPrivateFiles = false;


    vm.fileTypes = [{
        key: vm.fileTypePhoto,
        value: 'Plik z dysku'
    }, {
        key: vm.fileTypeYoutube,
        value: "Youtube wideo link"
    }];
    // import
    vm.isTaskImportFileValid = true;
    vm.isTaskImport = formData.params.isTaskImport;
    vm.importResult = {};
    vm.showImportResult = false;
    if(vm.isTaskImport) {
        vm.fileType = vm.fileTypePhoto;
    }

    vm.configure();

    function configure() {
        switch (vm.fileType) {
            case vm.fileTypePhoto:
                initUploader();
                break;
            default:
                initFile();
        }
    }

    /**
     * Usuwanie pliku z listy.
     */
    function onRemoveItem() {
        //vm.formInvalid = false;
    }

    /**
     * Walidacja uploadowanego pliku
     * @param fileItem
     */
    function validate(fileItem) {
        if(vm.isTaskImport) {
            vm.isTaskImportFileValid = formValidators.uploadImportFileValidator(fileItem);
            if(!vm.isTaskImportFileValid) {
                initUploader();
            }
        }
    }

    /**
     * Akcja potwierdzenia formularza.
     */
    function saveYoutube() {
        if (vm.form.$valid) {
            vm.file.isPrivate = false;
            vm.type = 'YOUTUBE';
            var files = [vm.file];
            fileService.save(files).then(function () {
                finish();
            });
        } else {
            Notification.warning("Uzupełnij poprawnie formularz ...")
        }
    }

    /**
     * Akcja zamknięcia okna formularza bez zapisu danych formularza.
     */
    function cancel() {
        $uibModalInstance.dismiss('cancel');
        if(vm.isTaskImport) {
            watchService.reload();
        }
    }

    function initFile() {
        vm.file = {
            type: vm.fileTypeYoutube,
            size: 0
        };
    }


    /**
     * Inicjalizacja komponentu uploadera plików.
     */
    function initUploader() {

        var endpointUrl = vm.isTaskImport ? API_CONFIG().ENDPOINT_MANAGEMENT_UPLOAD_IMPORT : API_CONFIG().ENDPOINT_MANAGEMENT_UPLOAD;

        var uploader = new FileUploader({
            url: endpointUrl,
            headers: {
                'X-TOKEN': authenticationService.getToken()
            }
        });

        uploader.onAfterAddingFile = function(fileItem) {
            vm.showFilesTable = true;
            validate(fileItem);
        };
        uploader.onSuccessItem = function(fileItem, response, status, headers) {
            vm.importResult = response;
            vm.files.push(response);
            vm.showImportResult = true;
            vm.showFilesTable = false;
        };

        uploader.onBeforeUploadItem = function(item) {
            item.formData.push({isPrivateFile: vm.hasPrivateFiles});
        };

        uploader.onProgressAll = function(progress) {
            vm.showProgress = true;
        };
        uploader.onCompleteAll = function() {
            vm.showProgress = false;
            if(!vm.isTaskImport) {
                fileService.save(
                    hasPrivateFiles(vm.files)
                ).then(function () {
                    finish();
                });

            }
        };

        vm.uploader = uploader;
    }

    function hasPrivateFiles(files) {
        angular.forEach(files, function (file) {
            file['isPrivate'] = vm.hasPrivateFiles;
        });
        return files;
    }

    function finish() {
        watchService.reload();
        Notification.success("Poprawnie dodano plik ...")
        $uibModalInstance.dismiss('cancel');
    }
}
}());
