;(function() {
"use strict";

/**
 * Serwis odpowiedzialny za autoryzacje uzytkownika.
 *
 * @service authService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('authService', authService);

authService.$inject = ['http', 'API_CONFIG', 'constants'];

/**
 *
 * @param http
 * @param API_CONFIG
 * @param $cookies
 * @param constants
 * @returns {{logout: logout, login: login, sessionKeepalive: sessionKeepalive, extendSession: extendSession}}
 */
function authService(http, API_CONFIG, constants) {

    return {
        logout: logout,
        login: login,
        passwordReset: passwordReset,
        resetPasswordConfirmation: resetPasswordConfirmation,
        passwordResetCommit: passwordResetCommit,
        sessionKeepalive: sessionKeepalive,
    };

    /**
     *
     * @returns {*}
     */
    function logout() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_AUTH, 'logout');
        return http.post(url, {});
    }

    /**
     *
     * @param data
     * @returns {*}
     */
    function login(data) {
        return http.post(API_CONFIG().ENDPOINT_LOGIN, data, constants.HTTP_CONTENT_TYPE_X_WWW_FORM_URLENCODED);
    }

    /**
     *
     * @param data
     */
    function passwordReset(data) {
        var endpoint = "{0}/{1}".format(API_CONFIG().ENDPOINT_AUTH, "reset")
        return http.post(endpoint, data);
    }
    /**
     *
     * @param data
     */
    function passwordResetCommit(data) {
        var endpoint = "{0}/{1}".format(API_CONFIG().ENDPOINT_AUTH, "reset")
        return http.put(endpoint, data);
    }

    /**
     *
     * @param hash
     */
    function resetPasswordConfirmation(hash) {
        var endpoint = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_AUTH, "reset", hash)
        return http.get(endpoint);
    }

    /**
     *
     * @returns {*}
     */
    function sessionKeepalive() {
        return http.get(API_CONFIG().ENDPOINT_KEEPALIVE_SESSION);
    }

}
}());
