;(function() {
"use strict";

/**
 * Informacje o egzaminach.
 *
 * @Controller ExamInfoController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('ExamInfoController', ExamInfoController);

    ExamInfoController.$inject = ['PAGES'];
    function ExamInfoController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }

})();
}());
