;(function() {
"use strict";

/**
 * Materialy pomocnicze.
 *
 * @Controller AidsController
 * @module resources
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('AidsController', AidsController);

    AidsController.$inject = ['PAGES'];
    function AidsController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }

})();
}());
