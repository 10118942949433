;(function() {
"use strict";

/**
 * Serwis obsługujący role.
 *
 * @service roleService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('roleService', RoleService);

RoleService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function RoleService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_ROLE, params);
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_ROLE, id);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_ROLE;
        if (item.id) {
            url = '{0}/{1}'.format(url, item.id);
            return http.put(url, item);
        }

        return http.post(url, item);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_ROLE, id);
        return http.delete(url);
    }

}
}());
