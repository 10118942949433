;(function() {
"use strict";

/**
 * Treść artykułu.
 *
 * @Controller ArticleController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('ArticleController', ArticleController);


    ArticleController.$inject = ['$state', '$sce', '$stateParams', 'articleService'];
    function ArticleController($state, $sce, $stateParams, articleService) {
        var vm = this;

        vm.getNews = getNews;
        vm.getNews($stateParams.id);

        function getNews(id) {
            articleService.getNewsById(id).then(
                function (response) {
                    vm.articleContent = $sce.trustAsHtml(response.data.content);
                    vm.article = response.data;
                },
                function (response, status) {
                    $state.go('home');
                }
            );
        }

    }
})();
}());
