;(function() {
"use strict";

/**
 * Moduł Administracja.
 *
 * @module exams
 * @project EWR
 *
 * @param $stateProvider
 */
angular
    .module('ewr.admin', ['ckeditor'], config);


config.$inject = ['$stateProvider'];

function config($stateProvider) {
    $stateProvider
        .state('adminCmsArticle', {
            parent: 'index',
            url: '/admin/cms-article',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/article.html',
                    controller: 'AdminArticleController as vm'
                }
            },
            auth: true
        })
        .state('adminCmsPage', {
            parent: 'index',
            url: '/admin/cms-page',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/page.html',
                    controller: 'AdminPageController as vm'
                }
            },
            auth: true
        })
        .state('adminCmsCarousel', {
            parent: 'index',
            url: '/admin/cms-carousel',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/carousel.html',
                    controller: 'AdminCarouselController as vm'
                }
            },
            auth: true
        })
        .state('adminMessage', {
            parent: 'index',
            url: '/admin/message',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/message.html',
                    controller: 'AdminMessageController as vm'
                }
            },
            auth: true
        })
        .state('adminReport', {
            parent: 'index',
            url: '/admin/report',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/report.html',
                    controller: 'AdminReportController as vm'
                }
            },
            auth: true
        })
        .state('adminRole', {
            parent: 'index',
            url: '/admin/role',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/role.html',
                    controller: 'AdminRoleController as vm'
                }
            },
            auth: true
        })
        .state('adminUser', {
            parent: 'index',
            url: '/admin/user',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/user.html',
                    controller: 'AdminUserController as vm'
                }
            },
            auth: true
        })
        .state('adminFile', {
            parent: 'index',
            url: '/admin/file',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/file.html',
                    controller: 'FileController as vm'
                }
            },
            auth: true
        })
        .state('adminResource', {
            parent: 'index',
            url: '/admin/resource',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/resource.html',
                    controller: 'ResourceController as vm'
                }
            },
            auth: true
        })
        .state('adminProfession', {
            parent: 'index',
            url: '/admin/profession',
            views: {
                'content@index': {
                    templateUrl: 'app/admin/templates/profession.html',
                    controller: 'AdminProfessionController as vm'
                }
            },
            auth: true
        });
}
}());
