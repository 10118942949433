;(function() {
"use strict";

/**
 * Zadanie - podgląd
 *
 * @Controller TaskPreviewController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.exams')
        .controller('TaskPreviewController', TaskPreviewController);

    TaskPreviewController.$inject = ['$uibModalInstance', 'data'];
    function TaskPreviewController($uibModalInstance, data) {
        var vm = this;
        vm.task = data;

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
}());
