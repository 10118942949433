;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny - szczegóły/lista kandydatów
 *
 * @Controller OnlineScheduleDetailsController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineScheduleDetailsController', OnlineScheduleDetailsController);

    OnlineScheduleDetailsController.$inject = ['$scope', '$stateParams', '$uibModal', 'constants', 'onlineScheduleService', 'onlineStudentService', 'tableDefinitions', 'watchService', 'formFactory', 'notifyService', 'fileUtils'];
    function OnlineScheduleDetailsController($scope, $stateParams, $uibModal, constants, onlineScheduleService, onlineStudentService, tableDefinitions, watchService, formFactory, notifyService, fileUtils) {
        const vm = this;
        vm.collectionId = $stateParams.id;
        vm.collection = {};

        onlineScheduleService.get(vm.collectionId).then(
            function (response) {
                vm.collection = response.data;
                watchService.reload();
            },
            function (response, status) {
            }
        );

        //student
        const formParams = {
            name: 'student',
            apiCall: 'getList',
            title: "Kandydaci",
            restService: onlineStudentService,
            collectionId: vm.collectionId,
            isExamDetails: true,
            show: true
        };

        const customSearch = [];
        customSearch.push({key: 'examScheduleId', value: vm.collectionId});
        customSearch.push({key: 'examScheduleMode', value: 'INCLUDE'});
        vm.customSearch = customSearch;

        const studentTableDefinition = tableDefinitions.getTableDefinitionOnlineStudent(formParams);

        vm.listingColumnDefinitions = studentTableDefinition.listingColumnDefinitions;
        vm.tableActions = studentTableDefinition.tableActions;
        vm.onlineStudentService = onlineStudentService;

        //examinator board
        const examinatorBoardFormParams = {
            name: 'examinatorBoard',
            apiCall: 'getListBoardExam',
            title: "Komisja egzaminacyjna",
            restService: onlineScheduleService,
            collectionId: vm.collectionId,
            isExamDetails: true
        };

        const examinatorBoardTableDefinition = tableDefinitions.getTableDefinitionOnlineExaminatorBoard(examinatorBoardFormParams);

        vm.examinatorBoardListingColumnDefinitions = examinatorBoardTableDefinition.listingColumnDefinitions;
        vm.examinatorBoardTableActions = examinatorBoardTableDefinition.tableActions;
        vm.onlineScheduleService = onlineScheduleService;

        /**
         * Dodaj kandydata do egzaminu
         */
        vm.addStudent = function() {
            $scope.collectionId = vm.collectionId;
            var modalInstance = $uibModal.open({
                templateUrl: 'app/online/templates/student/select-list.html',
                controller: 'OnlineStudentSelectController',
                controllerAs: 'vm',
                animation: true,
                size: 'lg',
                scope: $scope
            });

            modalInstance.result.then(function() {
               watchService.reload();
            }, function() {
               onlineScheduleService.get(vm.collectionId).then(
                   function (response) {
                       vm.collection = response.data;
                   }
               );
            });
        };

        /**
         * Wygeneruj zestaw egzaminacyjny
         */
        vm.generateTask = function() {
            onlineScheduleService.generateTask(vm.collectionId).then(
                function () {
                    vm.collection.canGenerate = false;
                    watchService.reload();
                    notifyService.success("Poprawnie wygenerowano zestawy egzaminacyjne ... ");
                },
                function () {
                    notifyService.error("Wystąpił problem podczas generowania zestawów egaminacyjnych, skontaktuj się z administratorem serwisu ...");
                }
            );
        };

        /**
         * Generuje zbiorczy plik zip z tokenami autoryzacyjnymi
         */
        vm.generateStudentTokens = function() {
            onlineScheduleService.generateTokens(vm.collectionId).then(function (response) {
                    fileUtils.downloadFile(response.data);
                },
                function() {
                    notifyService.error("Wystąpił błąd podczas generowania tokenów autoryzacyjnych.");
                }
            )
        };

        /**
         * Edytuj dane egzaminu
         */
        vm.edit = function() {
            const formEditParams = {
                name: 'online-schedule-edit',
                title: "{0} Edycja danych egzaminu {1}",
                restService: onlineScheduleService,
                objId: vm.collectionId,
                customSubmitAction: function(params) {
                    onlineScheduleService.save(params.model).then(
                    function (response) {
                        vm.collection = response.data;
                        notifyService.success("Poprawnie zapisano dane ... ");
                    },
                    function () {
                        notifyService.error("Wystąpił problem podczas zapisywania zmian, skontaktuj się z administratorem serwisu ...");
                    });
                    params.uibModalInstance.dismiss('cancel');
                }
            };

            formFactory.open(formEditParams);
        };

        /**
         * Dodaj członka komisji
         */
        vm.addExaminationBoardExam = function() {
            $scope.collectionId = vm.collectionId;
            var modalInstance = $uibModal.open({
                templateUrl: 'app/online/templates/schedule/select-list.html',
                controller: 'OnlineExaminationBoardSelectController',
                controllerAs: 'vm',
                animation: true,
                size: 'lg',
                scope: $scope
            });

            modalInstance.result.then(function() {
               watchService.reload();
            }, function() {
               watchService.reload();
            });
        };

        vm.canEdit = function() {
            if (vm.collection && vm.collection.status === 'CREATED') {
                return true;
            }
            return false;
        }

        vm.canApprove = function() {
            if (vm.collection && vm.collection.status === 'TO_APPROVE') {
                return true;
            }
            return false;
        }

        /**
         * Przekaż do akceptacji
         */
        vm.toApprove = function() {
            onlineScheduleService.toApprove(vm.collectionId).then(
                function () {
                    vm.collection.status = 'TO_APPROVE';
                    notifyService.success("Egzamin został przekazany do akceptacji");
                },
                function (response, status) {}
            );
        }

        /**
         * Apceptuj
         */
        vm.approve = function() {
            onlineScheduleService.approve(vm.collectionId).then(
                function () {
                    vm.collection.status = 'APPROVED';
                    notifyService.success("Egzamin został zaakceptowany");
                },
                function (response, status) {}
            );
        }

        /**
         * Przekaż do poprawy
         */
        vm.toImprove = function() {
            onlineScheduleService.toImprove(vm.collectionId).then(
                function () {
                    vm.collection.status = 'CREATED';
                    notifyService.success("Egzamin został zwrócony do poprawy");
                },
                function (response, status) {}
            );
        }

        /**
         * Odrzuc
         */
        vm.remove = function() {
            onlineScheduleService.remove(vm.collectionId).then(
                function () {
                    vm.collection.status = 'INACTIVE';
                    notifyService.success("Egzamin został odrzucony");
                },
                function (response, status) {}
            );
        }

    }

})();
}());
