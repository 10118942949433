;(function() {
"use strict";

/**
 * Admin - zarządzanie plikami.
 *
 * @Controller FilesController
 * @module admin
 * @project EWR
 */

angular.module('ewr.admin')
    .controller('FileController', FileController);

FileController.$inject = ['fileService', 'tableDefinitions', 'formUploadService', 'constants'];

function FileController(fileService, tableDefinitions, formUploadService, constants) {
    const vm = this;
    const formParams = {
        name: 'cms-files',
        title: "Dodawanie nowego pliku",
        restService: fileService
    };

    vm.dictionary = {};
    vm.filterParams = {};

    fileService.getFilter().then(
        function (response) {
            const dictionaries = response.data;
            angular.forEach(dictionaries, function (dictionary) {
                if (dictionary.name === 'fileType') {
                    vm.dictionary.fileTypes = dictionary.items;
                }
                if (dictionary.name === 'fileStatus') {
                    vm.dictionary.fileStatuses = dictionary.items;
                }
            });
        },
        function (response, status) {
            // TODO
        }
    );

    const fileTableDefinition = tableDefinitions.getTableDefinitionAdminFile(formParams);

    vm.listingColumnDefinitions = fileTableDefinition.listingColumnDefinitions;
    vm.tableActions = fileTableDefinition.tableActions;
    vm.fileService = fileService;

    vm.add = function () {
        formUploadService.open(formParams)
    };

    vm.search = function () {
        vm.customSearch = fileTableDefinition.searchByFields(vm);
    };

}
}());
