;(function() {
"use strict";

/**
 * Serwis obsługujący profil uzytkownika.
 *
 * @service profileService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('profileService', profileService);

profileService.$inject = ['http', 'API_CONFIG'];

function profileService(http, API_CONFIG) {
    var service = {
        get: get,
        save: save,
    };

    return service;

    function get(id) {
        return http.get(API_CONFIG().ENDPOINT_MANAGEMENT_PROFILE);
    }

    function save(item) {
        return http.put(API_CONFIG().ENDPOINT_MANAGEMENT_PROFILE, item);
    }
}
}());
