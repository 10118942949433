;(function() {
"use strict";

$(document).ready ( function () {

    const DEFAULT_TYPE = 'normal';
    const CONTRAST_VIEW_TYPE = 'contrast';
    const SMALL_TEXT_SIZE_KEY = 'small';
    const BIG_TEXT_SIZE_KEY = 'big';

    var isFirstClickOnWcagContrastSwitch = true;
    var enabledViewType = DEFAULT_TYPE;
    var enabledTextSizeKey = DEFAULT_TYPE;


    function setSettings(viewType, textSizeKey) {
        setViewType(viewType);
        setTextSize(textSizeKey);
    }

    function setViewType(viewType) {
        enabledViewType = viewType;
        less.modifyVars({'@viewType': viewType});
        less.refreshStyles();
    }

    function setTextSize(textSizeKey) {
        enabledTextSizeKey = textSizeKey;
        less.modifyVars({'@textSize' : textSizeKey});
        less.refreshStyles();
    }

    $(document).on ("click", "#wcagTextSmallBtn", function (evt) {
        setSettings(enabledViewType, SMALL_TEXT_SIZE_KEY);
        evt.stopImmediatePropagation();
    });

    $(document).on ("click", "#wcagTextNormalBtn", function (evt) {
        setSettings(enabledViewType, DEFAULT_TYPE);
        evt.stopImmediatePropagation();
    });

    $(document).on ("click", "#wcagTextBigBtn", function (evt) {
        setSettings(enabledViewType, BIG_TEXT_SIZE_KEY);
        evt.stopImmediatePropagation();
    });

    function getViewType() {
        if (isFirstClickOnWcagContrastSwitch) {
            isFirstClickOnWcagContrastSwitch = false;
            return 'contrast';
        } else {
            isFirstClickOnWcagContrastSwitch = true;
            return 'normal';
        }
    }

    $(document).on ("click", "#wcagContrastSwitch", function (evt) {
        switch (getViewType()) {
            case 'normal':
                setViewType('normal');
                break;
            case 'contrast':
                setViewType('contrast');
                break;
            default:
                break;
        }
        evt.stopImmediatePropagation();
    });

    $(document).on ("click", "#cookies-btn", function (evt) {
        $('#cookies').hide();
        evt.stopImmediatePropagation();
    });
});
}());
