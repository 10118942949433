;(function() {
"use strict";

/**
 * Module that provides utility functions
 * such as translation, showing message
 */
angular
    .module('ewr.common')
    .factory('utilsService', utilsService);

utilsService.$inject = ['$rootScope', '$translate', 'constants', '$cookies'];

function utilsService($rootScope, $translate, constants, $cookies) {

    var service = {
        showError: showError,
        showSuccess: showSuccess,
        translate: translate,
        updateUrl: updateUrl,
        getPropertyValue: getPropertyValue,
        isPropertyExists: isPropertyExists
    };

    return service;


    function updateUrl(baseUrl, params) {
        baseUrl += "?";
        angular.forEach(params, function (value, key) {
            if (key != 'id') {
                baseUrl += '&' + key + '=' + value
            }
        });

        if (params && 'id' in params) {
            baseUrl = baseUrl.format(params['id']);
        }
        return baseUrl;
    }

    /**
     * Emits event with error text message
     * @param text of the message
     */
    function showError(text) {
        $rootScope.$emit(messageEvents.errorMessage, text);
    }

    /**
     * Emits event with success text message
     * @param text of the message
     */
    function showSuccess(text) {
        $rootScope.$emit(messageEvents.successMessage, text);
    }

    /**
     * Returns a promise to
     * translation of string with given key
     * (used to wrap translation library)
     * @param key
     * @returns promise of trasnlation
     */
    function translate(key) {
        return $translate(key);
    }

    function getPropertyValue(propertyName, obj) {
        var parts = propertyName.split('.');
        var property = obj || this;
        for (var i = 0, l = parts.length; i < l; i++ ) {
            property = property[parts[i]];
        }

        return property;
    }

    function isPropertyExists(propertyName, obj) {
        var parts = propertyName.split('.');
        for (var i = 0, l = parts.length; i < l; i++) {
            var part = parts[i];
            if (obj !== null && typeof obj === "object" && part in obj) {
                obj = obj[part];
            } else {
                return false;
            }
        }

        return true;
    }
}
}());
