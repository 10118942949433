;(function() {
"use strict";

/**
 * Materialy multimedialne.
 *
 * @Controller MultimediaController
 * @module resources
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('MultimediaController', MultimediaController);


    MultimediaController.$inject = ['resourceService', 'slideshowService' ,'CONFIG_TABLE'];
    function MultimediaController(resourceService, slideshowService, CONFIG_TABLE) {
        var vm = this;
        vm.videosAndPhotos = new Array();
        vm.presentations = new Array();
        vm.paginate = paginate;
        vm.loadData = loadData;
        vm.openSlideshow = openSlideshow;

        vm.loadData(urlParams);

        var urlParams = {
            offset: 0,
            limit: CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE,
        };

        /**
         *
         * @param offset
         */
        function paginate (offset) {
            urlParams.offset = offset;
            urlParams.limit = CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE;
            vm.loadData(urlParams);
            return;
        };

        /**
         *
         * @param urlParams
         */
        function loadData(urlParams) {
            resetCollections();
            resourceService.getResources(urlParams).then(
                function (response) {
                    switchItemsByType(response.data.items)
                    vm.meta = response.data.meta;
                },
                function (response, status) {
                }
            );

        }

        /**
         *
         * @param items
         */
        function switchItemsByType(items) {
            angular.forEach(items, function(item) {
                switch (item.type) {
                    case 'PHOTO':
                    case 'YOUTUBE':
                        vm.videosAndPhotos.push(item);
                        break;
                    case 'PRESENTATION':
                        vm.presentations.push(item)
                        break;
                }
            });
        }

        /**
         *
         */
        function resetCollections() {
            vm.videosAndPhotos = new Array();
            vm.presentations = new Array();
        }

        /**
         *
         * @param item
         * @param $event
         */
        function openSlideshow(item, $event) {
            $event.preventDefault();

            slideshowService.open(
                {
                    items: vm.videosAndPhotos,
                    selectedItem: item
                }
            );
        }
    }
})();
}());
