;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('youtubeUrlValidator', youtubeUrlValidator);

youtubeUrlValidator.$inject = ['formValidators'];

function youtubeUrlValidator(formValidators) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
                ctrl.$parsers.unshift(function (viewValue) {
                    ctrl.$setValidity('youtubeUrlValid', formValidators.youtubeUrlValidator(viewValue));
                    return viewValue;
                });
    }
    };
}
}());
