;(function() {
"use strict";

/**
 * Dyrektywa paska stanu zalogowania uzytkownika.
 *
 * @directive auth
 * @module layout
 * @project EWR
 */
angular
    .module('ewr.layout')
    .directive('auth', auth);

/**
 *
 * @returns {{restrict: string, templateUrl: string, controller: AuthController, controllerAs: string, bindToController: boolean, replace: boolean}}
 */
function auth() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/auth/auth.directive.html',
        controller: AuthController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

AuthController.$inject = ['$state', '$scope', 'authenticationService', 'formFactory', 'passwordService', 'profileService', 'authService', 'initService', 'Notification', 'constants', 'watchService'];

/**
 *
 * @param authenticationService
 * @param formFactory
 * @constructor
 */
function AuthController($state, $scope, authenticationService, formFactory, passwordService, profileService, authService, initService, Notification, constants, watchService) {
    const vm = this;
    vm.editProfile = editProfile;
    vm.changePassword = changePassword;
    vm.isAuthenticated = authenticationService.isAuthenticated();
    vm.login = login;
    vm.logout = logout;

    $scope.$watch(function () {
        return authenticationService.getUserData();
    }, function (userData) {
        vm.userData = userData;
    });

    $scope.$watch(function () {
        return watchService.shouldReload();
    }, function (shouldReload) {
        initService.init().then(function (response) {
            authenticationService.init(response);
        });
    });

    $scope.$watch(function () {
        return authenticationService.isAuthenticated();
    }, function (isAuthenticated) {
        vm.isAuthenticated = isAuthenticated;
        if(!isAuthenticated) {
            authenticationService.deauthenticate();
            initService.init().then(function (response) {
                authenticationService.init(response);
            });
        }
    });

    $scope.$watch(function () {
        return watchService.shouldShowChangePassForm();
    }, function (shouldShowChangePassForm) {
        if(shouldShowChangePassForm) {
            changePassword(false);
        }
    });

    function logout() {
        authService.logout().then(function (response) {
            Notification.success("Wylogowano poprawnie ...");
            authenticationService.deauthenticate();
            initService.init().then(function(response) {
                authenticationService.init(response);
                $state.go('home');
            });
        });
    }

    function login() {
        const formParams = {
            name: 'login',
            title: "Zaloguj się",
            restService: authService,
            customSubmitAction: function (params) {
                authService.login(params.model).then(
                    function(response) {
                        authenticationService.authenticate(response.headers('X-TOKEN'));
                        params.uibModalInstance.dismiss('cancel');
                        Notification.success("Zalogowano poprawnie ...");
                        initService.init().then(function(response) {
                            authenticationService.init(response);
                            $state.go('home');
                        })
                    }, function () {
                        Notification.warning("Logowanie nie udało się ...");
                    }
                );
            },
            size: constants.MODAL_SIZE_LOGIN,
            submitButtonLabel: 'Zaloguj'

        };
        formFactory.open(formParams);
    }

    function editProfile() {
        const formParams = {
            name: 'profile',
            title: "Edycja profilu użytkownika",
            restService: profileService,
            objId: 0
        };
        formFactory.open(formParams);
    }

    function changePassword(showCancelButton) {

        const formParams = {
            name: 'password',
            title: "Zmiana hasła",
            restService: passwordService,
            showCancelButton: showCancelButton,
            onSaveErrorCallback: function(response, Notification) {
                switch (response.data.code) {
                    case "USER-PASSWORD-NOT-MATCH":
                        Notification.warning("Wprowadzono nieprawidłowe hasło bieżące");
                        break;
                    case "PASSWORD-NOT-EQUAL":
                        Notification.warning("Nowe hasło i potwierdzenie hasła nie są takie same");
                        break;
                    default:
                        Notification.error("Wystąpił problem podczas zapisu");
                }
            },
            defaultExtraAction: function (params) {
                setTimeout(function() {
                    window.location.reload(true);
                }, 5000);

            }
        };

        formFactory.open(formParams);
    }

}
}());
