;(function() {
"use strict";

/**
 * Lista zawodów.
 *
 * @Controller OrgProfessionController
 * @module organizations
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.organizations')
        .controller('OrgProfessionController', OrgProfessionController);

    OrgProfessionController.$inject = ['$sce', 'PAGES', 'pageService', 'orgCommissionService', 'tableDefinitions'];

    function OrgProfessionController($sce, PAGES, pageService, orgCommissionService, tableDefinitions) {
        const vm = this;
        pageService.getByGuid(PAGES.ORG_PROFESSIONS).then(
            function (response) {
                vm.pageTitle = response.data.title;
                vm.pageContent = $sce.trustAsHtml(response.data.content); 
            },
            function (response, status) {
            }
        );

        const formParams = {
            restService: orgCommissionService,
        };

        const orgProfessionTableDefinition = tableDefinitions.getTableDefinitionOrgProfession(formParams);

        vm.listingColumnDefinitions = orgProfessionTableDefinition.listingColumnDefinitions;
        vm.tableActions = orgProfessionTableDefinition.tableActions;
        vm.orgCommissionService = orgCommissionService;
    }

})();
}());
