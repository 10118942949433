;(function() {
"use strict";

/**
 * Serwis obsługujący artykułu.
 *
 * @service articleService
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('articleService', ArticleService);

ArticleService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function ArticleService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        getFilter: getFilter,
        get: get,
        save: save,
        deleteItem: deleteItem,
        publish: publish,
        deactivate: deactivate,
        getNews: getNews,
        getNewsById: getNewsById,
        getOrganizationNews: getOrganizationNews,
        getOrganizationNewsById: getOrganizationNewsById
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_CMS_ARTICLE, params);
        return http.get(url);
    }

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_ARTICLE, 'filter');
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_ARTICLE, id);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_CMS_ARTICLE;
        if(item.id) {
            url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_ARTICLE, item.id);
            return http.put(url, item);
        }
        return http.post(url, item);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_ARTICLE, id);
        return http.delete(url);
    }

    function publish(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_CMS_ARTICLE, id, 'publish');
        return http.put(url);
    }

    function deactivate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_CMS_ARTICLE, id, 'deactivate');
        return http.put(url);
    }

    function getNews(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_NEWS, params);
        return http.get(url);
    }

    function getNewsById(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_NEWS, id);
        return http.get(url);
    }

    function getOrganizationNews(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_ORG_NEWS, params);
        return http.get(url);
    }

    function getOrganizationNewsById(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ORG_NEWS, id);
        return http.get(url);
    }

}
}());
