;(function() {
"use strict";

angular
    .module('ewr.common')
    .filter('examType', examType)
    .filter('examMode', examMode)
    .filter('examLevel', examLevel)
    .filter('taskStatus', taskStatus)
    .filter('userStatus', userStatus)
    .filter('collectionStatus', collectionStatus)
    .filter('professionStatus', professionStatus)
    .filter('professionName', professionName)
    .filter('fileStatus', fileStatus)
    .filter('fileType', fileType)
    .filter('organizationName', organizationName)
    .filter('studentStatus', studentStatus)
    .filter('scheduleStatus', scheduleStatus)
    .filter('scheduleStudentTaskStatus', scheduleStudentTaskStatus)
    .filter('examScore', examScore);

function examType() {
    return function(val) {
      if (val && val.length > 0) {
        switch (val) {
            case 'ORAL_EXAM':
                return 'Teoretyczny ustny';
            case 'WRITTEN_EXAM':
                return 'Teoretyczny pisemny';
            case 'PRACTICAL_EXAM':
                return 'Praktyczny';
        }
      }
      return '';
    };
}

function examMode() {
    return function(val) {
      if (val && val.length > 0) {
        switch (val) {
            case 'JOURNEYMAN':
                return 'Czeladnik';
            case 'MASTERS':
                return 'Mistrz';
        }
      }
      return '';
    };
}

function examLevel() {
    return function(val) {
      if (val) {
        switch (val) {
            case 1:
                return 'Łatwy';
            case 2:
                return 'Średni';
            case 3:
                return 'Trudny';
        }
      }
      return '';
    };
}

function taskStatus() {
    return function(val) {
      if (val) {
        switch (val) {
            case 'GENERATED':
                return 'Wygenerowany';
            case 'CREATED':
                return 'W edycji';
            case 'TO_APPROVE':
                return 'Do zatwierdzenia';
            case 'INACTIVE':
                return 'Nieaktywny';
            case 'ACTIVE':
                return 'Aktywny';
        }
      }
      return '';
    };
}

function userStatus() {
    return function(val) {
        if (val) {
            switch (val) {
            case 'INACTIVE':
                return 'Nieaktywny';
            case 'ACTIVE':
                return 'Aktywny';
            case 'BLOCKED':
                return 'Zablokowany';
            }
        }
        return '';
    };
}

function collectionStatus() {
    return function(val) {
      if (val) {
        switch (val) {
            case 'CREATED':
                return 'W edycji';
            case 'TO_APPROVE':
                return 'Do akceptacji';
            case 'INACTIVE':
                return 'Nieaktywny';
            case 'ACTIVE':
                return 'Aktywny';
        }
      }
      return '';
    };
}

function professionStatus() {
    return function(val) {
      if (val) {
        switch (val) {
            case 'INACTIVE':
                return 'Nieaktywny';
            case 'ACTIVE':
                return 'Aktywny';
        }
      }
      return '';
    };
}

function professionName() {
    return function(val) {
        return (val) ? val : 'Temat ogólny';
    };
}

function fileStatus() {
    return function(val) {
        if (val) {
            switch (val) {
                case 'DRAFT':
                    return 'Nieaktywny';
                case 'ACTIVE':
                    return 'Aktywny';
            }
        }
        return '';
    };
}

function fileType() {
    return function(val) {
        if (val) {
            switch (val) {
                case 'PHOTO':
                    return 'Zdjęcie';
                case 'YOUTUBE':
                    return 'Youtube';
                case 'PRESENTATION':
                    return 'Prezentacja';
            }
        }
        return '';
    };
}

function organizationName() {
    return function(val) {
        return (val) ? val : 'ZRP';
    };
}

function studentStatus() {
    return function(val) {
        if (val) {
            switch (val) {
                case 'REGISTERED':
                    return 'Do weryfikacji';
                case 'VERIFIED':
                    return 'Zweryfikowany';
                case 'BLOCKED':
                    return 'Zablokowany';
                case 'DELETED':
                    return 'Usunięty';
            }
        }
        return '';
    };
}

function scheduleStatus() {
    return function(val) {
      if (val) {
        switch (val) {
            case 'CREATED':
                return 'Do weryfikacji';
            case 'TO_APPROVE':
                return 'Do akceptacji';
            case 'APPROVED':
                return 'Zaakceptowany';
            case 'INACTIVE':
                return 'Nieaktywny';
            case 'ACTIVE':
                return 'Aktywny';
            case 'IN_PROGRESS':
                return 'W trakcie';
            case 'FINISHED':
                return 'Zakończony';
        }
      }
      return '';
    };
}

function scheduleStudentTaskStatus() {
    return function(val) {
      if (val) {
        switch (val) {
            case 'TO_APPROVE':
                return 'Do akceptacji';
            case 'APPROVED':
                return 'Zaakceptowany';
            case 'TO_GENERATE':
                return 'Do wygenerowania'
        }
      }
      return '';
    };
}

function examScore() {
    return function(val) {
      if (val == 0) {
         return 'n/a';
      }

      val = parseFloat(val);

      if (val < 2.86) {
          return 'Niedostateczny';
      }
      if (val >= 2.86 && val <= 3.55) {
          return 'Dostateczny';
      }
      if (val >= 3.56 && val <= 4.55) {
          return 'Dobry';
      }
      if (val >= 4.56 && val <= 5.55) {
          return 'Bardzo dobry';
      }
      if (val >= 5.56) {
          return 'Celujący';
      }
      return '';
    };
}
}());
