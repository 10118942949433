;(function() {
"use strict";

/**
 * Page directive
 */
angular
    .module('ewr.layout')
    .directive('page', page);

function page() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/page/page.directive.html',
        scope: {
            guid: '@'
        },
        controller: PageController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

PageController.$inject = ['$sce', 'pageService'];
function PageController($sce, pageService) {
    var vm = this;

    pageService.getByGuid(vm.guid).then(
        function (response) {
            vm.pageTitle = response.data.title;
            vm.pageContent = $sce.trustAsHtml(response.data.content);
        },
        function (response, status) {
        }
    );

}
}());
