;(function() {
"use strict";

angular.module('ewr.common')
    .factory('formDictionaryService', formDictionaryService);

formDictionaryService.$inject = ['http', 'API_CONFIG', 'constants'];

/**
 *
 * @param http
 * @param API_CONFIG
 * @param constants
 * @returns {{getDict: getDict}}
 */
function formDictionaryService(http, API_CONFIG, constants) {

    var service = {
        getDict: getDict
    };

    return service;

    /**
     *
     * @returns {*}
     */
    function getDict(name) {
        var url = '';
        switch (name) {
            case constants.FORM_DICT_ARTICLE:
                url = API_CONFIG().ENDPOINT_FORM_ARTICLE_DICTIONARY;
                break;
            case constants.FORM_DICT_ROLE:
                url = API_CONFIG().ENDPOINT_FORM_ROLE_DICTIONARY;
                break;
            case constants.FORM_DICT_USER:
                url = API_CONFIG().ENDPOINT_FORM_USER_DICTIONARY;
                break;
            case constants.FORM_DICT_EXAM:
                url = API_CONFIG().ENDPOINT_FORM_EXAM_DICTIONARY;
                break;
            case constants.FORM_DICT_EXAM_GENERATE:
                url = API_CONFIG().ENDPOINT_FORM_EXAM_GENERATE_DICTIONARY;
                break;
            case constants.FORM_DICT_TEST:
                url = API_CONFIG().ENDPOINT_FORM_TEST_DICTIONARY;
                break;
            case constants.FORM_DICT_TASK:
                url = API_CONFIG().ENDPOINT_FORM_TASK_DICTIONARY;
                break;
            case constants.FORM_DICT_COMMISSION:
                url = API_CONFIG().ENDPOINT_FORM_COMMISSION_DICTIONARY;
                break;
            case constants.FORM_DICT_RESOURCE:
                url = API_CONFIG().ENDPOINT_FORM_RESOURCE_DICTIONARY;
                break;
            case constants.FORM_DICT_REPORT:
                url = API_CONFIG().ENDPOINT_FORM_REPORT_DICTIONARY;
                break;
            case constants.FORM_DICT_ONLINE:
                url = API_CONFIG().ENDPOINT_FORM_STUDENT_DICTIONARY;
                break;
            case constants.FORM_DICT_SCHEDULE:
                url = API_CONFIG().ENDPOINT_ONLINE_SCHEDULE_DICTIONARY;
                break;
        }
        return http.get(url);
    }
}
}());
