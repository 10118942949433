;(function() {
"use strict";

/**
 * Krajowy rejestr kwalifikacji.
 *
 * @Controller KrkController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('KrkController', KrkController);

    KrkController.$inject = ['PAGES'];
    function KrkController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;

    }
})();
}());
