;(function() {
"use strict";

/**
 * Admin - zarządzanie raportami.
 *
 * @Controller AdminReportController
 * @module admin
 * @project EWR
 */
angular.module('ewr.admin')
    .controller('AdminReportController', AdminReportController);

AdminReportController.$inject = ['Notification', 'reportService', 'fileUtils'];
function AdminReportController(Notification, reportService, fileUtils) {

    const vm = this;

    vm.generate = function (data) {
        return reportService.generate(data).then(
            function(response) {
                fileUtils.downloadFile(response.data);
                Notification.success("Wygenerowano raport");
            }, function (response) {
                // TODO add suitable message from procedure call !!!
                Notification.error("Generowanie nie udało się ...");
            }
        );
    }

}
}());
