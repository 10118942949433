;(function() {
"use strict";

/**
 * Serwis odpowiedzialny za przechowywanie danych autentykacji.
 *
 * @service authenticationService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('authenticationService', authenticationService);

authenticationService.$inject = ['$cookies', '$window', 'constants', 'Idle', 'Keepalive', '$uibModalStack', 'aclService', 'watchService'];

/**
 * 
 * @param $state
 * @param $cookies
 * @param constants
 */
function authenticationService($cookies, $window, constants, Idle, Keepalive, $uibModalStack, aclService, watchService) {

    var userData;

    return {
        authenticate: authenticate,
        deauthenticate: deauthenticate,
        extendSession: extendSession,
        getToken: getToken,
        isAuthenticated: isAuthenticated,
        getUserData: getUserData,
        init: init
    };

    /**
     *
     * @param token
     * @param tokenExpiration
     */
    function authenticate(token) {

        saveTokenCookies(token, constants.SESSION_IDLE_TIME);
        
        console.info("Idle monitoring turned on.");
        Idle.watch();
        Idle.setIdle(constants.SESSION_IDLE_TIME);
        Keepalive.start();

    }

    /**
     *
     * @param token
     * @param tokenExpiration
     */
    function saveTokenCookies(token, tokenExpiration) {
        var expireDate = new Date();

        expireDate.setTime(expireDate.getTime() + tokenExpiration * 1000);

        $cookies.put(constants.COOKIE_SESSION_KEY, token, {'expires': expireDate});
        Keepalive.setInterval(tokenExpiration / constants.SESSION_TIMES_TO_PING);
    }

    /**
     *
     * @returns {promise|void}
     */
    function deauthenticate() {
        $cookies.remove(constants.COOKIE_SESSION_KEY);

        console.info("Idle monitoring turned off.");
        Idle.unwatch();
        Keepalive.stop();
        $uibModalStack.dismissAll('logout');
    }

    /**
     *
     * @returns {null}
     */
    function getToken() {
        var token = $cookies.get(constants.COOKIE_SESSION_KEY);
        return token ? token : null;
    }

    /**
     *
     * @param token
     * @param newTokenExpiration
     */
    function extendSession(token, newTokenExpiration) {
        var expireDate = new Date();
        expireDate.setTime(expireDate.getTime() + newTokenExpiration * 1000);

        $cookies.put(constants.COOKIE_SESSION_KEY, token, {'expires': expireDate});
    }

    /**
     *
     * @returns {boolean}
     */
    function isAuthenticated() {
        if (!getToken()) {
            return false;
        }
        return true;
    }

    /**
     *
     * @returns {*}
     */
    function getUserData() {
        return userData;
    }

    /**
     * 
     * @param initialData
     */
    function init(initialData) {
        try {
            if ('permissions' in initialData.data) {
                aclService.setPermissions(initialData.data.permissions);
            }

            if ('roles' in initialData.data) {
                aclService.setRoles(initialData.data.roles);
            }

            if ('userData' in initialData.data) {
                userData = initialData.data.userData;

                // Przekierowanie na zmianę wymuszoną zmianę hasła
                if(userData && 'expiredPass' in userData ) {
                    if (userData.expiredPass) {
                        watchService.setShowChangePassForm(true);
                    }
                }
            }
        } catch(e) {
            deauthenticate();
        }
    }
}
}());
