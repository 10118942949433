;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('hasRole', hasRole)
    .directive('hasPermission', hasPermission)
    .directive('hasOneOfRoles', hasOneOfRoles)
    .directive('hasOneOfPermissions', hasOneOfPermissions)

hasRole.$inject = ['aclService'];
function hasRole(aclService) {

    var directiveDefinitionObject = {
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$watch(function () {
                return aclService.getRoles();
            }, function (roles) {
                if(!aclService.hasRole(attr.hasRole)) {
                    elem.hide();
                }
            });
        }
    };
    return directiveDefinitionObject;
}

hasPermission.$inject = ['aclService'];
function hasPermission(aclService) {

    var directiveDefinitionObject = {
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$watch(function () {
                return aclService.getPermissions();
            }, function (permissions) {
                if(!aclService.hasPermission(attr.hasPermission)) {
                    elem.hide();
                } else {
                    elem.show();
                }
            });

        }
    };
    return directiveDefinitionObject;
}

hasOneOfRoles.$inject = ['aclService'];
function hasOneOfRoles(aclService) {

    var directiveDefinitionObject = {
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$watch(function () {
                return aclService.getRoles();
            }, function (roles) {
                var roleArray = attr.hasOneOfRoles.split(",");
                if(!aclService.hasOneOfRoles(roleArray)) {
                    elem.hide();
                }
            });

        }
    };
    return directiveDefinitionObject;
}

hasOneOfPermissions.$inject = ['aclService'];
function hasOneOfPermissions(aclService) {

    var directiveDefinitionObject = {
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$watch(function () {
                return aclService.getPermissions();
            }, function (permissions) {
                var permissionArray = attr.hasOneOfPermissions.split(",");
                if(!aclService.hasOneOfPermissions(permissionArray)) {
                    elem.hide();
                } else {
                    elem.show();
                }
            });

        }
    };
    return directiveDefinitionObject;
};
}());
