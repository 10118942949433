;(function() {
"use strict";

/**
 * Dyrektywa zarządzajaca polityką cookies.
 *
 * @component cookiePolicy
 * @module layout
 */
angular
    .module('ewr.layout')
    .directive('cookiePolicy', cookiePolicy);

function cookiePolicy() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/cookiepolicy/cookiepolicy.directive.html',
        controller: CookiePolicyController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

CookiePolicyController.$inject = ['$cookies', 'constants'];
function CookiePolicyController($cookies, constants) {
    var vm = this;
    vm.agree = agree;
    vm.showCookiePolicy = true;

    if($cookies.get(constants.COOKIE_POLICY_DECISION_KEY)) {
        vm.showCookiePolicy = false;
    }

    function agree() {
        var expireDate = new Date();

        expireDate.setTime(expireDate.getTime() + 1000*100000000);
        $cookies.put(constants.COOKIE_POLICY_DECISION_KEY, constants.COOKIE_POLICY_DECISION_VALUE, {'expires': expireDate});
        vm.showCookiePolicy = false;
    }

}
}());
