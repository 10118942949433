;(function() {
"use strict";

/**
 * Przypomnienie hasła
 *
 * @Controller ResetPasswordConfirmController
 * @module layout
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.auth')
        .controller('ResetPasswordConfirmController', ResetPasswordConfirmController);

    ResetPasswordConfirmController.$inject = ['$state', 'authService', 'formFactory', '$timeout', 'Notification', '$stateParams'];

    function ResetPasswordConfirmController($state, authService, formFactory, $timeout, Notification, $stateParams) {
        authService.resetPasswordConfirmation($stateParams.hash).then(
            function () {
                setNewPasswordForm(authService, Notification, $state, formFactory, $stateParams.hash, $timeout)
            },
            function() {
                Notification.warning("Wystąpił problem podczas odzyskiwania hasła ...");
                $state.go('home');
            }
        );
    }

    function setNewPasswordForm(authService, Notification, state, formFactory, hash, timeout) {

        const formParams = {
            name: 'reset-password-set-new',
            title: "Ustal nowe hasło",
            restService: authService,
            customSubmitAction: function (params) {
                const data = {
                    hash: hash,
                    password: params.model.password,
                    passwordRepeat: params.model.passwordRepeat
                };
                authService.passwordResetCommit(data).then(
                    function() {
                        state.go('home');
                        Notification.success("Poprawnie nadano nowe hasło ...");
                        params.uibModalInstance.dismiss('cancel');
                    }, function () {
                        Notification.warning("Wystąpił problem podczas odzyskiwania hasła ...");
                    }
                );
            },
            customCancelAction: function (params) {
                params.uibModalInstance.dismiss('cancel');
                state.go('home');
            },
            submitButtonLabel: 'Zapisz'
        };

        timeout(function() {
            formFactory.open(formParams);
        },500);
    }
})();
}());
