;(function() {
"use strict";

/**
 * Serwis obsługujący zadania.
 *
 * @service taskService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('taskService', TaskService);

TaskService.$inject = ['http', 'utilsService', 'API_CONFIG', '$filter'];

function TaskService(http, utils, API_CONFIG, $filter) {
    var service = {
        getList: getList,
        getFilter: getFilter,
        get: get,
        save: save,
        deleteItem: deleteItem,
        sendToApprove: sendToApprove,
        approve: approve,
        reject: reject,
        activate: activate,
        deactivate: deactivate,
        resumption: resumption
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, params);
        return http.get(url);
    }

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, 'filter');
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_TASK;

        var answers = $filter('objectToArray')(item.answers);
        item.answers = answers;

        switch (item.type) {
            case 'ORAL_EXAM':
                item.answers = item.answers.slice(0, 1);
                item.answers[0]['correct'] = true;
                break;
            case 'WRITTEN_EXAM':
                break;
            case 'PRACTICAL_EXAM':
                item.answers = [];
                item.topicId = null;
                item.level = null;
                break;
            default:
                return;
        }

        if (item.id) {
            url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, item.id);
            return http.put(url, item);
        }

        return http.post(url, item);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id);
        return http.delete(url);
    }

    function sendToApprove(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id, 'send-to-approve');
        return http.put(url);
    }

    function approve(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id, 'approve');
        return http.put(url);
    }

    function reject(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id, 'reject');
        return http.put(url);
    }

    function activate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id, 'activate');
        return http.put(url);
    }

    function deactivate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id, 'deactivate');
        return http.put(url);
    }

    function resumption(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TASK, id, 'resumption');
        return http.put(url);
    }

}
}());
