;(function() {
"use strict";

/**
 * Dyrektywa - karuzela
 */
angular
    .module('ewr.layout')
    .directive('carousel', carousel);

function carousel() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/carousel/carousel.directive.html',
        scope: {},
        controller: CarouselController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

CarouselController.$inject = ['$scope', 'carouselService'];

function CarouselController($scope, carouselService) {
    var vm = this;

    vm.carousel = [];

    carouselService.getAllList().then(
        function (response) {
            vm.carousel = response.data;
        },
        function (response, status) {
            // TODO
        }
    );

}
}());
