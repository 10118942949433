;(function() {
"use strict";

/**
 * Moduł Egzaminy.
 *
 * @module exams
 * @project EWR
 *
 * @param $stateProvider
 */
angular
    .module('ewr.exams', [], config);


config.$inject = ['$stateProvider'];

/**
 * Funkcja reprezentujaca routing w module Izby.
 *
 * @param $stateProvider
 */
function config($stateProvider) {
    $stateProvider
        .state('examExam', {
            parent: 'index',
            url: '/egzaminy/zestawy-egzaminacyjne',
            views: {
                'content@index': {
                    templateUrl: 'app/exams/templates/exam/list.html',
                    controller: 'ExamController as vm'
                }
            },
            auth: true
        })
        .state('examExamDetails', {
            parent: 'index',
            url: '/egzaminy/zestawy-egzaminacyjne/:id',
            views: {
                'content@index': {
                    templateUrl: 'app/exams/templates/exam/details.html',
                    controller: 'ExamDetailsController as vm'
                }
            },
            auth: true
        })
        .state('examTest', {
            parent: 'index',
            url: '/egzaminy/zestawy-testowe',
            views: {
                'content@index': {
                    templateUrl: 'app/exams/templates/test/list.html',
                    controller: 'TestController as vm'
                }
            },
            auth: true
        })
        .state('examTestDetails', {
            parent: 'index',
            url: '/egzaminy/zestawy-testowe/:id',
            views: {
                'content@index': {
                    templateUrl: 'app/exams/templates/test/details.html',
                    controller: 'TestDetailsController as vm'
                }
            },
            auth: true
        })
        .state('examTask', {
            parent: 'index',
            url: '/egzaminy/zadania',
            views: {
                'content@index': {
                    templateUrl: 'app/exams/templates/task/list.html',
                    controller: 'TaskController as vm'
                }
            },
            auth: true
        })
        ;
}
}());
