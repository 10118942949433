;(function() {
"use strict";

/**
 * O egzaminach
 *
 * @Controller ExamInfoController
 * @module home
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.home')
        .controller('ExamInfoController', ExamInfoController);

    ExamInfoController.$inject = ['PAGES'];
    function ExamInfoController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }
})();
}());
