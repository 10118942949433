;(function() {
"use strict";

/**
 * Serwis obsługujący walidacje formularzy.
 *
 * @service formValidators
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('formExpressionProperties', formExpressionProperties);

formExpressionProperties.$inject = ['$filter'];

/**
 *
 * @returns {{get: get, contentExpressionProperty: contentExpressionProperty}}
 */
function formExpressionProperties($filter) {

    /**
     *
     * @type {{contentExpressionProperty: *}}
     */
    var epressionProperties = {
        taskCorrectAnswerFirstExpressionProperty: taskCorrectAnswerFirstExpressionProperty(),
        taskCorrectAnswerSecondExpressionProperty: taskCorrectAnswerSecondExpressionProperty(),
        taskCorrectAnswerThirdExpressionProperty: taskCorrectAnswerThirdExpressionProperty(),
        taskTypeOnChangeExpression: taskTypeOnChangeExpression(),
        taskModeOnChangeExpression: taskModeOnChangeExpression(),
        permissionsOnLoadExpression: permissionsOnLoadExpression(),
        onlineOrganizationTypeOnChangeExpression: onlineOrganizationTypeOnChangeExpression(),
        onlineprofessionOnChangeExpression: onlineprofessionOnChangeExpression()
    };

    var service = {
        get: get,
        taskCorrectAnswerFirstExpressionProperty: taskCorrectAnswerFirstExpressionProperty,
        taskCorrectAnswerSecondExpressionProperty: taskCorrectAnswerSecondExpressionProperty,
        taskCorrectAnswerThirdExpressionProperty: taskCorrectAnswerThirdExpressionProperty,
        taskTypeOnChangeExpression: taskTypeOnChangeExpression,
        taskModeOnChangeExpression: taskModeOnChangeExpression,
        permissionsOnLoadExpression: permissionsOnLoadExpression,
        onlineOrganizationTypeOnChangeExpression: onlineOrganizationTypeOnChangeExpression,
        onlineprofessionOnChangeExpression: onlineprofessionOnChangeExpression
    };

    return service;

    /**
     *
     * @param name
     * @returns {*}
     */
    function get(name) {
        if(name in epressionProperties) {
            return epressionProperties[name];
        }
        return name;
    }

    /**
     *
     * @returns {Function}
     */
    function taskCorrectAnswerFirstExpressionProperty() {
        return {
            'templateOptions.disabled':
                function($viewValue, $modelValue, scope) {
                    return disableAnswerFields(0, scope.model.answers);
                }
        }
    }

    function taskCorrectAnswerSecondExpressionProperty() {
        return {
            'templateOptions.disabled':
                function($viewValue, $modelValue, scope) {
                    return disableAnswerFields(1, scope.model.answers);
                }
        }
    }

    function taskCorrectAnswerThirdExpressionProperty() {
        return {
            'templateOptions.disabled':
                function($viewValue, $modelValue, scope) {
                    return disableAnswerFields(2, scope.model.answers);
                }
        }
    }

    function disableAnswerFields(index, answers) {
        var disable = false;
        var correctIdx = null;
        angular.forEach(answers, function(answer, idx) {
            if(answer.correct) {
                disable = true;
                correctIdx = idx;
            }
        });
        if(disable && (correctIdx != index)) {
            return true;
        }
        return false;
    }

    function taskTypeOnChangeExpression() {
        return function($modelValue, fieldOptions, scope, event) {

            var options = [];
            if (scope.model.type !== null && scope.model.type !== undefined) {
                var dictTaskConfig = getDictionaryByName(scope.$parent.form.dicts, 'taskConfig');

                angular.forEach(dictTaskConfig.items, function (item) {
                    if (item.extra.type === scope.model.type) {
                        options.push(
                            {
                                name: $filter('examMode')(item.extra.mode),
                                value: item.extra.mode
                            }
                        );
                    }
                });
            }

            options.unshift({
                name: '-- wybierz --',
                value: null
            });
            options = $filter('unique')(options, 'value');

            updateFieldOptions("mode", options, scope);
            scope.model.mode = null;
        }
    }

    function taskModeOnChangeExpression() {
        return function($modelValue, fieldOptions, scope, event) {
            var configs = [];
            if (scope.model.type !== null && scope.model.type !== undefined) {
                var dictTaskConfig = getDictionaryByName(scope.$parent.form.dicts, 'taskConfig');
                angular.forEach(dictTaskConfig.items, function (item) {
                    if (item.extra.type === scope.model.type && item.extra.mode === scope.model.mode) {
                        configs.push(item.extra.topicId);
                    }
                });
            }

            var dictTopic = getDictionaryByName(scope.$parent.form.dicts, 'topic');
            var options = [];
            angular.forEach(dictTopic.items, function (item) {
                if (configs.indexOf(item.value) !== -1) {
                    options.push({
                        name: item.name,
                        value: item.value
                    });
                }
            });

            options.unshift({
                name: '-- wybierz --',
                value: null
            });
            updateFieldOptions("topicId", options, scope);

            scope.model.topicId = null;
        }
    }

    function permissionsOnLoadExpression() {
        return {
            'templateOptions.disabled':
                function($viewValue, $modelValue, scope) {
                configurePermissions($modelValue, scope.options);
            }
        }
    }

    function onlineOrganizationTypeOnChangeExpression() {
        return function($modelValue, fieldOptions, scope, event) {
            var extra = [];

            if (scope.model.organizationId !== null && scope.model.organizationId !== undefined) {
                var dictOrg = getDictionaryByName(scope.$parent.form.dicts, 'organization');

                angular.forEach(dictOrg.items, function (item) {
                    if (item.value === scope.model.organizationId) {
                        extra = item.extra;
                    }
                });
            }

            var options = [];
            angular.forEach(extra, function (item) {
                var isAdd = true;
                angular.forEach(options, function(option) {
                    if (option.name === item.profession.name) {
                        isAdd = false;
                    }
                });

                if (isAdd === true) {
                    options.push({
                        name: item.profession.name,
                        value: item.profession.id
                    });
                }
            });

            options.unshift({
                name: '-- wybierz --',
                value: null
            });

            updateFieldOptions("professionId", options, scope);

            scope.model.professionId = null;
        }
    }

    function onlineprofessionOnChangeExpression() {
        return function($modelValue, fieldOptions, scope, event) {
            var extra = [];

            if (scope.model.organizationId !== null && scope.model.organizationId !== undefined) {
                var dictOrg = getDictionaryByName(scope.$parent.form.dicts, 'organization');

                angular.forEach(dictOrg.items, function (item) {
                    if (item.value === scope.model.organizationId) {
                        extra = item.extra;
                    }
                });
            }

            var options = [];
            angular.forEach(extra, function (item) {
                var isAdd = true;
                angular.forEach(options, function(option) {
                    if (option.value === item.mode) {
                        isAdd = false;
                    }
                });

                if (isAdd === true) {
                    var mode = '';

                    if (item.mode === 'JOURNEYMAN')
                        mode = 'Czeladnik';
                    else if (item.mode === 'MASTERS')
                        mode = 'Mistrz';

                    options.push({
                        name: mode,
                        value: item.mode
                    });
                }
            });

            options.unshift({
                name: '-- wybierz --',
                value: null
            });

            updateFieldOptions("examMode", options, scope);

            scope.model.examMode = null;
        }
    }

    function configurePermissions($modelValue, fieldOptions) {
        updateValues(fieldOptions);
    }

    function findIdxByModelValue(value, options) {
        var idx = -1;
        angular.forEach(options, function (object, key) {
           if(object.value == value) {
               idx = key;
           }
        });
        return idx;
    }

    function updateValues(fieldOptions) {
        var values = [];
        var options = fieldOptions.templateOptions.options;
        angular.forEach(options, function (option) {
            if(isEnabledAndChecked(option.value, options)) {
                values.push(option.value);
            }
        });
        fieldOptions.value(values);
    }

    function isEnabledAndChecked(value, options) {
        var id = findIdxByModelValue(value, options);
        var elementId = "vm.form_multiCheckbox_permissions_1_{0}".format(id);
        var element = document.getElementById(elementId);
        return !element.hasAttribute("disabled") && element.checked;
    }

    function updateFieldOptions(fieldName, newOptions, scope) {
        angular.forEach(scope.fields, function (field) {
            if(field.key === fieldName) {
                field.templateOptions.options = newOptions;
            }
        });
    }

    function getDictionaryByName(dicts, name) {
        var result = null;
        angular.forEach(dicts, function(dict, index) {
            if (dict.name === name) {
                result = dict;
            }
        });

        return result;
    }

}
}());
