;(function() {
"use strict";

/**
 * Serwis pobierający definicje formularzy.
 *
 * @service formService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('formService', formService);

formService.$inject = ['http', 'API_CONFIG'];

function formService(http, API_CONFIG) {
    var service = {
        get: get
    };

    return service;

    function get(name) {
        var url = API_CONFIG().ENDPOINT_FORM_DEFINITIONS.format(name);
        return http.get(url);
    }

}
}());
