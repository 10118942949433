;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('carouselService', CarouselService);

CarouselService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function CarouselService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        getAllList: getAllList,
        get: get,
        save: save,
        deleteItem: deleteItem,
        activate: activate,
        deactivate: deactivate
    };

    return service;

    function getAllList() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_CAROUSEL, 'list');
        return http.get(url);
    }

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_CMS_CAROUSEL, params);
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_CAROUSEL, id);
        return http.get(url);
    }

    function save(item) {
        // TODO redundant data - should be configured spring/json mapper and pass item ???
        var carousel = {
            'header': item.header,
            'content': item.content,
            'url': item.url,
            'orderNo': item.orderNo,
            'idFile': item.idFile
        };

        var url = API_CONFIG().ENDPOINT_CMS_CAROUSEL;
        if (item.id) {
            url = '{0}/{1}'.format(url, item.id);
            return http.put(url, carousel);
        }

        return http.post(url, carousel);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_CAROUSEL, id);
        return http.delete(url);
    }

    function activate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_CMS_CAROUSEL, id, 'activate');
        return http.put(url);
    }

    function deactivate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_CMS_CAROUSEL, id, 'deactivate');
        return http.put(url);
    }

}
}());
