;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('ewrTable', directiveEwrTable)
    .directive('ewrTableRow', directiveEwrTableRow)
    .directive('ewrTablePagination', directiveEwrTablePagination);

directiveEwrTable.$inject = [];

function directiveEwrTable() {
        var directiveDefinitionObject = {
            restrict: 'E',
            scope: {
                columnDefinitions: '=',
                api: '=?',
                actions: '=?',
                ident: '=?',
                table: '@',
                customData: '=?',
                customSearch: '=?',
                apicall: '=?',
                checktable: '=?',
                hidePagination: '=?',
                selectedRows: '=?',
                clickRowAction: '&?',
                defaultLimit: '@?',
                autoLoad: '=?',
                defaultSort: '=?'
            },
            templateUrl: function () {
                return 'app/common/directives/ui/components/table/templates/table.html?v=' + (new Date()).getTime();
            },
            controller: directiveCaplselTableController
        };

        return directiveDefinitionObject;
}
directiveCaplselTableController.$inject = ['$scope', '$attrs', '$timeout', 'CONFIG_TABLE', 'CONFIG_TABLE_TYPES', 'Notification', 'watchService'];
directiveEwrTableRow.$inject = ['$sce', '$compile', 'utilsService', 'aclService'];
directiveEwrTablePagination.$inject = ['$compile'];

function directiveCaplselTableController($scope, $attrs, $timeout, CONFIG_TABLE, CONFIG_TABLE_TYPES, Notification, watchService) {
        var SORT_UP_SUFFIX = '_sort_up';
        var SORT_DOWN_SUFFIX = '_sort_down';

        $scope.limit = $scope.defaultLimit ? parseInt($scope.defaultLimit) : CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE;
        $scope.limits = [10, 50, 100];
        $scope.defaultSort = $scope.defaultSort ? $scope.defaultSort : { sortAsc: false, sortBy: 'id' };

        var urlParams = {
            offset: 0,
            limit: $scope.limit,
            sortAsc: $scope.defaultSort.sortAsc,
            sortBy: $scope.defaultSort.sortBy,
            search: '',
            id: $scope.ident
        };

        if (!$scope.selectedRows) {
            $scope.selectedRows = [];
        }

        var searchParams = {};

        $scope.allSearchParams = {};

        angular.forEach($scope.columnDefinitions, function(val, column) {
            if (!val.css) {
                val.css = '';
            }

            if(val.searchable == true) {
                switch(val.type) {
                    case CONFIG_TABLE_TYPES.DATE:
                        $scope.allSearchParams[column + '_ge_'] = ({key: column + '_ge_', name: val.value + " after", placeholder: val.value, isDate: true, type: CONFIG_TABLE_TYPES.DATE});
                        $scope.allSearchParams[column + '_le_'] = ({key: column + '_le_', name: val.value + " before", placeholder: val.value, isDate: true, type: CONFIG_TABLE_TYPES.DATE});
                        break;
                    case CONFIG_TABLE_TYPES.NUMBER:
                        $scope.allSearchParams[column + '_gt_'] = ({key: column + '_gt_', name: val.value + " greater", placeholder: val.value, isDate: false, type: CONFIG_TABLE_TYPES.NUMBER});
                        $scope.allSearchParams[column + '_lt_'] = ({key: column + '_lt_', name: val.value + " less", placeholder: val.value, isDate: false, type: CONFIG_TABLE_TYPES.NUMBER});
                        break;
                    default:
                        $scope.allSearchParams[column] = ({key: column, name: val.value, placeholder: val.value, isDate: false, type: CONFIG_TABLE_TYPES.TEXT});
                }
            }
        });

        $scope.availableSearchParams = angular.copy($scope.allSearchParams);
        $scope.enableSearch = Object.keys($scope.allSearchParams).length > 0 || ($scope.customSearch != null);

        /**
         * Loads data to table.
         * @param params
         */
        $scope.loadTable = function (urlParams) {
            if (!('customData' in $attrs)) {
                var apiCall;
                if('apicall' in $attrs) {
                    apiCall = $scope.api[$attrs.apicall];
                } else {
                    apiCall = $scope.api.getList;
                }
                // Call backend service.

                apiCall(urlParams).then(function (response) {
                    $timeout(function () {
                        $scope.data = response.data.items;
                        $scope.meta = response.data.meta;
                        $scope.$apply();
                    });

                });
            } else {
                $scope.data = { items: $scope.customData };
            }
        };
        /**
         * Paginates the table.
         * @param pageNumber
         */
        $scope.paginate = function (offset) {
            urlParams.offset = offset;
            $scope.loadTable(urlParams);

        };

        $scope.sortBy = function (sortBy, sortAsc) {
            urlParams.sortAsc = sortAsc;
            urlParams.sortBy = sortBy;
            changeSortDirection(sortBy, sortAsc);
            $scope.loadTable(urlParams);

        };

        $scope.changeLimit = function () {
            urlParams.offset = 0;
            urlParams.limit = $scope.limit;
            $scope.loadTable(urlParams);

        };

        $scope.refreshSearch = function(params) {

            var oldParams = angular.copy(urlParams);
            urlParams = {
                offset: 0,
                limit: oldParams.limit,
                sortAsc: oldParams.sortAsc,
                sortBy: oldParams.sortBy,
                id: oldParams.id
            };

            if($scope.customSearch != null) {
                angular.forEach($scope.customSearch, function(item) {
                    urlParams[item.key] = item.value;
                });
            }

            $scope.loadTable(urlParams);
        };

        if($scope.customSearch != null) {
            $scope.refreshSearch();
        }

        $scope.$watch("customSearch", function(newValue, oldValue) {
            if(!angular.equals(newValue, oldValue)) {
                $scope.refreshSearch(newValue);
            }
        });
        $scope.$watch("searchParams", function(newValue, oldValue) {
            if(!angular.equals(newValue, oldValue)) {
                $scope.refreshSearch(newValue);
            }
        });

        $scope.searchBy = function (by, value, operation) {
            if (operation == null) {
                operation = ':';
            }
            if(searchParams[by] == null) {
                searchParams[by]= {};
            }
            searchParams[by][operation] = value;

            urlParams.search = '';

            angular.forEach(searchParams, function (value, key) {
                if (value) {
                    angular.forEach(value, function(val, op) {
                        urlParams.search += key + op + val + ',';
                    });
                }
            });

            if (urlParams.search.charAt(urlParams.search.length - 1) == ',') {
                urlParams.search = urlParams.search.slice(0, -1);

            }

            $scope.loadTable(urlParams);
        };

        /**
         * Change sort directions
         * @param column
         * @return void
         */
        var changeSortDirection = function (column, directionAsc) {

            resetAllSortDirections();
            var sortColumnUp = column + SORT_UP_SUFFIX;
            var sortColumnDown = column + SORT_DOWN_SUFFIX;
            if (directionAsc === false) {
                $scope[sortColumnUp] = true;
                $scope[sortColumnDown] = false;
            } else {
                $scope[sortColumnUp] = false;
                $scope[sortColumnDown] = true;
            }
        };
        /**
         * Reset sort directions of specific column.
         * @param column
         * @return void
         */
        var defaultSortDirections = function (column) {
            var sortColumnUp = column + SORT_UP_SUFFIX;
            var sortColumnDown = column + SORT_DOWN_SUFFIX;
            $scope[sortColumnUp] = true;
            $scope[sortColumnDown] = true;
        };
        /**
         * Reset all sort directions.
         * @param column
         * @return void
         */
        var resetAllSortDirections = function () {
            angular.forEach($scope.columnDefinitions, function (value, key) {
                defaultSortDirections(key);
            });
        };
        resetAllSortDirections();
        $scope.withActionColumn = $scope.actions.length;

        $scope.$watch(function () {
            return watchService.shouldReload();
        }, function (shouldReload) {
            if ($scope.autoLoad !== false) {
                console.log("Table reloaded ...");
                $scope.loadTable(urlParams);
            }
        });
}


function directiveEwrTableRow($sce, $compile, utilsService, aclService) {
    /**
     * Returns table items.
     *
     * @param contentType
     * @returns {string}
     */
    var getTemplate = function (scope) {
        var template = '';

        if (scope.checktable === true) {
            if (scope.selectedRows.indexOf(scope.item.id) !== -1) {
                scope.selected = true;
            }
            template += '<td><input ng-model="selected" type="checkbox" ng-click="selectRow(' + scope.item.id + ')"/></td>';
        }

        angular.forEach(scope.columns, function (column, key) {
            if (utilsService.isPropertyExists(key, scope.item) && column.show != false) {
                var value = utilsService.getPropertyValue(key, scope.item);
                if('filter' in column) {
                    value = column.filter(scope.item);
                }

                var serializedItem = "'" + window.btoa(encodeURI(JSON.stringify(scope.item))) + "'";
                var tooltipPlacement = ('tooltipPlacement' in column) ? column.tooltipPlacement : 'bottom';

                if ('tooltip' in column) {
                    template += '<td ng-click="clickRow(' + serializedItem + ')" class=\"center\"><span uib-tooltip-html="getTooltipContent(' + serializedItem + ',\'' + key + '\') | trustHtml" tooltip-placement="' + tooltipPlacement + '">' + value + "</span></td>";
                } else {
                    template += '<td ng-click="clickRow(' + serializedItem + ')" class=\"center\"><span uib-tooltip="' + value + '" tooltip-placement="' + tooltipPlacement + '">' + value + "</span></td>";
                }

            }
        });

        var actionClass = '';
        if(scope.actions.length < 3) {
            actionClass = 'table-action-column-small';
        } else if (scope.actions.length === 3) {
            actionClass = 'table-action-column-medium';
        } else {
            actionClass = 'table-action-column-large';
        }

        /**
         * Action column
         * @type {string}
         */
        var actionRow = '';
        if (scope.actions.length > 0) {

            actionRow = '<td class="td-actions td-no-padding"><span class="td-no-padding-text"></span> <span class="td-no-padding-btn pull-right"> <div class="dropdown"> <i class="fa fa-ellipsis-h fa-2x dropdown-toggle" id="dropdownTdMenu" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i> <ul class="dropdown-menu" aria-labelledby="dropdownTdMenu">'
                .format(actionClass);
            angular.forEach(scope.actions, function (action) {
                var serializedItem = window.btoa(encodeURI(JSON.stringify(scope.item)));

                var showItem = true;
                var showItemConditionally = true;
                if('roles' in action) {
                    var roles = action.roles.split(",");
                    showItem = aclService.hasOneOfRoles(roles);
                }

                if('permissions' in action) {
                    var permissions = action.permissions.split(",");
                    showItem = aclService.hasOneOfPermissions(permissions);
                }

                if('condition' in action) {
                    showItemConditionally = action.condition(serializedItem);
                }
                if(showItem && showItemConditionally) {
                    actionRow += '<li>';
                    actionRow += action.button.format("'" + serializedItem + "'");
                    actionRow += '</li>';
                    scope[action.actionName] = action.action;
                }

            });
            actionRow += '</ul></div></span></td>'
        }
        return template + actionRow;
    };

    /**
     * Linker function
     * @param scope
     * @param element
     * @param attrs
     */
    var linker = function (scope, element, attrs) {

        scope.selectRow = function (id) {
            if (scope.selected) {
                scope.selectedRows.push(id);
            } else {
                var index = scope.selectedRows.indexOf(id);
                if (index !== -1) {
                    scope.selectedRows.splice(index, 1);
                }
            }
        };

        scope.clickRow = function (serializedItem) {
            var row = angular.fromJson(decodeURI(window.atob(serializedItem)));
            if (angular.isDefined(scope.clickRowAction)) {
                scope.clickRowAction({
                    item: row
                });
            }
        };

        scope.getTooltipContent = function (serializedItem, key) {
            var row = angular.fromJson(decodeURI(window.atob(serializedItem)));
            var column = scope.columns[key];
            return column.tooltip(row);
        };

        scope.$watch(function () {
            return aclService.initialized();
        }, function (initialized) {
            element.html(getTemplate(scope)).show();
            $compile(element.contents())(scope);
        });
    };

    var directiveDefinitionObject = {
        restrict: 'A',
        link: linker,
        scope: {
            item: '=',
            columns: '=',
            actions: '=',
            apicall: '=?',
            checktable: '=',
            hidePagination: '=',
            selectedRows: '=?',
            clickRowAction: '&?'
        }
    };
    return directiveDefinitionObject;
}
function directiveEwrTablePagination($compile) {
    /**
     * Returns table items.
     *
     * @param contentType
     * @returns {string}
     */
    var getTemplate = function (data, paginate) {
        var template = '<ul class="pagination">';

        var limit = data.limit;
        var pageNumber = parseInt(data.offset/limit);
        var totalPages = Math.ceil(data.total/limit);

        if (pageNumber > 0) {
            var prev = parseInt((pageNumber - 1)*limit);
            template += '<li><a ng-click="pagin(' + prev + ')" aria-label="Wstecz" href>Wstecz</a>' +
                '</li>';
        }

        var pageRange = totalPages < 5 ? totalPages : 5;
        var stop = (pageNumber <= totalPages - Math.ceil(pageRange/2)) ? pageNumber + Math.floor(pageRange/2) : totalPages;
        var start = (pageNumber <= 4) ? 1 :(pageNumber >= stop - 4 ? stop - pageRange + 1 : pageNumber - Math.floor(pageRange/2));


        if (totalPages != 1 && totalPages != 0) {
            for (var i = 1, start; i <= pageRange; i++, start++) {
                var digitalPageNumber = start - 1;
                var classActive = 'ng-class="{active: {0}==={1}}"'.format(digitalPageNumber, pageNumber);
                var clickAction = (digitalPageNumber === pageNumber) ? '' : 'ng-click="pagin({0})"'.format(parseInt(limit*digitalPageNumber));
                template += '<li {0}><a {1} href>{2}</a></li>'.format(classActive, clickAction, start);
            }
        }

        if (pageNumber < (totalPages - 1)) {
            var next = parseInt((pageNumber + 1)*limit);
            template += '<li><a ng-click="pagin(' + next + ')" aria-label="Dalej" href>Dalej</a>' +
                '</li>';
        }
        template += '</ul> </div>';
        return template;
    };
    /**
     * Linker function
     * @param scope
     * @param element
     * @param attrs
     */
    var linker = function (scope, element, attrs) {
        scope.$watch('data', function (value) {
            if (value !== undefined) {
                var expressionHandler = scope.paginate();
                scope.pagin = function (offset) {
                    expressionHandler(offset);
                    return false;
                };
                element.html(getTemplate(scope.data)).show();
                $compile(element.contents())(scope);
            }
        });
    };

    var directiveDefinitionObject = {
        restrict: 'E',
        replace: true,
        link: linker,
        scope: {
            data: '=',
            paginate: '&'
        }
    };
    return directiveDefinitionObject;
}
}());
