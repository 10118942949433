;(function() {
"use strict";

/**
 * HTTP services.
 * Responsible for handling connection with the backend services.
 */
angular
    .module('ewr.common')
    .service('http', httpService);

httpService.$inject = ['$q', '$http', '$window', 'constants', 'API_CONFIG', 'authenticationService', 'notifyService'];

function httpService($q, $http, $window, constants, apiConfig, authenticationService, notifyService) {
    return {
        request: request,
        get: get,
        put: put,
        post: post,
        patch: patch,
        delete: _delete
    };

    /**
     * Base request method. Handles the authorization token.
     *
     * @param method
     * @param url
     * @param data
     * @param headers
     * @returns {*}
     */
    function request(method, url, data, headers, contentType) {
        var deferred = $q.defer();
        var token = authenticationService.getToken();
        if (!headers) {
            headers = [];
        }

        if(token !== null) {
            headers['X-TOKEN'] = token;
        }

        if(!contentType) {
            headers['Content-Type'] = constants.HTTP_CONTENT_TYPE_APPLICATION_JSON;
        } else {
            headers['Content-Type'] = constants.HTTP_CONTENT_TYPE_X_WWW_FORM_URLENCODED;
            data = $.param(data)
        }
         $http({
            method: method,
            url: addVersionUrl(url),
            headers: headers,
            data: data
        }).then(function(response) {
             deferred.resolve(response);
         }, function (response) {
             if(response && ('status' in response) && parseInt(response.status) == 401) {
                 if(authenticationService.isAuthenticated()) {
                     authenticationService.deauthenticate();
                     $window.location.href = "/";
                 }
             }
             if (response && ('data' in  response) && response.data && ('code' in response.data) ) {
                if (response.data.code !== null && response.data.code !== undefined && response.data.code != '') {
                    notifyService.error(response.data.code);
                }
             }
             deferred.reject(response);
         });

        return deferred.promise;
    }

    /**
     * Creates a GET request.
     * @param url
     * @param headers
     * @returns {*}
     */
    function get(url, headers) {
        return this.request(constants.HTTP_METHOD_GET, url, null, headers);
    }

    /**
     * Creates a PUT request.
     * @param url
     * @param data
     * @returns {*}
     */
    function put(url, data) {
        return this.request(constants.HTTP_METHOD_PUT, url, data);
    }

    /**
     * Creates a POST request.
     * @param url
     * @param data
     * @returns {*}
     */
    function post(url, data, contentType) {
        return this.request(constants.HTTP_METHOD_POST, url, data, null, contentType);
    }

    /**
     * Creates a DELETE request.
     * @param url
     * @returns {*}
     */
    function _delete(url) {
        return this.request(constants.HTTP_METHOD_DELETE, url);
    }

    /**
     * Creates a PATCH request.
     * @param url
     * @param data
     * @returns {*}
     */
    function patch(url, data) {
        return this.request(constants.HTTP_METHOD_PATCH, url, data);
    }

    function addVersionUrl(url) {
        if(url.indexOf("?") > -1) {
            url += "&" + apiConfig().URL_APP_VERSION;
        } else {
            url += "?" + apiConfig().URL_APP_VERSION;
        }
        return url;
    }
}
}());
