;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('cdnFile', cdnFile);

cdnFile.$inject = []

function cdnFile() {
    var directiveDefinitionObject = {
        restrict: 'E',
        scope: {
            name: '@',
            width: '=?',
            height: '=?',
            cssClass: '@'
        },
        templateUrl: function () {
            return 'app/common/directives/cdn/templates/cdn-file.html?v=' + (new Date()).getTime();
        },
        controller: cdnFileController
    };

    return directiveDefinitionObject;
};

cdnFileController.$inject = ['$scope', 'API_CONFIG'];

function cdnFileController($scope, API_CONFIG) {
    var imageSrc = API_CONFIG().CDN_URL;
    if($scope.width && $scope.height) {
        imageSrc += "{0}x{1}_".format($scope.width, $scope.height);
    }
    $scope.imageSrc = "{0}{1}".format(imageSrc, $scope.name);
}
}());
