;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny - podgląd zadania
 *
 * @Controller OnlineScheduleCollectionTaskPreviewController
 * @module online
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineScheduleCollectionTaskPreviewController', OnlineScheduleCollectionTaskPreviewController);

    OnlineScheduleCollectionTaskPreviewController.$inject = ['$uibModalInstance', 'data'];
    function OnlineScheduleCollectionTaskPreviewController($uibModalInstance, data) {
        var vm = this;
        vm.task = data;

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
}());
