;(function() {
"use strict";

/**
 * Wideo.
 *
 * @Controller VideoController
 * @module resources
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('VideoController', VideoController);

    VideoController.$inject = ['CONFIG_TABLE', 'resourceService'];
    function VideoController(CONFIG_TABLE, resourceService) {
        var vm = this;
        vm.items = new Array();
        vm.paginate = paginate;
        vm.loadData = loadData;

        vm.loadData(urlParams);
        var urlParams = {
            offset: 0,
            limit: CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE,
        };

        function paginate(offset) {
            urlParams.offset = offset;
            urlParams.limit = CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE;
            vm.loadData(urlParams);
            return;
        };

        function loadData(urlParams) {
            resourceService.getVideos(urlParams).then(
                function (response) {
                    vm.items = response.data.items;
                    vm.meta = response.data.meta;
                },
                function (response, status) {
                }
            );

        }
    }
})();
}());
