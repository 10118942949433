;(function() {
"use strict";

/**
 * Serwis odpowiedzialny za kontrole dostepow
 *
 * @service aclService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('aclService', aclService);

aclService.$inject = [];

/**
 *
 * @returns {{setRoles: setRoles, setPermissions: setPermissions, getPermissions: getPermissions, getRoles: getRoles, hasRole: hasRole, hasPermission: hasPermission}}
 */
function aclService() {
    var roles = [];
    var permissions = [];
    var inited = false;

    return {
        setRoles: setRoles,
        setPermissions: setPermissions,
        getPermissions: getPermissions,
        getRoles: getRoles,
        hasRole: hasRole,
        hasPermission: hasPermission,
        hasOneOfRoles: hasOneOfRoles,
        hasOneOfPermissions: hasOneOfPermissions,
        initialized: initialized
    };

    /**
     *
     * @param permissions
     */
    function setPermissions(items) {
        permissions = items;
        inited = true;
    }

    /**
     *
     * @param roles
     */
    function setRoles(items) {
        roles = items;
        inited = true;
    }

    /**
     *
     * @returns {*}
     */
    function getPermissions() {
        return permissions;
    }

    /**
     *
     * @returns {*}
     */
    function getRoles() {
        return roles;
    }

    /**
     *
     * @param role
     * @returns {boolean}
     */
    function hasRole(role) {
        return roles.indexOf(role.trim()) > -1 ? true : false;
    }

    /**
     *
     * @param items
     * @returns {boolean}
     */
    function hasOneOfRoles(items) {
        var has = false;
        angular.forEach(items, function (role) {
            if(hasRole(role)) {
                has = true;
            }
        });
        return has;
    }

    /**
     *
     * @param permission
     * @returns {boolean}
     */
    function hasPermission(permission) {
        if(permission) {
            return permissions.indexOf(permission.trim()) > -1 ? true : false;
        }
        return false;
    }

    /**
     *
     * @param items
     * @returns {*}
     */
    function hasOneOfPermissions(items) {
        var has = false;
        angular.forEach(items, function (permission) {
            if(hasPermission(permission)) {
                has = true;
            }
        });
        return has;
    }

    function initialized() {
        return inited;
    }
}
}());
