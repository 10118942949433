;(function() {
"use strict";

/**
 * Angular module responsible for web layout and homepage
 */

angular.module('ewr.layout', [])
    .config(config);

/**
 * Configures routing for layout (topmost) module
 * Sets topbar, navbar and content parts of the app
 *
 * NOTE: all states that are available for not authorized user should be
 * marked with auth: false
 * @param $stateProvider
 * @param $urlRouterProvider
 */

config.$inject = ['$stateProvider', '$urlRouterProvider'];

function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/home');
    $stateProvider
    .state('index', {
       abstract: true,
       views : {
           '@' : {
               templateUrl: 'app/layout/templates/layout-template.html',
               controller: 'MainController as vm'
           },
       }
    });
}
}());
