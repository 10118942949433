;(function() {
"use strict";

/**
 * Admin Header directive
 */
angular
    .module('ewr.admin')
    .directive('breadcrumbs', breadcrumbs);

function breadcrumbs() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/admin/directives/breadcrumbs/breadcrumbs.directive.html',
        scope: {
            title: '@',
            subTitle: '@'
        },
        controller: BreadcrumbsController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

function BreadcrumbsController() {
    var vm = this;

}
}());
