;(function() {
"use strict";

/**
 * Szablony.
 *
 * @Controller OrgTemplatesController
 * @module organizations
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.organizations')
        .controller('OrgTemplatesController', OrgTemplatesController);

    OrgTemplatesController.$inject = ['$state', '$sce', 'utilsService', 'PAGES', 'Notification', 'pageService'];
    function OrgTemplatesController($state, $sce, utilsService, PAGES, Notification, pageService) {
        var vm = this;

        pageService.getByGuid(PAGES.ORG_TEMPLATES).then(
            function (response) {
                vm.pageTitle = response.data.title;
                vm.pageContent = $sce.trustAsHtml(response.data.content);
            },
            function (response, status) {
            }
        );
    }

})();
}());
