;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('datepicker', datepicker);

datepicker.$inject = ['date'];

function datepicker(dateService) {
    return {
        require: 'ngModel',
        restrict: 'E',
        scope: {
            ngModel: '=',
            placeholder: '=',
            name: '='
        },
        templateUrl: 'app/common/templates/datepicker.html',
        controller: ['$scope', 'ewr.validators', function ($scope, validators) {
            $scope.datepickerOptions = {
                format: dateService.getFormatYYYYMMDD(),
                initDate: new Date(),
                datepickerPopup: dateService.getFormatYYYYMMDD()
            };

            $scope.allowDateFormat = validators.allowDateFormat;

            $scope.datepicker = {};
            $scope.datepicker.opened = false;
            $scope.datepicker.open = function () {
                $scope.datepicker.opened = !$scope.datepicker.opened;
            };

            $scope.$watch('model', function(value) {
                $scope.ngModel = dateService.convertToFormatYYYYMMDD(value);
                if($scope.ngModel === '01-01-1970')
                    $scope.ngModel = undefined;
            })
        }]
    };
}
}());
