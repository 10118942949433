;(function() {
"use strict";

/**
 * Admin - zarządzanie stronami.
 *
 * @Controller AdminPageController
 * @module admin
 * @project EWR
 */
angular.module('ewr.admin')
    .controller('AdminPageController', AdminPageController);

AdminPageController.$inject = ['pageService', 'tableDefinitions', 'utilsService'];
function AdminPageController(pageService, tableDefinitions, utilsService) {

    const vm = this;
    const formParams = {
        name: 'cms-page',
        title: "{0} stronę {1}",
        restService: pageService,
    };

    const pageTableDefinition = tableDefinitions.getTableDefinitionAdminPage(formParams);

    vm.listingColumnDefinitions = pageTableDefinition.listingColumnDefinitions;
    vm.tableActions = pageTableDefinition.tableActions;
    vm.pageService = pageService;

    vm.search = function () {
        vm.customSearch = pageTableDefinition.searchByFields(vm);
    };
}
}());
