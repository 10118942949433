;(function() {
"use strict";

/**
 * Serwis obsługujący raporty.
 *
 * @service reportService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('reportService', ReportService);

ReportService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function ReportService(http, utils, API_CONFIG) {

    var service = {
        generate: generate
    };

    return service;

    function generate(data) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_REPORT, 'generate');
        return http.post(url, data);
    }

}
}());
