;(function() {
"use strict";

/**
 * Standardy egzaminacyjne.
 *
 * @Controller StandardsController
 * @module resources
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('StandardsController', StandardsController);

    StandardsController.$inject = ['PAGES'];
    function StandardsController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }

})();
}());
