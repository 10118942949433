;(function() {
"use strict";

/**
 * Serwis obsługujący tabele.
 *
 * @service articleService
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('watchService', watchService);

watchService.$inject = [];

function watchService() {
    var reloadChange = false;
    var showChangePassForm = false;

    var service = {
        reload: reload,
        shouldReload: shouldReload,
        shouldShowChangePassForm: shouldShowChangePassForm,
        setShowChangePassForm: setShowChangePassForm
    };

    return service;

    function setShowChangePassForm(show) {
        showChangePassForm = show;
    }

    function shouldShowChangePassForm() {
        return showChangePassForm;
    }

    function reload() {
        reloadChange = !reloadChange;
    }

    function shouldReload() {
        return reloadChange;
    }
}
}());
