;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('pageService', PageService);

PageService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function PageService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem,
        getByGuid: getByGuid
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_CMS_PAGE, params);
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_CMS_PAGE, id);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_CMS_PAGE;
        if (item.id) {
            url = '{0}/{1}'.format(url, item.id);
            return http.put(url, item);
        }

        return http.post(url, item);
    }

    function deleteItem(id) {
        // TODO
    }

    function getByGuid(guid) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_PAGE, guid);
        return http.get(url);
    }

}
}());
