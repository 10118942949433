;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('documentService', documentService);

documentService.$inject = ['http', 'API_CONFIG', 'utilsService', 'constants'];

function documentService(http, API_CONFIG, utils, constants) {

    return {
        generateOnlineExamSummaryProtocol: generateOnlineExamSummaryProtocol,
        generateOnlineExamStudentProtocol: generateOnlineExamStudentProtocol,
    };

    function generateOnlineExamSummaryProtocol(scheduleExamId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_DOCUMENT, scheduleExamId, 'protocol', 'summary');
        return http.get(url);
    }

    function generateOnlineExamStudentProtocol(scheduleExamId, studentId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_DOCUMENT, scheduleExamId, 'protocol', studentId);
        return http.get(url);
    }
}
}());
