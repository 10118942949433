;(function() {
"use strict";

/**
 * Zestaw testowy
 *
 * @Controller TestController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('TestController', TestController);

    TestController.$inject = ['testService', 'tableDefinitions', 'formFactory', 'constants', 'watchService', 'notifyService'];
    function TestController(testService, tableDefinitions, formFactory, constants, watchService, notifyService) {
        const vm = this;
        const formParams = {
            name: 'test-generate',
            title: "{0} zestaw testowy {1}",
            restService: testService,
        };

        vm.dictionary = {};
        vm.filterParams = {};

        testService.getFilter().then(
            function (response) {
                const dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    if (dictionary.name === 'examMode') {
                        vm.dictionary.examModes = dictionary.items;
                    }
                    if (dictionary.name === 'examType') {
                        vm.dictionary.examTypes = dictionary.items;
                    }
                    if (dictionary.name === 'collectionStatus') {
                        vm.dictionary.statuses = dictionary.items;
                    }
                    if (dictionary.name === 'profession') {
                        vm.dictionary.professions = dictionary.items;
                    }
                });
            },
            function (response, status) {
                // TODO
            }
        );

        const testTableDefinition = tableDefinitions.getTableDefinitionMgmtTestCollection(formParams);

        vm.listingColumnDefinitions = testTableDefinition.listingColumnDefinitions;
        vm.tableActions = testTableDefinition.tableActions;
        vm.testService = testService;

        vm.search = function () {
            vm.customSearch = testTableDefinition.searchByFields(vm);
        };

        vm.generate = function() {

            const formParams = {
                name: 'test-generate',
                title: 'Generuj testowy zestaw',
                submitButtonLabel: 'GENERUJ',
                restService: testService,
                dictName: constants.FORM_DICT_TEST,
                add: true,
                customSubmitAction: function(params) {
                    return testService.generate(params.model).then(
                        function() {
                            params.uibModalInstance.dismiss('cancel');
                            notifyService.success('TEST.GENERATE_SUCCESS');
                            watchService.reload();
                        }
                    );
                }
            };

            formFactory.open(formParams);
        }

    }
})();
}());
