;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('formFieldSelectFile', formFieldSelectFile);

formFieldSelectFile.$inject = []

function formFieldSelectFile() {
    var directiveDefinitionObject = {
        restrict: 'E',
        scope: {
            files: '=',
            selectedFile: '=',
            use: '=?',
            required: '=',
            imagesOnly: '=',
            filterByType: '=?'
        },
        templateUrl: function () {
            return 'app/common/directives/ui/components/forms/fields/templates/select-file.html?v=' + (new Date()).getTime();
        },
        controller: formFielsSelectFileController
    };

    return directiveDefinitionObject;
};

formFielsSelectFileController.$inject = ['$timeout', '$uibModalStack', '$scope' ,'formObjectObserverService'];

function formFielsSelectFileController($timeout, $uibModalStack, $scope, formObjectObserverService) {
    $scope.selectFile = selectFile;
    $scope.hideImagesOnlyFilter = hideImagesOnlyFilter;
    $scope.filter = filter;
    $scope.dismissModal = dismissModal;
    $scope.selectedFileId = 0;
    $scope.invalid = false;
    $scope.disableImagesOnly = angular.copy($scope.imagesOnly);
    $timeout(function () {
       $scope.$apply(function() {
           $scope.mutableFiles = angular.copy($scope.files);
       }) ;
    });

    if($scope.filterByType) {
        $scope.$watch('filterByType', function (fileType) {
            filterByType(fileType);
        });
    }

    function selectFile(item) {
        var selectedItem = dictValueMapToModel(item);
        $scope.selectedFile = selectedItem;

        $scope.selectedFileId = selectedItem.id;

        if($scope.use && $scope.use === "idFile") {
            $scope.selectedFile = $scope.selectedFileId;
        }
    }

    function dictValueMapToModel(item) {
        return {
            id: item.value,
            url: item.code,
            name: item.name,
            type: item.extra
        }
    }

    function filterByType(type) {
        $scope.mutableFiles = angular.copy($scope.files);
        var files = new Array();
        angular.forEach($scope.mutableFiles, function(file) {
            if(file.extra === type) {
                files.push(file);
            }
        });
        $timeout(function () {
            $scope.$apply(function () {
                $scope.mutableFiles = files;
            })

        });
    }

    function hideImagesOnlyFilter() {
        return !$scope.filterByType;
    }

    function filter() {
        $scope.mutableFiles = ($scope.filterByType) ? $scope.mutableFiles : angular.copy($scope.files);
        var searchItems = new Array();
        if($scope.search) {
            var search = $scope.search.toLowerCase();
            angular.forEach($scope.mutableFiles, function (item) {
                var itemName = item.name.toLowerCase();
                if(itemName.indexOf(search) > -1) {
                    searchItems.push(item);
                }
            });
        } else {
            searchItems = ($scope.filterByType) ? $scope.mutableFiles : angular.copy($scope.files);
        }

        var items = new Array();
        if($scope.imagesOnly) {
            angular.forEach(searchItems, function (item) {
                if(item.extra == 'PHOTO') {
                    items.push(item);
                }
            });
        } else {
            items = searchItems;
        }
        $timeout(function () {
            $scope.$apply(function () {
                $scope.mutableFiles = items;
            });
        });

    }

    $scope.$watch('imagesOnly', function () {
       filter();
    });

    $scope.$watch(function () {
        return formObjectObserverService.getFormObject();
    }, function (model) {
        $scope.selectedFile = angular.copy(model);
        if('idFile' in $scope.selectedFile) {
            $scope.selectedFileId = $scope.selectedFile.idFile;
        } else {
            $scope.selectedFileId = $scope.selectedFile.id;
        }

        if($scope.use && $scope.use === "idFile") {
            $scope.selectedFile = $scope.selectedFileId;
        }
    });

    function dismissModal() {
        $uibModalStack.dismissAll();
    }

    $scope.$watch('selectedFile', function() {
        validate();
    });

    $scope.$watch('selectedFileId', function() {
        validate();
    });

    function validate() {
        if($scope.required && (!$scope.selectedFile || !$scope.selectedFileId)) {
            $scope.invalid = true;
            $scope.$parent.form.$setValidity($scope.$parent.id, false);
        } else {
            $scope.invalid = false;
            $scope.$parent.form.$setValidity($scope.$parent.id, true);
        }
    }
}
}());
