;(function() {
"use strict";

/**
 * Treść artykułu.
 *
 * @Controller OrgNewsDetailsController
 * @module organization
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.organizations')
        .controller('OrgNewsDetailsController', OrgNewsDetailsController);


    OrgNewsDetailsController.$inject = ['$sce', '$stateParams', 'articleService'];
    function OrgNewsDetailsController($sce, $stateParams, articleService) {
        var vm = this;

        articleService.getOrganizationNewsById($stateParams.id).then(
            function (response) {
                vm.articleTitle = response.data.header;
                vm.articleContent = $sce.trustAsHtml(response.data.content); 
                vm.articleDate = response.data.publishDate;
            },
            function (response, status) {
                // TODO
            }
        );

    }
})();
}());
