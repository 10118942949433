;(function() {
"use strict";

angular
    .module('ewr.common')
    .filter('navigation', navigation);

navigation.$inject = ['$location'];

function navigation($location) {
    return function(input) {
      if (! input) {
        return;
      }
      return $location.path() === input ? 'active' : '';
    };
}
}());
