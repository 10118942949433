;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('pagination', pagination);

pagination.$inject = ['$state', 'pagerService'];

function pagination($state, pagerService) {

    var linker = function (scope, element, attrs) {

        scope.$watch('meta', function(value) {

            if (value) {
                var currentPage = pagerService.getCurrentPage();
                var data = pagerService.getPager(value.total, currentPage, value.limit);
                scope.pager = data;
                scope.pagin = function (pageNumber) {
                    var params = {
                        page: pageNumber
                    }
                    angular.extend(params, scope.extraParams);

                    $state.go(scope.stateHref, params);
                };
            }

        });
    };

    return {
        restrict: 'E',
        scope: {
            meta: '=',
            stateHref: '@',
            extraParams: '=?'
        },
        templateUrl: 'app/common/templates/pagination.html',
        link: linker,
    };
}
}());
