;(function() {
"use strict";

/**
 * Strona główna
 *
 * @Controller HomeController
 * @module home
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.home')
        .controller('HomeController', HomeController);
    
    HomeController.$inject = ['PAGES'];
    function HomeController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }
})();
}());
