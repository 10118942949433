;(function() {
"use strict";

angular
    .module('ewr.online')
    .directive('scheduleInfo', scheduleInfo);

function scheduleInfo() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/online/directives/scheduleinfo/scheduleinfo.directive.html',
        scope: {},
        controller: ScheduleInfoController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

ScheduleInfoController.$inject = ['$scope', '$stateParams', 'notifyService', 'onlineExamService', 'onlineScheduleService', 'documentService', 'fileUtils'];

function ScheduleInfoController($scope, $stateParams, notifyService, onlineExamService, onlineScheduleService, documentService, fileUtils) {
    var vm = this;

    vm.collectionId = $stateParams.id;
    vm.collection = {};

    onlineScheduleService.get(vm.collectionId).then(
        function (response) {
            vm.collection = response.data;
        },
        function (response, status) {
        }
    );

    vm.canRun = function() {
        if (vm.collection && vm.collection.status === 'APPROVED') {
            return true;
        }

        return false;
    }

    vm.canFinish = function() {
        if (vm.collection && vm.collection.status === 'IN_PROGRESS') {
            return true;
        }

        return false;
    }

    vm.canGenerateSummaryProtocol = function() {
        if (vm.collection && vm.collection.status === 'FINISHED') {
            return true;
        }

        return false;
    }

    vm.runExam = function() {
        onlineExamService.runExam(vm.collectionId).then(
            function (response) {
                vm.collection.status = 'IN_PROGRESS';
                $scope.$emit('examRun');
                notifyService.success("Rozpoczęto egzamin");
            },
            function (response, status) {
                notifyService.error("Wystąpił problem, skontaktuj się z administratorem serwisu ...");
            }
        );
    }

    vm.finishExam = function() {
        onlineExamService.finishExam(vm.collectionId).then(
            function (response) {
                vm.collection.status = 'FINISHED';
                $scope.$emit('examFinish');
                notifyService.success("Egzamin został zakończony");
            },
            function (response, status) {
                notifyService.error("Wystąpił problem, skontaktuj się z administratorem serwisu ...");
            }
        );
    }

    vm.generateOnlineExamSummaryProtocol = function() {
        documentService.generateOnlineExamSummaryProtocol(vm.collectionId).then(function (response) {
            fileUtils.downloadFile(response.data);
            },
            function() {
                notifyService.error("Wystąpił błąd podczas generowania protokołu zbiorczego.");
            }
        )
    };

}
}());
