;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('professionService', ProfessionService);

ProfessionService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function ProfessionService(http, utils, API_CONFIG) {
    var service = {
        getAllList: getAllList,
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem
    };

    return service;

    function getAllList() {
        const url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_PROFESSION, 'list');
        return http.get(url);
    }

    function getList(params) {
        const url = utils.updateUrl(API_CONFIG().ENDPOINT_PROFESSION, params);
        return http.get(url);
    }

    function get(id) {
        // TODO
    }

    function save(item) {
        // TODO
    }

    function deleteItem(id) {
        // TODO
    }

}
}());
