;(function() {
"use strict";

/**
 *
 * Module aggregating common functions.
 */
(function() {
    angular
        .module('ewr.common', [])

        .constant('API_CONFIG', function ($cookies) {
            var CONFIG_LOCATION;
            var APPLICATION_VERSION;
            var CDN_URL;
            var BASE_URL;

            CONFIG_LOCATION = '/assets/config/config.json';

            APPLICATION_VERSION = '2.2.1';

            CDN_URL = 'https://ewr.zrp.pl/cdn/';

            BASE_URL = 'https://ewr.zrp.pl/api';

            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 1000000000*100)
            createCookie('BASE_URL', BASE_URL, expireDate)

            return {
                'URL_APP_VERSION': 'v=' + APPLICATION_VERSION,
                'API_BASE': BASE_URL,
                'CDN_URL': CDN_URL,
                'CONFIG_LOCATION': CONFIG_LOCATION,
                'ENDPOINT_KEEPALIVE_SESSION': '{0}/{1}'.format(BASE_URL, '/ping'),

                // menu egzaminy
                'ENDPOINT_MANAGEMENT_EXAM': '{0}/{1}'.format(BASE_URL, 'mgmt-exam'),
                'ENDPOINT_MANAGEMENT_TEST': '{0}/{1}'.format(BASE_URL, 'mgmt-test'),
                'ENDPOINT_MANAGEMENT_TASK': '{0}/{1}'.format(BASE_URL, 'mgmt-task'),

                // menu admin
                'ENDPOINT_CMS_ARTICLE': '{0}/{1}'.format(BASE_URL, 'cms-article'),
                'ENDPOINT_CMS_PAGE': '{0}/{1}'.format(BASE_URL, 'cms-page'),
                'ENDPOINT_CMS_CAROUSEL': '{0}/{1}'.format(BASE_URL, 'cms-carousel'),
                'ENDPOINT_MANAGEMENT_MESSAGE': '{0}/{1}'.format(BASE_URL, 'mgmt-message'),
                'ENDPOINT_MANAGEMENT_REPORT': '{0}/{1}'.format(BASE_URL, 'mgmt-report'),
                'ENDPOINT_MANAGEMENT_ROLE': '{0}/{1}'.format(BASE_URL, 'mgmt-role'),
                'ENDPOINT_MANAGEMENT_USER': '{0}/{1}'.format(BASE_URL, 'mgmt-user'),
                'ENDPOINT_MANAGEMENT_PROFESSION': '{0}/{1}'.format(BASE_URL, 'mgmt-profession'),
                'ENDPOINT_MANAGEMENT_PROFILE': '{0}/{1}'.format(BASE_URL, 'profile'),
                'ENDPOINT_MANAGEMENT_CHANGE_PASSWORD': '{0}/{1}'.format(BASE_URL, 'password'),
                'ENDPOINT_MANAGEMENT_FILE': '{0}/{1}'.format(BASE_URL, 'file'),
                'ENDPOINT_MANAGEMENT_RESOURCE': '{0}/{1}'.format(BASE_URL, 'resource-admin'),
                'ENDPOINT_MANAGEMENT_UPLOAD': '{0}/{1}'.format(BASE_URL, 'upload'),
                'ENDPOINT_MANAGEMENT_UPLOAD_IMPORT': '{0}/{1}'.format(BASE_URL, 'upload/task-import'),

                // menu izba
                'ENDPOINT_ORG_MESSAGE': '{0}/{1}'.format(BASE_URL, 'org-message'),
                'ENDPOINT_ORG_NEWS': '{0}/{1}'.format(BASE_URL, 'org-news'),
                'ENDPOINT_ORG_COMMISSION': '{0}/{1}'.format(BASE_URL, 'org-commission'),

                // other
                'ENDPOINT_NEWS': '{0}/{1}'.format(BASE_URL, 'news'),
                'ENDPOINT_PAGE': '{0}/{1}'.format(BASE_URL, 'page'),
                'ENDPOINT_PROFESSION': '{0}/{1}'.format(BASE_URL, 'profession'),
                'ENDPOINT_COMMISSION': '{0}/{1}'.format(BASE_URL, 'commission'),
                'ENDPOINT_TEST': '{0}/{1}'.format(BASE_URL, 'test'),
                'ENDPOINT_RESOURCE': '{0}/{1}'.format(BASE_URL, 'resource'),

                // dictionary
                'ENDPOINT_FORM_ARTICLE_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'cms-article', 'dictionary'),
                'ENDPOINT_FORM_ROLE_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-role', 'dictionary'),
                'ENDPOINT_FORM_USER_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-user', 'dictionary'),
                'ENDPOINT_FORM_EXAM_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-exam', 'dictionary'),
                'ENDPOINT_FORM_EXAM_GENERATE_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-exam', 'dictionary-generate'),
                'ENDPOINT_FORM_TEST_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-test', 'dictionary'),
                'ENDPOINT_FORM_TASK_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-task', 'dictionary'),
                'ENDPOINT_FORM_REPORT_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'mgmt-report', 'dictionary'),
                'ENDPOINT_FORM_COMMISSION_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'org-commission', 'dictionary'),
                'ENDPOINT_FORM_RESOURCE_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'resource-admin', 'dictionary'),


                // auth/init
                'ENDPOINT_AUTH': '{0}/{1}'.format(BASE_URL, 'auth'),
                'ENDPOINT_INIT': '{0}/{1}'.format(BASE_URL, 'init'),
                'ENDPOINT_LOGIN': '{0}/{1}'.format(BASE_URL, 'login'),

                // online exams
                'ENDPOINT_ONLINE': '{0}/{1}'.format(BASE_URL, 'student'),
                'ENDPOINT_ONLINE_STUDENT': '{0}/{1}'.format(BASE_URL, 'student'),
                'ENDPOINT_ONLINE_STUDENT_LIST': '{0}/{1}'.format(BASE_URL, 'student', 'list'),
                'ENDPOINT_ONLINE_SCHEDULE': '{0}/{1}'.format(BASE_URL, 'exam-schedule'),
                'ENDPOINT_ONLINE_SCHEDULE_LIST': '{0}/{1}'.format(BASE_URL, 'exam-schedule', 'list'),
                'ENDPOINT_ONLINE_SCHEDULE_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'exam-schedule', 'dictionary'),
                'ENDPOINT_FORM_STUDENT_DICTIONARY': '{0}/{1}/{2}'.format(BASE_URL, 'student', 'dictionary'),
                'ENDPOINT_ONLINE_EXAM': '{0}/{1}'.format(BASE_URL, 'mgmt-exam-online'),
                'ENDPOINT_ONLINE_DOCUMENT': '{0}/{1}'.format(BASE_URL, 'mgmt-document-online'),

                'ENDPOINT_FORM_DEFINITIONS': 'assets/config/definitions/forms/{0}.form.json',
                'ENDPOINT_TABLE_DEFINITIONS': 'assets/config/definitions/tables/{0}.table.json'
            }
        })
        .constant('constants', {
            'HTTP_METHOD_GET': 'GET',
            'HTTP_METHOD_POST': 'POST',
            'HTTP_METHOD_PUT': 'PUT',
            'HTTP_METHOD_PATCH': 'PATCH',
            'HTTP_METHOD_DELETE': 'DELETE',
            'HTTP_CONTENT_TYPE_APPLICATION_JSON': 'application/json',
            'HTTP_CONTENT_TYPE_X_WWW_FORM_URLENCODED': 'application/x-www-form-urlencoded',
            'CDN_URL_KEY': 'CDN_URL',
            'COOKIE_POLICY_DECISION_KEY': 'COOKIE_POLICY_DECISION_KEY',
            'COOKIE_POLICY_DECISION_VALUE': 'COOKIE_POLICY_DECISION_VALUE',
            'COOKIE_SESSION_KEY': 'X-SESSION',
            'COOKIE_USER_DATA_KEY': 'USER_LOGIN_DATA',
            'COOKIE_SESSION_EXPIRED_MINUTES': 100,
            'COOKIE_SELECTED_SCHEMA_ID': 'COOKIE_SELECTED_SCHEMA_ID',
            'COOKIE_CKEDITOR_USE_PRIVATE_FILES': 'CKEDITOR_USE_PRIVATE_FILES',
            'SESSION_IDLE_TIME': 7200, // time after which system detects user idle state
            'SESSION_TIMES_TO_PING': 10, // how many times should we ping server during token expiration time (returned from server x-inactive-interval)
            'SESSION_USER_ACTION_TIMEOUT': 30, // time user has to extend session
            'EDITOR_DEFAULT_TOOLBAR': 'about,a11yhelp,basicstyles,bidi,blockquote,clipboard,colorbutton,colordialog,contextmenu,dialogadvtab,div,elementspath,enterkey,entities,filebrowser,find,flash,floatingspace,font,format,forms,horizontalrule,htmlwriter,image,iframe,indentlist,indentblock,justify,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastefromword,pastetext,preview,print,removeformat,resize,save,selectall,showblocks,showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,tabletools,templates,toolbar,undo,wysiwygarea',
            'FORM_DICT_ARTICLE': 'article',
            'FORM_DICT_ROLE': 'role',
            'FORM_DICT_USER': 'user',
            'FORM_DICT_RESOURCE': 'resource',
            'FORM_DICT_ONLINE': 'online',
            'FORM_DICT_REPORT': 'report',
            'FORM_DICT_EXAM': 'exam',
            'FORM_DICT_EXAM_GENERATE': 'examGenerate',
            'FORM_DICT_TEST': 'test',
            'FORM_DICT_TASK': 'task',
            'FORM_DICT_COMMISSION': 'commission',
            'FORM_DICT_SCHEDULE': 'schedule',
            'TABLE_DATE_FORMAT': 'yyyy-MM-dd',
            'MODAL_SIZE_SMALL': 'sm',
            'MODAL_SIZE_LARGE': 'lg',
            'MODAL_SIZE_LOGIN': 'login',
            'PAGINATE_DEFAULT_PAGE_SIZE': 5
        })
        .constant('labels', {
            'CONFIRMATION_DIALOG_TITLE': "Potwierdzenie usunięcia",
            'CONFIRMATION_DIALOG_REMOVE_FROM_EXAM_TITLE': "Potwierdzenie usunięcia uczestnika z egzaminu",
            'CONFIRMATION_DIALOG_CONFIRMATION': "Czy na pewno chcesz usunąć wskazany obiekt ?",
            'CONFIRMATION_DIALOG_CHANGE_STATUS_TITLE': "Potwierdzenie zmiany statusu",
            'CONFIRMATION_DIALOG_CHANGE_STATUS_CONFIRMATION': "Czy na pewno chcesz zmienić status wskazanego rekordu ?",
            'CONFIRMATION_DIALOG_REMOVE_FROM_EXAM_CONFIRMATION': "Czy na pewno chcesz usunąć uczestnika z egzaminu ?"
        })
        .constant('PAGES', {
            'COOKIE_POLICY': 'polityka-cookie',

            'INFO_PROJECT_INFO': 'informacje-o-projekcie',
            'INFO_CURRENT_INFO': 'biezace-informacje',
            'INFO_LAWS_AND_RESOLUTIONS': 'akty-prawne-i-uchwaly',
            'INFO_NEWS_REPORTING': 'relacje-z-dzialalnosci-egzaminacyjnych',
            'INFO_COMMISSIONS': 'wykaz-komisji-egzaminacyjnych',
            'INFO_EXAMS_INFO': 'informacje-o-egzaminach',
            'INFO_PROFESSIONS': 'lista-zawodow',
            'INFO_KRK': 'krajowe-ramy-kwalifikacji',

            'RESOURCES_PHOTOS': 'fotografie',
            'RESOURCES_PRESENTATIONS': 'prezentacje',
            'RESOURCES_STANDARDS': 'standardy-egzaminacyjne',
            'RESOURCES_EXAM_SAMPLES': 'przykladowe-zadania-egzaminacyjne',
            'RESOURCES_AIDS': 'materialy-pomocnicze',

            'ORG_NEWS': 'aktualnosci',
            'ORG_MESSAGES': 'korespondencja',
            'ORG_PROFESSIONS': 'zarzadzanie-lista-zawodow',
            'ORG_COMMISSIONS': 'komisje-egzaminacyjne',
            'ORG_TEMPLATES': 'szablony',

            'EXAM_INFO': 'o-egzaminach',
            'EXAM_INFO_INTRODUCTION': 'o-egzaminach-wprowadzenie',
        })
        .constant('CONFIG_TABLE_TYPES', {
            'TEXT': 'text',
            'NUMBER': 'NUMBER',
            'DATE': 'date'
        })
        .constant('CONFIG_TABLE', {
            'URL_PAGINATE_DEFAULT_SIZE':10
        })
        .config(config);


    config.$inject = ['ngDialogProvider'];
    function config(ngDialogProvider) {
        ngDialogProvider.setDefaults({
            className: 'ngdialog-theme-default',
            plain: false,
            showClose: false,
            closeByDocument: true,
            closeByEscape: true
        });
    }

    function createCookie(name,value,expiration) {
        var expires = "; expires=" + expiration;
        document.cookie = name + "=" + value + expires + "; path=/";
    }
})()
}());
