;(function() {
"use strict";

/**
 * Egzamin
 *
 * @Controller ScheduleExamController
 * @module online
 * @project EWR
 */

angular
    .module('ewr.online')
    .controller('ScheduleExamController', ScheduleExamController);

ScheduleExamController.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'onlineExamService', 'tableDefinitions', 'formFactory', 'constants', 'watchService', 'notifyService', 'documentService', 'fileUtils'];
function ScheduleExamController($scope, $state, $stateParams, $uibModal, onlineExamService, tableDefinitions, formFactory, constants, watchService, notifyService, documentService, fileUtils) {
    const vm = this;

    vm.collectionId = $stateParams.id;
    vm.students = [];

    load();

    $scope.$on('examRun', function (event, data) {
        load();
    });

    $scope.$on('examFinish', function (event, data) {
        load();
    });

    function load() {
        onlineExamService.getExamStats(vm.collectionId).then(
            function (response) {
                vm.students = response.data;
            },
            function (response, status) {
            }
        );
    }

    vm.refresh = function() {
        load();
    }

    vm.canPause = function(item) {
        if (item.status === 'IN_PROGRESS') {
            return true;
        }

        return false;
    }

    vm.canResume = function(item) {
        if (item.status === 'PAUSED') {
            return true;
        }

        return false;
    }

    vm.canBlock = function(item) {
        if (item.status === 'NEW' || item.status === 'IN_PROGRESS' || item.status === 'PAUSED') {
            return true;
        }

        return false;
    }

    vm.canShowScore = function(item) {
        if (item.status === 'FINISHED') {
            return true;
        }

        return false;
    }

    vm.canShowPreview = function(item) {
        if (item.status === 'FINISHED') {
            return true;
        }

        return false;
    }

    vm.showScore = function(item) {
        var modalInstance = $uibModal.open({
            templateUrl: 'app/online/templates/schedule/exam-score.html',
            controller: 'ExamScoreController',
            controllerAs: 'vm',
            animation: true,
            size: 'lg',
            scope: $scope,
            resolve: {
                data: function () {
                    return item;
                }
            }
        });
    }

    vm.showPreview = function(item) {
        $state.go('onlineExamPreview', {examId: item.examId, studentId: item.studentId});
    }

    vm.pauseStudentExam = function(item) {
        onlineExamService.pauseStudentExam(item.examId, item.studentId).then(
            function (response) {
                item.status = 'PAUSED';
            },
            function (response, status) {
                notifyService.error("Wystąpił problem podczas wstrzymania egzaminu, skontaktuj się z administratorem serwisu ...");
            }
        );
    }

    vm.resumeStudentExam = function(item) {
        onlineExamService.resumeStudentExam(item.examId, item.studentId).then(
            function (response) {
                item.status = 'IN_PROGRESS';
            },
            function (response, status) {
                notifyService.error("Wystąpił problem podczas wznowienia egzaminu, skontaktuj się z administratorem serwisu ...");
            }
        );
    }

    vm.blockStudentExam = function(item) {
        onlineExamService.blockStudentExam(item.examId, item.studentId).then(
            function (response) {
                item.status = 'BLOCKED';
            },
            function (response, status) {
                notifyService.error("Wystąpił problem podczas blokowania egzaminu, skontaktuj się z administratorem serwisu ...");
            }
        );
    }

    vm.canGenerateProtocol = function (studentExam) {
        return studentExam.status === 'FINISHED';
    }

    vm.generateProtocol = function(studentExam) {
        console.log(studentExam);
        documentService.generateOnlineExamStudentProtocol(studentExam.examId, studentExam.studentId).then(function (response) {
                fileUtils.downloadFile(response.data);
            },
            function() {
                notifyService.error("Wystąpił błąd podczas generowania protokołu zbiorczego.");
            }
        )
    };

}
}());
