;(function() {
"use strict";

/**
 * Admin - zarządzanie zasobami.
 *
 * @Controller ResourceController
 * @module admin
 * @project EWR
 */

angular.module('ewr.admin')
    .controller('ResourceController', ResourceController);

ResourceController.$inject = ['resourceService', 'tableDefinitions', 'formFactory', 'constants'];

function ResourceController(resourceService, tableDefinitions, formFactory, constants) {
    const vm = this;
    const formParams = {
        name: 'resource',
        title: "{0} zasobu {1}",
        restService: resourceService,
        dictName: constants.FORM_DICT_RESOURCE
    };

    vm.dictionary = {};
    vm.filterParams = {};

    resourceService.getFilter().then(
        function (response) {
            const dictionaries = response.data;
            angular.forEach(dictionaries, function (dictionary) {
                if (dictionary.name === 'fileType') {
                    vm.dictionary.fileTypes = dictionary.items;
                }
            });
        },
        function (response, status) {
            // TODO
        }
    );

    const resourceTableDefinition = tableDefinitions.getTableDefinitionAdminResource(formParams);

    vm.listingColumnDefinitions = resourceTableDefinition.listingColumnDefinitions;
    vm.tableActions = resourceTableDefinition.tableActions;
    vm.resourceService = resourceService;

    vm.addResource = function () {
        delete formParams['objId'];
        formParams['title'] = 'Dodawanie nowego zasobu';
        formFactory.open(formParams);
    };

    vm.search = function () {
        vm.customSearch = resourceTableDefinition.searchByFields(vm);
    };

}
}());
