;(function() {
"use strict";

/**
 * Egzamin
 *
 * @Controller ExamPreviewController
 * @module online
 * @project EWR
 */

angular
    .module('ewr.online')
    .controller('ExamPreviewController', ExamPreviewController);

ExamPreviewController.$inject = ['$scope', '$stateParams', 'onlineExamService'];
function ExamPreviewController($scope, $stateParams, onlineExamService) {
    const vm = this;

    vm.examId = $stateParams.examId;
    vm.studentId = $stateParams.studentId;
    vm.exam = {};

    onlineExamService.getExam(vm.examId, vm.studentId).then(
        function (response) {
            vm.exam = response.data;
        },
        function (response, status) {
        }
    );

}
}());
