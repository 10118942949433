;(function() {
"use strict";

/**
 * Przypomnienie hasła
 *
 * @Controller ResetPasswordController
 * @module layout
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.auth')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = ['$state', 'authService', 'formFactory', 'constants', '$timeout', 'Notification'];

    function ResetPasswordController($state, authService, formFactory, constants, $timeout, Notification) {
        angular.element(".modal").hide();
        angular.element(".modal-backdrop").hide();
        const formParams = {
            name: 'reset-password',
            title: "Przypomnij hasło",
            restService: authService,
            gotoStateAfterFinish: 'home',
            customCancelAction: function (params) {
                angular.element(".modal-backdrop").hide();
                params.uibModalInstance.dismiss('cancel');
                $state.go('home');
            },
            customSubmitAction: function (params) {
                authService.passwordReset(params.model).then(
                    function() {
                        $state.go('home');
                        Notification.success("Sprawdź pocztę w celu odzyskania hasła ...");
                        angular.element(".modal-backdrop").hide();
                        params.uibModalInstance.dismiss('cancel');
                    }, function () {
                        Notification.success("Sprawdź pocztę w celu odzyskania hasła ...");
                    }
                );
            },
            size: constants.MODAL_SIZE_LOGIN,
            submitButtonLabel: 'Wyślij'
        };

        $timeout(function() {
            angular.element(".modal-backdrop").show();
            formFactory.open(formParams);
        },500);

    }
})();
}());
