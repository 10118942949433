;(function() {
"use strict";

angular
    .module('ewr.online')
    .controller('ExamScoreController', ExamScoreController);

ExamScoreController.$inject = ['$scope', '$stateParams', 'data', 'onlineExamService', '$uibModalInstance', 'formFactory', 'constants', 'watchService', 'notifyService'];
function ExamScoreController($scope, $stateParams, data, onlineExamService, $uibModalInstance, formFactory, constants, watchService, notifyService) {
    const vm = this;
    vm.score = {};

    onlineExamService.getExamScore(data.examId, data.studentId)
        .then(
            function (response) {
                vm.score = response.data;
            },
            function (response, status) {
            }
        );

    vm.close = function close() {
        $uibModalInstance.close();
    }

}
}());
