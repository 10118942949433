;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny - dodaj zadanie do zestawu
 *
 * @Controller ExamAddTaskController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.exams')
        .controller('ExamAddTaskController', ExamAddTaskController);

    ExamAddTaskController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'examService', 'tableDefinitions', 'watchService'];
    function ExamAddTaskController($scope, $uibModalInstance, Notification, examService, tableDefinitions, watchService) {
        const vm = this;
        const formParams = {
            restService: examService
        };

        vm.dictionary = {};
        vm.collection = $scope.$parent.vm.collection;
        vm.searchFields = {};
        vm.selectedRows = [];

        // table filter
        vm.searchFields.examMode = vm.collection.mode;
        vm.searchFields.examType = vm.collection.type;
        vm.searchFields.professionId = vm.collection.profession.id !== null ? vm.collection.profession.id + '' : '0';

        // table definition
        const taskTableDefinition = tableDefinitions.getTableDefinitionMgmtCollectionTask(formParams);
        vm.listingColumnDefinitions = taskTableDefinition.listingColumnDefinitions;
        vm.tableActions = taskTableDefinition.tableActions;
        vm.examService = examService;


        examService.getTaskFilter(vm.collection.id).then(
            function (response) {
                const dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {


                    if (dictionary.name === 'profession') {
                        vm.dictionary.professions = dictionary.items;
                        vm.dictionary.professions.unshift({name: 'Temat ogólny', value: '0'})
                    }
                    if (dictionary.name === 'examType') {
                        vm.dictionary.examTypes = dictionary.items;
                    }
                    if (dictionary.name === 'examMode') {
                        vm.dictionary.examModes = dictionary.items;
                        if (vm.dictionary.examModes.length > 1) {
                            vm.dictionary.examModes.unshift({name: 'Wszystkie rodzaje', value: ''})
                        }
                    }
                    if (dictionary.name === 'taskLevel') {
                        vm.dictionary.taskLevels = dictionary.items;
                    }
                    if (dictionary.name === 'taskConfig') {
                        vm.dictionary.taskConfigs = dictionary.items;
                    }
                    if (dictionary.name === 'topic') {
                        vm.dictionary.topics = dictionary.items;
                    }

                });

                // set default filter value
                vm.search();
            },
            function (response, status) {
                // TODO
            }
        );

        vm.topicFilter = function(topics) {
            const result = [];
            const topicIds = [];
            angular.forEach(vm.dictionary.topics, function (topic) {
                    angular.forEach(vm.dictionary.taskConfigs, function (config) {
                        if (topicIds.indexOf(topic.value) === -1) {
                            if (vm.searchFields.examMode !== '') {
                                if (config.extra.topicId === topic.value
                                    && config.extra.mode === vm.searchFields.examMode
                                    && config.extra.type === vm.searchFields.examType) {
                                    topicIds.push(topic.value);
                                    result.push(topic);
                                }
                            } else {
                                if (config.extra.topicId === topic.value && config.extra.type === vm.searchFields.examType) {
                                    topicIds.push(topic.value);
                                    result.push(topic);
                                }
                            }
                        }
                    });
            });

            return result;
        };

        vm.search = function () {
            vm.customSearch = taskTableDefinition.searchByFields(vm);
        };

        /**
         * Akcja zamknięcia okna formularza bez zapisu danych formularza.
         */
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * Akcja zapisu danych
         */
        vm.save = function() {
            if (vm.selectedRows.length > 0) {

                const data = {
                    taskIds: vm.selectedRows
                };

                examService.assignTasks(vm.collection.id, data).then(
                    function (response) {
                        $uibModalInstance.close();
                        Notification.success("Przypisano zadania do zestawu");
                        watchService.reload();
                    },
                    function (response, status) {
                        Notification.error("Wystąpił problem podczas zapisu ....");
                    }
                );
            }

        }
    }

})();
}());
