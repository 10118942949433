;(function() {
"use strict";

/**
 * Moduł Izby.
 *
 * @module organizations
 * @project EWR
 *
 * @param $stateProvider
 */
angular
    .module('ewr.organizations', [], config);


config.$inject = ['$stateProvider'];

/**
 * Funkcja reprezentujaca routing w module Izby.
 *
 * @param $stateProvider
 */
function config($stateProvider) {
    $stateProvider
        .state('orgNews', {
            parent: 'index',
            url: '/izby/aktualnosci/:page',
            views: {
                'content@index': {
                    templateUrl: 'app/organizations/templates/news/list.html',
                    controller: 'OrgNewsController as vm'
                }
            },
            auth: true
        })
        .state('orgNewsDetails', {
            parent: 'index',
            url: '/izby/aktualnosci/id/:id',
            views: {
                'content@index': {
                    templateUrl: 'app/organizations/templates/news/details.html',
                    controller: 'OrgNewsDetailsController as vm'
                }
            },
            auth: false
        })
        .state('orgMessage', {
            parent: 'index',
            url: '/izby/korespondencja',
            views: {
                'content@index': {
                    templateUrl: 'app/organizations/templates/messages.html',
                    controller: 'OrgMessageController as vm'
                }
            },
            auth: true
        })
        .state('orgProfession', {
            parent: 'index',
            url: '/izby/lista-zawodow',
            views: {
                'content@index': {
                    templateUrl: 'app/organizations/templates/professions.html',
                    controller: 'OrgProfessionController as vm'
                }
            },
            auth: true
        })
        .state('orgCommission', {
            parent: 'index',
            url: '/izby/komisje-egzaminacyjne',
            views: {
                'content@index': {
                    templateUrl: 'app/organizations/templates/commissions.html',
                    controller: 'OrgCommissionsController as vm'
                }
            },
            auth: true
        })
        .state('templates', {
            parent: 'index',
            url: '/izby/szablony',
            views: {
                'content@index': {
                    templateUrl: 'app/organizations/templates/templates.html',
                    controller: 'OrgTemplatesController as vm'
                }
            },
            auth: true
        });
}
}());
