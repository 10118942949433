;(function() {
"use strict";

/**
 *
 *
 * @module auth
 * @project EWR
 *
 * @param $stateProvider
 */
angular
    .module('ewr.auth', [], config);

config.$inject = ['$stateProvider'];

function config($stateProvider) {
    $stateProvider
        .state('resetPassword', {
            parent: 'index',
            url: '/auth/przypomnienie-hasla/',
            views: {
                'content@index': {
                    controller: 'ResetPasswordController as vm'
                }
            },
            auth: false
        })
        .state('resetPasswordConfirm', {
            parent: 'index',
            url: '/auth/przypomnienie-hasla/potwierdzenie/:hash',
            views: {
                'content@index': {
                    controller: 'ResetPasswordConfirmController as vm'
                }
            },
            auth: false
        });

}
}());
