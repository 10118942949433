;(function() {
"use strict";

angular.module('ewr.layout')
    .controller('MainController', MainController);

MainController.$inject = ['initService', 'authService', 'authenticationService', '$scope', 'Keepalive', '$uibModal', 'API_CONFIG'];

function MainController(initService, authService, authenticationService, $scope, Keepalive, $uibModal, API_CONFIG) {
    var vm = this;
    initService.init().then(function (response) {
        console.log("App initialized ...");
        authenticationService.init(response);
    });

    /**
     * Configure Idle and Keepalive
     */
    $scope.sessionWarningModal = null;
    $scope.sessionTimedoutModal = null;

    $scope.$on('IdleStart', function () {
        closeSessionModals();

        $scope.sessionWarningModal = $uibModal.open({
            templateUrl: 'app/layout/templates/session-warning-template.html',
            backdrop: 'static',
            controller: ['$scope', '$interval', '$uibModalInstance', 'constants',
                function ($scope, $interval, $uibModalInstance, constants) {

                    $scope.initialTimer = constants.SESSION_USER_ACTION_TIMEOUT;
                    $scope.timer = constants.SESSION_USER_ACTION_TIMEOUT;

                    var angularTimer = $interval(function () {
                        $scope.timer--;
                        if ($scope.timer === 0) {
                            closeSessionModals();

                            authService.deauthenticate();
                            $interval.cancel(angularTimer);

                            // display session timed out modal on logout screen.
                            $scope.sessionTimedoutModal = $uibModal.open({
                                templateUrl: 'app/layout/templates/session-timeout-template.html',
                                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                                    $scope.closeModal = function () {
                                        $uibModalInstance.dismiss('cancel');
                                    };
                                }]
                            });
                        }
                    }, 1000, $scope.initialTimer);

                    $scope.extendSession = function () {
                        Keepalive.ping();
                        $interval.cancel(angularTimer);
                        $uibModalInstance.dismiss('cancel');
                    };
                }]
        });
    });

    // do something to keep the user's session alive
    $scope.$on('Keepalive', function () {
        // authService.sessionKeepalive().then(function (response) {
        //     if (!_.isEmpty(response.data)) {
        //         if (response.data.code === '0') {
        //             var newTokenExpiration = response.headers('x-auth-token-inactive-interval');
        //             authenticationService.extendSession(newTokenExpiration);
        //         }
        //         else {
        //             Notification.error('Wystąpił błąd przy przedłużaniu sesji.');
        //         }
        //     }
        // }, function (error) {
        //     authenticationService.deauthenticate();
        // });
    });

    // close session warning and timeout modals
    function closeSessionModals() {
        if (!_.isEmpty($scope.sessionWarningModal)) {
            $scope.sessionWarningModal.close();
            $scope.sessionWarningModal = null;
        }

        if (!_.isEmpty($scope.sessionTimedoutModal)) {
            $scope.sessionTimedoutModal.close();
            $scope.sessionTimedoutModal = null;
        }
    }
}
}());
