;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny
 *
 * @Controller ExamController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.exams')
        .controller('ExamController', ExamController);

    ExamController.$inject = ['$state', 'utilsService', 'Notification', 'examService', 'tableDefinitions', 'formFactory', 'constants', 'watchService'];
    function ExamController($state, utilsService, Notification, examService, tableDefinitions, formFactory, constants, watchService) {
        const vm = this;
        const editFormParams = {
            name: 'exam',
            title: "Edytuj zestaw egzaminacyjny",
            restService: examService
        };

        vm.dictionary = {};
        vm.filterParams = {};

        examService.getFilter().then(
            function (response) {
                const dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    if (dictionary.name === 'examMode') {
                        vm.dictionary.examModes = dictionary.items;
                    }
                    if (dictionary.name === 'examType') {
                        vm.dictionary.examTypes = dictionary.items;
                    }
                    if (dictionary.name === 'collectionStatus') {
                        vm.dictionary.statuses = dictionary.items;
                    }
                    if (dictionary.name === 'profession') {
                        vm.dictionary.professions = dictionary.items;
                    }
                });
            },
            function (response, status) {
            }
        );

        const examTableDefinition = tableDefinitions.getTableDefinitionMgmtExamCollection(editFormParams);

        vm.listingColumnDefinitions = examTableDefinition.listingColumnDefinitions;
        vm.tableActions = examTableDefinition.tableActions;
        vm.examService = examService;

        vm.search = function () {
            vm.customSearch = examTableDefinition.searchByFields(vm);
        };

        vm.add = function() {
            // TODO
            const addFormParams = {
                name: 'exam',
                title: "Dodaj zestaw egzaminacyjny",
                restService: examService,
                dictName: constants.FORM_DICT_EXAM,
                customSubmitAction: function(params) {
                    examService.save(params.model).then(
                        function(response) {
                            const examId = response.data;
                            params.uibModalInstance.dismiss('cancel');
                            Notification.success("Zapisano zestaw egzaminacyjny");
                            $state.go('examExamDetails', {id: examId});
                        }, function () {
                            // TODO add suitable message from procedure call !!!
                            Notification.error("Wystąpił problem podczas zapisu ....");
                        }
                    );
                }
            };
            formFactory.open(addFormParams);
        };

        vm.generate = function() {
            const generateFormParams = {
                name: 'exam',
                title: "Generuj zestaw egzaminacyjny",
                restService: examService,
                submitButtonLabel: 'GENERUJ',
                dictName: constants.FORM_DICT_EXAM_GENERATE,
                customSubmitAction: function(params) {
                    return examService.generate(params.model).then(
                        function() {
                            params.uibModalInstance.dismiss('cancel');
                            Notification.success("Wygenerowano zestaw egzaminacyjny");
                            watchService.reload();
                        }, function () {
                            // TODO add suitable message from procedure call !!!
                            Notification.error("Generowanie nie udało się ...");
                        }
                    );
                }
            };

            formFactory.open(generateFormParams);
        };

    }
})();
}());
