;(function() {
"use strict";

/**
 * Polityka cookie.
 *
 * @Controller CookiePolicyController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('CookiePolicyController', CookiePolicyController);

    CookiePolicyController.$inject = ['PAGES'];
    function CookiePolicyController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }
})();
}());
