;(function() {
"use strict";

/**
 * Serwis obsługujący zestawy egzaminacyjne.
 *
 * @service examService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('examService', ExamService);

ExamService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function ExamService(http, utils, API_CONFIG) {

    var service = {
        getList: getList,
        getFilter: getFilter,
        getTaskFilter: getTaskFilter,
        getTaskToAddList: getTaskToAddList,
        assignTasks: assignTasks,
        get: get,
        save: save,
        generate: generate,
        print: print,
        preview: preview,
        finish: finish,
        reject: reject,
        approve: approve,
        activate: activate,
        deactivate: deactivate,
        resumption:resumption,
        deleteItem: deleteItem,
        getTaskList: getTaskList,
        getTestByProfessionId: getTestByProfessionId,
        deleteCollectionTask: deleteCollectionTask,
        getCollectionTask: getCollectionTask,
        changeCollectionTaskOrder: changeCollectionTaskOrder,
        changeCollectionTaskOrderDirection: changeCollectionTaskOrderDirection
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, params);
        return http.get(url);
    }

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, 'filter');
        return http.get(url);
    }

    function getTaskFilter(collectionId) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, collectionId, 'task-filter');
        return http.get(url);
    }

    /**
     * Zwraca dostępną listę zadań do dodania do danego zestawu
     *
     * @param params parametry wyszukiwania
     */
    function getTaskToAddList(params) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, params.id, 'task-to-add');
        url = utils.updateUrl(url, params);
        return http.get(url);
    }

    /**
     * Przypisuje zadanie do zestawu
     *
     * @param collectionId identyfikator zestawu
     * @param data identyfikatory zadań
     */
    function assignTasks(collectionId, data) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, collectionId, 'assign-tasks');
        return http.post(url, data);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_EXAM;
        return http.post(url, item);
    }

    function generate(data) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, 'generate');
        return http.post(url, data);
    }

    function finish(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id, 'finish');
        return http.put(url);
    }

    function reject(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id, 'reject');
        return http.put(url);
    }

    function approve(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id, 'approve');
        return http.put(url);
    }

    function activate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id, 'activate');
        return http.put(url);
    }

    function deactivate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id, 'deactivate');
        return http.put(url);
    }

    function resumption(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id, 'resumption');
        return http.put(url);
    }

    function print(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, 'generate', id);
        return http.get(url);
    }

    function preview(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, 'preview', id);
        return http.get(url);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, id);
        return http.delete(url);
    }

    function getTestByProfessionId(professionId) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_TEST, 'profession', professionId);
        return http.get(url);
    }

    function getTaskList(params) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, params.id, 'task');
        url = utils.updateUrl(url, params);
        return http.get(url);
    }

    function deleteCollectionTask(collectionId, taskId) {
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, collectionId, 'task', taskId);
        return http.delete(url);
    }

    function getCollectionTask(collectionId, taskId) {
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, collectionId, 'task', taskId);
        return http.get(url);
    }

    function changeCollectionTaskOrder(collectionId, taskId, data) {
        var url = '{0}/{1}/{2}/{3}/{4}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, collectionId, 'task', taskId, 'change-order');
        return http.put(url, data);
    }

    function changeCollectionTaskOrderDirection(collectionId, taskId, data) {
        var url = '{0}/{1}/{2}/{3}/{4}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_EXAM, collectionId, 'task', taskId, 'change-order-direction');
        return http.put(url, data);
    }

}
}());
