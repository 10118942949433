;(function() {
"use strict";

/**
 * Serwis odpowiedzialny za initcjalizację aplikacji.
 *
 * @service authService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('initService', initService);

initService.$inject = ['http', 'API_CONFIG'];

/**
 *
 * @param http
 * @param API_CONFIG
 * @returns {{init: init}}
 */
function initService(http, API_CONFIG) {

    return {
        init: init
    };

    /**
     *
     * @returns {*}
     */
    function init() {
        return http.get(API_CONFIG().ENDPOINT_INIT);
    }
}
}());
