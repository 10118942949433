;(function() {
"use strict";

/**
 * Serwis obsługujący zestawy testowe.
 *
 * @service testService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('testService', TestService);

TestService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function TestService(http, utils, API_CONFIG) {

    var service = {
        getList: getList,
        getFilter: getFilter,
        getTaskFilter: getTaskFilter,
        getTaskToAddList: getTaskToAddList,
        assignTasks: assignTasks,
        getTask: getTask,
        get: get,
        save: save,
        deleteItem: deleteItem,
        generate: generate,
        print: print,
        getTaskList: getTaskList,
        getTest: getTest,
        deleteCollectionTask: deleteCollectionTask,
        getCollectionTask: getCollectionTask,
        changeCollectionTaskOrder: changeCollectionTaskOrder
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, params);
        return http.get(url);
    }

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, 'filter');
        return http.get(url);
    }

    function getTaskFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, 'task-filter');
        return http.get(url);
    }

    /**
     * Zwraca dostępną listę zadań do dodania do danego zestawu
     *
     * @param params parametry wyszukiwania
     */
    function getTaskToAddList(params) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, params.id, 'task-to-add');
        url = utils.updateUrl(url, params);
        return http.get(url);
    }

    /**
     * Przypisuje zadanie do zestawu
     *
     * @param collectionId identyfikator zestawu
     * @param data identyfikatory zadań
     */
    function assignTasks(collectionId, data) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, collectionId, 'assign-tasks');
        return http.post(url, data);
    }

    /**
     * Podgląd zadania
     *
     * @param taskId identyfikator zadania
     */
    function getTask(taskId) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, 'task', taskId);
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, id);
        return http.get(url);
    }

    function save(item) {
        // TODO
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, id);
        return http.delete(url);
    }

    function generate(data) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, 'generate');
        return http.post(url, data);
    }

    function print(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, 'print', id);
        return http.get(url);
    }

    function getTest() {
        return http.get(API_CONFIG().ENDPOINT_TEST);
    }

    function getTaskList(params) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, params.id, 'task');
        url = utils.updateUrl(url, params);
        return http.get(url);
    }

    function deleteCollectionTask(collectionId, taskId) {
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, collectionId, 'task', taskId);
        return http.delete(url);
    }

    function getCollectionTask(collectionId, taskId) {
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, collectionId, 'task', taskId);
        return http.get(url);
    }

    function changeCollectionTaskOrder(collectionId, taskId, data) {
        var url = '{0}/{1}/{2}/{3}/{4}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_TEST, collectionId, 'task', taskId, 'change-order');
        return http.put(url, data);
    }

}
}());
