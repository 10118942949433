;(function() {
"use strict";

/**
 * Zadania testowe
 *
 * @Controller TaskController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('TaskController', TaskController);


    TaskController.$inject = ['utilsService', 'taskService', 'tableDefinitions', 'formFactory', 'constants', 'fileService', 'formUploadService'];
    function TaskController(utilsService, taskService, tableDefinitions, formFactory, constants, fileService, formUploadService) {
        const vm = this;
        const formParams = {
            name: 'task',
            title: "{0} zadanie {1}",
            dictName: constants.FORM_DICT_TASK,
            restService: taskService,
        };

        vm.dictionary = {};
        vm.filterParams = {
            professionId: ''
        };

        taskService.getFilter().then(
            function (response) {
                const dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    if (dictionary.name === 'profession') {
                        vm.dictionary.professions = dictionary.items;
                        vm.dictionary.professions.unshift({name: 'Temat ogólny', value: '0'})
                    }
                    if (dictionary.name === 'examMode') {
                        vm.dictionary.examModes = dictionary.items;
                    }
                    if (dictionary.name === 'examType') {
                        vm.dictionary.examTypes = dictionary.items;
                    }
                    if (dictionary.name === 'taskLevel') {
                        vm.dictionary.taskLevels = dictionary.items;
                    }
                    if (dictionary.name === 'topic') {
                        vm.dictionary.topics = dictionary.items;
                    }
                });
            },
            function (response, status) {
                // TODO
            }
        );


        const taskTableDefinition = tableDefinitions.getTableDefinitionMgmtTask(formParams);

        vm.listingColumnDefinitions = taskTableDefinition.listingColumnDefinitions;
        vm.tableActions = taskTableDefinition.tableActions;
        vm.taskService = taskService;

        vm.search = function () {
            vm.customSearch = taskTableDefinition.searchByFields(vm);
        };

        vm.addTask = function () {
            delete formParams['objId'];
            formFactory.open(formParams, true);
        }

        vm.import = function () {
            const formParams = {
                name: 'cms-files',
                title: "Importowanie zadań egzaminacyjnych",
                isTaskImport: true,
                restService: fileService
            };

            formUploadService.open(formParams)
        }
    }
})();
}());
