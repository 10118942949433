;(function() {
"use strict";

/**
 * Admin - zarządzanie rolami.
 *
 * @Controller AdminRoleController
 * @module admin
 * @project EWR
 */
angular.module('ewr.admin')
    .controller('AdminRoleController', AdminRoleController);

AdminRoleController.$inject = ['roleService', 'tableDefinitions', 'formFactory', 'constants'];

function AdminRoleController(roleService, tableDefinitions, formFactory, constants) {

    const vm = this;
    const formParams = {
        name: 'role',
        title: "{0} rolę {1}",
        restService: roleService,
    };
    vm.search = {};

    const roleTableDefinition = tableDefinitions.getTableDefinitionAdminRole(formParams);

    vm.listingColumnDefinitions = roleTableDefinition.listingColumnDefinitions;
    vm.tableActions = roleTableDefinition.tableActions;
    vm.roleService = roleService;

    vm.searchRole = function () {
        vm.customSearch = roleTableDefinition.searchByFields(vm);
    };

    vm.addRole = function () {
        delete formParams['objId'];
        formParams['dictName'] = constants.FORM_DICT_ROLE;
        formFactory.open(formParams);
    };

}
}());
