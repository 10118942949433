;(function() {
"use strict";

/**
 * Serwis obsługujący walidacje formularzy.
 *
 * @service formValidators
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('formValidators', formValidators);

formValidators.$inject = ['userService', '$timeout'];

function formValidators(userService, $timeout) {

    /**
     * Fasada walidatorów.
     * @type {{sampleValidator: *}}
     */
    const validators = {
        requiredRoles: requiredRoles(),
        sampleValidator: sampleValidator(),
        uniqueLoginValidator: uniqueLoginValidator(),
        passwordPolicyValidator: passwordPolicyValidator(),
        passwordConfirmValidator: passwordConfirmValidator(),
        resetPasswordConfirmValidator: resetPasswordConfirmValidator(),
        peselValidator: peselValidator()
    };

    const service = {
        get: get,
        requiredRoles: requiredRoles,
        sampleValidator: sampleValidator,
        uniqueLoginValidator: uniqueLoginValidator,
        passwordPolicyValidator: passwordPolicyValidator,
        passwordConfirmValidator: passwordConfirmValidator,
        youtubeUrlValidator: youtubeUrlValidator,
        uploadImportFileValidator: uploadImportFileValidator,
        resetPasswordConfirmValidator: resetPasswordConfirmValidator,
        peselValidator: peselValidator
    };

    return service;

    /**
     * Zwraca instancję wskazanego walidatora.
     * @param name
     * @returns {*}
     */
    function get(name) {
        if(name in validators) {
            return validators[name];
        }
        return "";
    }

    function uniqueLoginValidator() {
        return function($viewValue, $modelValue, scope) {
            if (scope.model['id'] !== undefined) {
                return $timeout(function(){});
            } else {
                scope.options.templateOptions.loading = true;
                return userService.findByLogin($viewValue).then(function (response) {
                    scope.options.templateOptions.loading = false;
                    if (response.data !== null && response.data !== '') {
                        throw new Error('taken');
                    }
                }, function (response) {
                    scope.options.templateOptions.loading = false;
                });
            }
        }
    }

    /**
     * Walidator odpowiedzialny za weryfikację ...
     *
     * @returns {Function}
     */
    function sampleValidator() {
        return function(viewValue, modelValue) {
            const  value = modelValue || viewValue;
            return /(\d{1,3}\.){3}\d{1,3}/.test(value);
        }
    }

    function requiredRoles() {
        return function($viewValue, $modelValue, $scope) {
            $scope.fc.$setValidity('required', true);
            return true
        }
    }


    /**
     * Password policy validation method.
     * Regex pattern:
     * /^
     *  (?=.*[a-z]*)                                - any alphabetic character
     *  (?=.*\w*[A-Z].*[A-Z].*)                     - at least 2 capital letters
     *  (?=.*[0-9].*[0-9].*)                        - at least 2 digits
     *  (?=.*[!@#()_+=~$%^&*].*[!@#()_+=~$%^&*].*)  - at least 2 special characters
     *  .{12,}                                      - minimum 12 characters in password
     * $/
     *
     * @returns {Function}
     */
    function passwordPolicyValidator() {
        return function($viewValue) {
            const re = /^(?=.*[a-z]*)(?=.*\w*[A-Z].*[A-Z].*)(?=.*[0-9].*[0-9].*)(?=.*[!@#()_+=~$%^&*].*[!@#()_+=~$%^&*].*).{12,}$/;
            return re.test($viewValue);
        }
    }

    /**
     * Walidator numeru PESEL
     * @returns {function(*=): boolean}
     */
    function peselValidator() {
        return function($viewValue) {
            const re = /^\d{11}$/;
            return re.test($viewValue);
        }
    }


    /**
     *
     * @returns {Function}
     */
    function passwordConfirmValidator() {
        return function($viewValue, $modelValue, scope) {
            return $viewValue === scope.model['newPassword'];
        }
    }

    /**
     *
     * @returns {Function}
     */
    function resetPasswordConfirmValidator() {
        return function($viewValue, $modelValue, scope) {
            return $viewValue === scope.model['password'];
        }
    }

    /**
     * 
     * @param url
     * @returns {boolean}
     */
    function youtubeUrlValidator(url) {
        const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        const matches = url.match(p);
        if(matches){
            return true;
        }
        return false;
    }


    function uploadImportFileValidator(fileItem) {
        const extArray = ["xls", "xlsx"];
        var valid = false;
        for(var ext in extArray) {
            if(fileItem.file.name.indexOf(extArray[ext]) > -1) {
                valid = true;
                break;
            }
        }
        return valid;
    }
};
}());
