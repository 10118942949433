;(function() {
"use strict";

/**
 * Moduł Strona główna.
 *
 * @module home
 * @project EWR
 */
angular
    .module('ewr.home', [], config);


config.$inject = ['$stateProvider'];

function config($stateProvider) {
    $stateProvider
        .state('home', {
            parent: 'index',
            url: '/home',
            views: {
                'content@index': {
                    templateUrl: 'app/home/templates/home.html',
                    controller: 'HomeController as vm'
                }
            },
            auth: false
        })
        .state('cookiePolicy', {
            parent: 'index',
            url: '/polityka-cookie',
            views: {
                'content@index': {
                    templateUrl: 'app/home/templates/cookiePolicy.html',
                    controller: 'CookiePolicyController as vm'
                }
            },
            auth: false
        })
        .state('homeExamInfo', {
            parent: 'index',
            url: '/o-egzaminach',
            views: {
                'content@index': {
                    templateUrl: 'app/home/templates/examInfo.html',
                    controller: 'ExamInfoController as vm'
                }
            },
            auth: false
        });
}
}());
