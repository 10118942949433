;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('appForm', appForm);

appForm.$inject = ['$state'];
function appForm($state) {

    var directiveDefinitionObject = {
        restrict: 'E',
        scope: {
            title: '@',
            name: '@',
            objId: '=',
            api: '=?',
            dictName: '@',
            submitButtonLabel: '@',
            submitAction: '&?',
            cancelButtonLabel: '@',
            cancelAction: '&?'
        },
        templateUrl: function () {
            return 'app/common/directives/ui/components/appForm/app-form.template.html';
        },
        controller: AppFormController,
        controllerAs: 'vm'
    };

    return directiveDefinitionObject;
}

AppFormController.$inject = ['$scope', 'formFactory', 'formDictionaryService', 'Notification'];
function AppFormController($scope, formFactory, formDictionaryService, Notification) {
    var vm = this;

    vm.formData = {
        restService: $scope.api,
        title: $scope.title,
        formName: $scope.name,
        objId: $scope.objId,
        submitButtonLabel: angular.isDefined($scope.submitButtonLabel) ? $scope.submitButtonLabel : 'ZAPISZ',
        submitAction: angular.isDefined($scope.submitAction) ? $scope.submitAction : null,
        cancelButtonLabel: angular.isDefined($scope.cancelButtonLabel) ? $scope.cancelButtonLabel : 'ANULUJ',
        cancelAction: angular.isDefined($scope.cancelAction) ? $scope.cancelAction : null
    };

    if (angular.isDefined($scope.dictName)) {
        formDictionaryService.getDict($scope.dictName).then(function(response) {
            vm.formData.dict = response.data;
            generateForm();

        });
    } else {
        generateForm();
    }


    function generateForm() {
        /**
         * Pobranie definicji oraz modelu danych formularza oraz danych.
         */
        formFactory.get(vm.formData.formName).then(function (data) {
            vm.formData.fields = data.data;
            formFactory.appendFieldValidators(vm.formData.fields);
            formFactory.appendSelectOptions(vm.formData);
        });
    }

    vm.submit = function () {
        vm.form.$submitted = true;
        return $scope.submitAction({
            data: vm.formData.model
        })
    };

    vm.cancel = function () {
        $scope.cancelAction({
            data: vm.formData.model
        })
    }

}
}());
