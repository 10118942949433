;(function() {
"use strict";

angular
    .module('ewr.common')
    .filter('examScoreName', examScoreName)
    .filter('examScoreNumber', examScoreNumber);

function examScoreName() {
    return function(val) {
        if (!val) {
            return '';
        }

        val = parseFloat(val);
        if (val < 2.86) {
            return 'Niedostateczny';
        }
        if (val >= 2.86 && val <= 3.55) {
            return 'Dostateczny';
        }
        if (val >= 3.56 && val <= 4.55) {
            return 'Dobry';
        }
        if (val >= 4.56 && val <= 5.55) {
            return 'Bardzo dobry';
        }
        if (val >= 5.56) {
            return 'Celujący';
        }

        return '';
    };
}

function examScoreNumber() {
    return function(val) {
        if (!val) {
            return '';
        }

        val = parseFloat(val);
        if (val < 2.86) {
            return 2;
        }
        if (val >= 2.86 && val <= 3.55) {
            return 3;
        }
        if (val >= 3.56 && val <= 4.55) {
            return 4;
        }
        if (val >= 4.56 && val <= 5.55) {
            return 5;
        }
        if (val >= 5.56) {
            return 6;
        }

        return '';
    };
}
}());
