;(function() {
"use strict";

/**
 * Admin - zarządzanie karuzelą.
 *
 * @Controller AdminCarouselController
 * @module admin
 * @project EWR
 */

angular.module('ewr.admin')
    .controller('AdminCarouselController', AdminCarouselController);

AdminCarouselController.$inject = ['carouselService', 'tableDefinitions', 'formFactory', 'constants'];
function AdminCarouselController(carouselService, tableDefinitions, formFactory, constants) {

    const vm = this;
    const formParams = {
        name: 'cms-carousel',
        title: "{0} pozycję {1}",
        restService: carouselService,
        dictName: constants.FORM_DICT_RESOURCE
    };

    const carouselTableDefinition = tableDefinitions.getTableDefinitionAdminCarousel(formParams);

    vm.listingColumnDefinitions = carouselTableDefinition.listingColumnDefinitions;
    vm.tableActions = carouselTableDefinition.tableActions;
    vm.carouselService = carouselService;

    vm.addCarousel = function () {
        delete formParams['objId'];
        formFactory.open(formParams);
    };

}
}());
