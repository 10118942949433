;(function() {
"use strict";

/**
 * Akty prawne i uchwały.
 *
 * @Controller LawsAndResolutionsController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('LawsAndResolutionsController', CurrentInfoController);

    CurrentInfoController.$inject = ['PAGES'];
    function CurrentInfoController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;

    }
})();
}());
