;(function() {
"use strict";

/**
 * Terminarz egzaminów
 *
 * @Controller OnlineScheduleController
 * @module online
 * @project EWR
 */

angular
    .module('ewr.online')
    .controller('OnlineScheduleController', OnlineScheduleController);

OnlineScheduleController.$inject = ['$scope','onlineScheduleService', 'tableDefinitions', 'formFactory', 'constants', 'watchService', 'notifyService'];
function OnlineScheduleController($scope, onlineScheduleService, tableDefinitions, formFactory, constants, watchService, notifyService) {
    const vm = this;

    const formParams = {
        name: 'schedule',
        title: "{0} schedule {1}",
        restService: onlineScheduleService,
    };

    vm.dictionary = {};
    vm.filterParams = {
        professionId: ''
    };

    onlineScheduleService.getFilter().then(
        function (response) {
            const dictionaries = response.data;
            angular.forEach(dictionaries, function (dictionary) {
                if (dictionary.name === 'profession') {
                    vm.dictionary.professions = dictionary.items;
                    vm.dictionary.professions.unshift({name: 'Temat ogólny', value: '0'})
                }
                if (dictionary.name === 'examMode') {
                    vm.dictionary.examModes = dictionary.items;
                }
                if (dictionary.name === 'scheduleStatus') {
                    vm.dictionary.scheduleStatuses = dictionary.items;
                }
            });
        },
        function (response, status) {
            Notification.error("Wystąpił problem podczas pobierania danych formularza, skontaktuj się z administratorem serwisu ...");
        }
    );

    const scheduleTableDefinition = tableDefinitions.getTableDefinitionOnlineSchedule(formParams);

    vm.listingColumnDefinitions = scheduleTableDefinition.listingColumnDefinitions;
    vm.tableActions = scheduleTableDefinition.tableActions;
    vm.onlineScheduleService = onlineScheduleService;

    vm.search = function () {
        vm.customSearch = scheduleTableDefinition.searchByFields(vm);
    };

    vm.add = function() {
        const addFormParams = {
            name: 'online-schedule',
            title: "Utwórz nowy egzamin online",
            restService: onlineScheduleService,
            dictName: constants.FORM_DICT_SCHEDULE,
            customSubmitAction: function (params) {
                onlineScheduleService.save(params.model).then(
                    function() {
                        watchService.reload();
                        notifyService.success("Poprawnie zapisano dane egzaminu ... ");
                        angular.element(".modal-backdrop").hide();
                        params.uibModalInstance.dismiss('cancel');
                    }, function () {
                        notifyService.error("Zapis danych egzaminu nie powiódł się ...");
                    }
                );
            },
        };
        formFactory.open(addFormParams);
    };

}
}());
