;(function() {
"use strict";

/**
 * Admin - zarządzanie artykułami.
 *
 * @Controller AdminArticleController
 * @module admin
 * @project EWR
 */

angular.module('ewr.admin')
    .controller('AdminArticleController', AdminArticleController);

AdminArticleController.$inject = ['articleService', 'tableDefinitions', 'formFactory', 'constants'];
function AdminArticleController(articleService, tableDefinitions, formFactory, constants) {
    const vm = this;
    const formParams = {
        name: 'cms-article',
        title: "{0} artykuł {1}",
        dictName: constants.FORM_DICT_ARTICLE,
        restService: articleService,
    };

    vm.dictionary = {};
    vm.filterParams = {};

    articleService.getFilter().then(
        function (response) {
            const dictionaries = response.data;
            angular.forEach(dictionaries, function (dictionary) {
                if (dictionary.name === 'organization') {
                    vm.dictionary.organizations = dictionary.items;
                }
            });
        },
        function (response, status) {
            // TODO
        }
    );

    const articleTableDefinition = tableDefinitions.getTableDefinitionAdminArticle(formParams);

    vm.listingColumnDefinitions = articleTableDefinition.listingColumnDefinitions;
    vm.tableActions = articleTableDefinition.tableActions;
    vm.articleService = articleService;

    vm.search = function () {
        vm.customSearch = articleTableDefinition.searchByFields(vm);
    };

    vm.addNewArticle = function () {
        delete formParams['objId'];
        formFactory.open(formParams)
    };

}
}());
