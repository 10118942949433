;(function() {
"use strict";

/**
 * Bieżące informacje.
 *
 * @Controller CurrentInfoController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('CurrentInfoController', CurrentInfoController);

    CurrentInfoController.$inject = ['$state', '$stateParams', 'PAGES', 'articleService', 'pagerService'];
    function CurrentInfoController($state, $stateParams, PAGES, articleService, pagerService) {
        var vm = this;
        vm.PAGES = PAGES;

        vm.archival = false;
        vm.keyword = null;
        vm.ds = {};

        vm.load = function () {

            if ($stateParams.archival === null || $stateParams.archival === undefined || $stateParams.archival === '') {
                vm.archival = false;
            } else {
                vm.archival = $stateParams.archival;
            }
            vm.keyword = !$stateParams.keyword ? '' : $stateParams.keyword;

            var params = {
                'offset': pagerService.getOffset(),
                'limit': pagerService.getLimit(),
                'sortAsc': false,
                'sortBy': 'publishDate',
                'archival': vm.archival,
                'keyword': vm.keyword
            }

            articleService.getNews(params).then(
                function (response) {
                    vm.ds = response.data;
                },
                function (response, status) {
                    // TODO
                }
            );
        }

        vm.search = function() {
            var params = {
                'page': 1,
                'keyword': vm.keyword !== null ? vm.keyword : '',
                'archival': vm.archival
            }

            $state.go('currentInfo', params);
        }

        vm.load();

        vm.extraParams = {
            'keyword': vm.keyword,
            'archival': vm.archival
        }

    }
})();
}());
