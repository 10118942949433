;(function() {
"use strict";

/**
 * Koresponencja.
 *
 * @Controller OrgMessageController
 * @module organizations
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.organizations')
        .controller('OrgMessageController', OrgMessageController)
        .controller('OrgMessagePreviewModalInstanceController', OrgMessagePreviewModalInstanceController);


    OrgMessageController.$inject = ['$uibModal', '$sce', 'PAGES', 'pageService', 'orgMessageService', 'tableDefinitions'];
    function OrgMessageController($uibModal, $sce, PAGES, pageService, orgMessageService, tableDefinitions) {
        const vm = this;
        pageService.getByGuid(PAGES.ORG_MESSAGES).then(
            function (response) {
                vm.pageTitle = response.data.title;
                vm.pageContent = $sce.trustAsHtml(response.data.content); 
            },
            function (response, status) {
            }
        );

        const formParams = {
            restService: orgMessageService,
        };

        const orgMessageTableDefinition = tableDefinitions.getTableDefinitionOrgMessage(formParams);

        vm.listingColumnDefinitions = orgMessageTableDefinition.listingColumnDefinitions;
        vm.tableActions = orgMessageTableDefinition.tableActions;
        vm.orgMessageService = orgMessageService;

        vm.preview = function (item) {

            if (item.read !== true) {
                // oznacz wiadomość jako przeczytaną zanim wyświetlimy dialog
                formParams.restService.markAsRead(item.id).then(function (response) {
                    $uibModal.open({
                        templateUrl: function () {
                            return 'app/organizations/templates/message/preview.html';
                        },
                        controller: 'OrgMessagePreviewModalInstanceController',
                        controllerAs: 'vm',
                        resolve: {
                            data: function () {
                                return item;
                            }
                        }
                    });
                });
            } else {
                $uibModal.open({
                    templateUrl: function () {
                        return 'app/organizations/templates/message/preview.html';
                    },
                    controller: 'OrgMessagePreviewModalInstanceController',
                    controllerAs: 'vm',
                    resolve: {
                        data: function () {
                            return item;
                        }
                    }
                });
            }
        }

    };

    OrgMessagePreviewModalInstanceController.$inject = ['$uibModalInstance', 'constants', 'watchService', 'data'];
    function OrgMessagePreviewModalInstanceController($uibModalInstance, constants, watchService, data) {
        const vm = this;
        vm.cancel = cancel;
        vm.message = data;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
            if (data.read !== true) {
                watchService.reload();
            }
        }

    };

})();
}());
