;(function() {
"use strict";

/**
 * Admin - zarządzanie użytkownikami.
 *
 * @Controller AdminUserController
 * @module admin
 * @project EWR
 */
angular.module('ewr.admin')
    .controller('AdminUserController', AdminUserController);

AdminUserController.$inject = ['userService', 'tableDefinitions', 'formFactory', 'constants'];
function AdminUserController(userService, tableDefinitions, formFactory, constants) {

    const vm = this;
    const formParams = {
        name: 'user',
        title: "{0} użytkownika {1}",
        restService: userService,
    };

    vm.search = {};

    const userTableDefinition = tableDefinitions.getTableDefinitionAdminUser(formParams);

    vm.listingColumnDefinitions = userTableDefinition.listingColumnDefinitions;
    vm.tableActions = userTableDefinition.tableActions;
    vm.userService = userService;

    vm.search = function () {
        vm.customSearch = userTableDefinition.searchByFields(vm);
    };

    vm.addUser = function () {
        delete formParams['objId'];
        formParams['dictName'] = constants.FORM_DICT_USER;
        formFactory.open(formParams);
    };

}
}());
