;(function() {
"use strict";

/**
 * Serwis obsługujący korespondencje.
 *
 * @service messageService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('messageService', MessageService);

MessageService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function MessageService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_MESSAGE, params);
        return http.get(url);
    }

    function get(id) {
        // TODO
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_MESSAGE;
        return http.post(url, item);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_MESSAGE, id);
        return http.delete(url);
    }

}
}());
