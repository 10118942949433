;(function() {
"use strict";

angular
    .module('ewr.common')
    .filter('objectToArrayMap', objectToArrayMap)
    .filter('arrayToArrayMap', arrayToArrayMap)
    .filter('objectToArray', objectToArray)
    .filter('groupArray', groupArray)
    .filter('groupByFirstLetter', groupByFirstLetter);

function objectToArrayMap() {
    return function(obj) {
        var result = [];
        angular.forEach(obj, function(val, key) {
            result.push(
                {
                    'key': key,
                    'val': val
                }
            );
        });

        return result;
    };
}

function arrayToArrayMap() {
    return function(array) {
        var result = [];
        for (var key in array) {
            result.push(
                {
                    'key': key,
                    'val': array[key]
                }
            );
        };

        return result;
    };
}

function objectToArray() {
    return function(obj) {
        var result = [];
        angular.forEach(obj, function(val, key) {
            result.push(val);
        });
        return result;
    };
}

function groupArray() {

    return function(array, cols) {
        if (typeof array === 'undefined' || array === null || array.length <= 0) {
            return array;
        }

        function split(array, cols) {
            if (cols==1) return array;
            var size = Math.ceil(array.length / cols);
            return array.slice(0, size).concat([null]).concat(split(array.slice(size), cols-1));
        }

        var a = split(array, cols);
        var groups = [];
        var group = [];
        for(var i = 0; i < a.length; i++) {
            if (a[i] === null) {
                groups.push(group);
                group = [];
                continue;
            }
            group.push(a[i]);

        }
        groups.push(group);
        return groups;
    };
}

function groupByFirstLetter() {

    return function (array) {
        var result = [];
        array.forEach(function(entry) {
            var letter = entry.charAt(0);
            if (!result[letter]) {
                result[letter] = [];
            }
            result[letter].push(entry);
        });

        return result;
    };

}
}());
