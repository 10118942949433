;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny - szczegóły/lista zadań
 *
 * @Controller ExamDetailsController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.exams')
        .controller('ExamDetailsController', ExamDetailsController);

    ExamDetailsController.$inject = ['$scope', '$stateParams', '$uibModal', 'constants', 'examService', 'tableDefinitions'];
    function ExamDetailsController($scope, $stateParams, $uibModal, constants, examService, tableDefinitions) {
        const vm = this;
        vm.collectionId = $stateParams.id;
        vm.collection = {};

        examService.get(vm.collectionId).then(
            function (response) {
                vm.collection = response.data;
            },
            function (response, status) {
            }
        );

        const formParams = {
            name: 'exam',
            apiCall: 'getTask',
            title: 'Zadanie',
            dictName: constants.FORM_DICT_TASK,
            restService: examService,
            collectionId: vm.collectionId
        };

        const taskTableDefinition = tableDefinitions.getTableDefinitionMgmtExamCollectionTask(formParams);

        vm.listingColumnDefinitions = taskTableDefinition.listingColumnDefinitions;
        vm.tableActions = taskTableDefinition.tableActions;
        vm.defaultSort = taskTableDefinition.defaultSort;
        vm.examService = examService;

        /**
         * Dodaj zadanie do zestawu
         */
        vm.addTask = function() {
            $scope.collectionId = vm.collectionId;
            $uibModal.open({
                templateUrl: 'app/exams/templates/exam/task-add.html',
                controller: 'ExamAddTaskController',
                controllerAs: 'vm',
                animation: true,
                size: 'lg',
                scope: $scope
            });
        };
    }

})();
}());
