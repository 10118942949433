;(function() {
"use strict";

/**
 * Serwis odpowiedzialny za obsługę terminarza egzaminów online.
 *
 * @service onlineScheduleService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('onlineScheduleService', onlineScheduleService);

onlineScheduleService.$inject = ['http', 'API_CONFIG', 'utilsService', 'constants'];

/**
 *
 * @param http
 * @param API_CONFIG
 * @param utils
 * @param constants
 * @returns {{register: (function(*=, *=): *), getList: (function(*=): *), save: save}}
 */
function onlineScheduleService(http, API_CONFIG, utils, constants) {

    return {
        getList: getList,
        get: get,
        getFilter: getFilter,
        save: save,
        addStudent: addStudent,
        generateTask: generateTask,
        generateTokens: generateTokens,
        getListBoardExam: getListBoardExam,
        addExaminationBoardExam: addExaminationBoardExam,
        removeExaminationBoardFromExam: removeExaminationBoardFromExam,
        toApprove: toApprove,
        approve: approve,
        toImprove: toImprove,
        remove: remove,
        removeExaminationBoardFromExam: removeExaminationBoardFromExam,
        getListCollectionTask: getListCollectionTask,
        deleteCollectionTask: deleteCollectionTask,
        changeCollectionTaskOrderDirection: changeCollectionTaskOrderDirection,
        getTaskFilter: getTaskFilter,
        getTasks: getTasks,
        addTaskToCollection: addTaskToCollection
    };
    /**
     * Pobiera listę egzaminów.
     *
     * @param params
     * @returns {*}
     */
    function getList(params) {
        const url = utils.updateUrl(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE_LIST, params);
        return http.get(url);
    }

    /**
     * Pobiera szczegóły egzaminu.
     *
     * @param params
     * @returns {*}
     */
    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, id);
        return http.get(url);
    }

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'filter');
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_ONLINE_SCHEDULE;

        if (item.id) {
            url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, item.id);
            return http.put(url, item);
        }

        return http.post(url, item);
    }


    /**
     * Dodaje studenta do egzaminu
     *
     * @param studentId
     * @param scheduleExamId
     * @param examProcedure
     * @returns {*}
     */
    function addStudent(studentId, scheduleExamId, data) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'add-to-exam', studentId, scheduleExamId);
        return http.put(url, data);
    }

    /**
     * Generuje zestawy egzaminacyjne dla kandydatów w ramach egzaminu
     *
     * @param scheduleExamId
     * @returns {*}
     */
    function generateTask(scheduleExamId) {
        const url = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'generate', scheduleExamId);
        return http.put(url);
    }

    /**
     * Generuje tokeny autoryzacyjne dla studentów.
     *
     * @param scheduleExamId
     * @returns promise z odpowiedzi usługi generującej token (zip)
     */
    function generateTokens(scheduleExamId) {
        const url = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'generate-tokens', scheduleExamId);
        return http.get(url);
    }
    /**
     * Pobiera skład komisji/kandydatów do składu komisji.
     *
     * @param params
     * @returns {*}
     */
    function getListBoardExam(params) {
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'examination-board', params.examScheduleId, params.examScheduleMode);
        url = utils.updateUrl(url, params);
        return http.get(url);
    }

    /**
     * Dodaje osobę do komisji
     *
     * @param scheduleExamId
     * @param userId
     */
    function addExaminationBoardExam(scheduleExamId, userId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'add-examination-board-to-exam', scheduleExamId, userId);
        return http.put(url);
    }

    /**
     * Usuwa osobę z komisji
     *
     * @param scheduleExamId
     * @param examinationBoardId
     */
    function removeExaminationBoardFromExam(scheduleExamId, examinationBoardId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'remove-examination-board-from-exam', scheduleExamId, examinationBoardId);
        return http.put(url);
    }

    function toApprove(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, id, 'to-approve');
        return http.put(url);
    }

    function approve(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, id, 'approve');
        return http.put(url);
    }

    function toImprove(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, id, 'to-improve');
        return http.put(url);
    }

    function remove(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, id, 'remove');
        return http.put(url);
    }

    function getListCollectionTask(params){
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'exam-collection', params.examScheduleId, params.studentId);
        return http.get(url);
    }

    function deleteCollectionTask(collectionId, taskId) {
        var url = '{0}/{1}/{2}/{3}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, collectionId, 'task', taskId);
        return http.delete(url);
    }

    function changeCollectionTaskOrderDirection(collectionId, taskId, data) {
        var url = '{0}/{1}/{2}/{3}/{4}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, collectionId, 'task', taskId, 'change-order-direction');
        return http.put(url, data);
    }

    function getTaskFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'task-filter');
        return http.get(url);
    }

    function getTasks(params) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'tasks');
        url = utils.updateUrl(url, params);
        return http.get(url);
    }

    function addTaskToCollection(collectionId, studentId, taskId) {
        var url = '{0}/{1}/{2}/{3}/{4}'.format(API_CONFIG().ENDPOINT_ONLINE_SCHEDULE, 'add-task-to-collection', collectionId, studentId, taskId);
        return http.put(url);
    }
}
}());
