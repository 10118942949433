;(function() {
"use strict";

/**
 * Moduł Informacje.
 *
 * @module info
 * @project EWR
 */
angular
    .module('ewr.info', [], config);


config.$inject = ['$stateProvider'];


function config($stateProvider) {
    $stateProvider
        .state('projectInfo', {
            parent: 'index',
            url: '/informacje/informacje-o-projekcie',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/projectInfo.html',
                    controller: 'ProjectInfoController as vm'
                }
            },
            auth: false
        })
        .state('currentInfo', {
            parent: 'index',
            url: '/informacje/biezace-informacje/:page/:keyword/{archival:bool}',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/currentInfo.html',
                    controller: 'CurrentInfoController as vm'
                }
            },
            auth: false
        })
        .state('articleDetails', {
            parent: 'index',
            url: '/informacje/biezace-informacje/id/:id',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/article.html',
                    controller: 'ArticleController as vm'
                }
            },
            auth: false
        })
        .state('lawsAndResolutions', {
            parent: 'index',
            url: '/informacje/akty-prawne-i-uchwaly',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/lawsAndResolutions.html',
                    controller: 'LawsAndResolutionsController as vm'
                }
            },
            auth: false
        })
        .state('newsReporting', {
            parent: 'index',
            url: '/informacje/relacje-z-dzialalnosci-egzaminacyjnych',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/newsReporting.html',
                    controller: 'NewsReportingController as vm'
                }
            },
            auth: false
        })
        .state('commissions', {
            parent: 'index',
            url: '/informacje/wykaz-komisji-egzaminacyjnych',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/commissions.html',
                    controller: 'CommissionsController as vm'
                }
            },
            auth: false
        })
        .state('examInfo', {
            parent: 'index',
            url: '/informacje/informacje-o-egzaminach',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/examInfo.html',
                    controller: 'ExamInfoController as vm'
                }
            },
            auth: false
        })
        .state('professions', {
            parent: 'index',
            url: '/informacje/lista-zawodow',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/professions.html',
                    controller: 'ProfessionsController as vm'
                }
            },
            auth: false
        })
        .state('krk', {
            parent: 'index',
            url: '/informacje/krajowy-rejestr-kwalifikacji',
            views: {
                'content@index': {
                    templateUrl: 'app/info/templates/krk.html',
                    controller: 'KrkController as vm'
                }
            },
            auth: false
        })
}
}());
