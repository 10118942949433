;(function() {
"use strict";

/**
 * Admin - korespondencja.
 *
 * @Controller AdminMessageController
 * @module admin
 * @project EWR
 */
angular.module('ewr.admin')
    .controller('AdminMessageController', AdminMessageController)
    .controller('AdminMessagePreviewModalInstanceController', AdminMessagePreviewModalInstanceController);

AdminMessageController.$inject = ['messageService', 'tableDefinitions', 'formFactory'];
function AdminMessageController(messageService, tableDefinitions, formFactory) {

    const vm = this;
    const formParams = {
        name: 'send-message',
        title: 'Wyślij wiadomość',
        restService: messageService
    };

    const messageTableDefinition = tableDefinitions.getTableDefinitionAdminMessage(formParams);
    vm.listingColumnDefinitions = messageTableDefinition.listingColumnDefinitions;
    vm.tableActions = messageTableDefinition.tableActions;
    vm.messageService = messageService;

    vm.sendMessage = function () {
        delete formParams['objId'];
        formParams['submitButtonLabel'] = 'Wyślij';
        formFactory.open(formParams);
    };

}

AdminMessagePreviewModalInstanceController.$inject = ['$uibModalInstance', 'constants', 'data'];
function AdminMessagePreviewModalInstanceController($uibModalInstance, constants, data) {
    const vm = this;
    vm.cancel = cancel;
    vm.message = data;

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

};
}());
