;(function() {
"use strict";

/**
 * Prezentacje.
 *
 * @Controller PresentationsController
 * @module exams
 * @project resources
 */

(function () {
    'use strict';

    angular
        .module('ewr.resources')
        .controller('PresentationsController', PresentationsController);


    PresentationsController.$inject = ['resourceService', 'CONFIG_TABLE'];
    function PresentationsController(resourceService, CONFIG_TABLE) {
        var vm = this;
        vm.items = new Array();
        vm.paginate = paginate;
        vm.loadData = loadData;

        vm.loadData(urlParams);

        var urlParams = {
            offset: 0,
            limit: CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE,
        };

        function paginate (offset) {
            urlParams.offset = offset;
            urlParams.limit = CONFIG_TABLE.URL_PAGINATE_DEFAULT_SIZE;
            vm.loadData(urlParams);
            return;
        };

        function loadData(urlParams) {
            resourceService.getPresentations(urlParams).then(
                function (response) {
                    vm.items = response.data.items;
                    vm.meta = response.data.meta;
                },
                function (response, status) {
                }
            );
        }

    }
})();
}());
