;(function() {
"use strict";

/**
 * Serwis obsługujący pliki.
 *
 * @service fileService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('fileService', fileService);

fileService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function fileService(http, utils, API_CONFIG) {
    var service = {
        getFilter: getFilter,
        getList: getList,
        save: save,
        deleteItem: deleteItem,
        activate: activate,
        deactivate: deactivate
    };

    return service;

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_FILE, 'filter');
        return http.get(url);
    }

    /**
     *
     * @param params
     * @returns {*}
     */
    function getList(params) {
        var url = "{0}/{1}".format(API_CONFIG().ENDPOINT_MANAGEMENT_FILE, "list");
        url = utils.updateUrl(url, params);

        return http.get(url);
    }

    /**
     *
     * @param files
     * @returns {*}
     */
    function save(files) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_FILE;
        return http.post(url, files);
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_FILE, id);
        return http.delete(url);
    }

    /**
     *
     * @param id
     * @returns {HttpPromise|*}
     */
    function activate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_FILE, id, 'activate');
        return http.put(url);
    }

    /**
     *
     * @param id
     * @returns {HttpPromise|*}
     */
    function deactivate(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_FILE, id, 'deactivate');
        return http.put(url);
    }
}
}());
