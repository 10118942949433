;(function() {
"use strict";

/**
 * Custom prototypes definitions.
 */
angular
    .module('ewr')
    .run(defineFormatPrototype);

/**
 * Defines format function on String.prototype
 *
 * .format() usage:
 * '{0} is an awesome {1}'.format('js', 'language') will yield 'js is an awesome language'
 */
function defineFormatPrototype() {
    String.prototype.format = function () {
        var formatted = this;
        for (var i = 0; i < arguments.length; i++) {
            var regexp = new RegExp('\\{' + i + '\\}', 'gi');
            formatted = formatted.replace(regexp, arguments[i]);
        }
        return formatted;
    };
}
}());
