;(function() {
"use strict";

/**
 * Serwis obsługujący pokaz slajdów zasobów.
 *
 * @service slideshowService
 * @module common
 * @component ui
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('slideshowService', slideshowService)
    .controller('SlideshowModalInstanceController', SlideshowModalInstanceController);

slideshowService.$inject = ['$uibModal'];
SlideshowModalInstanceController.$inject = ['$uibModalInstance', 'formData'];

/**
 *
 * @param $uibModal
 * @returns {{open: open}}
 */
function slideshowService($uibModal) {


    var service = {
        open: open
    };

    return service;

    /**
     * 
     * @param params
     */
    function open(params) {
        var result = $uibModal.open({
            templateUrl: 'app/common/services/ui/components/slideshow/slideshowModal.html',
            controller: 'SlideshowModalInstanceController',
            controllerAs: 'vm',
            size: 'lg',
            backdrop: 'static',
            keyboard  : false,
            resolve: {
                formData: function () {
                    return {
                        params: params
                    }
                }
            }
        }).result;
    };
}

/**
 *
 * @param $uibModalInstance
 * @param formData
 * @constructor
 */
function SlideshowModalInstanceController($uibModalInstance, formData) {
    var vm = this;
    vm.close = close;
    vm.items = prepareItems(formData.params.items, formData.params.selectedItem);

    vm.slickConfig = {
        enabled: true,
        autoplay: true,
        draggable: true,
        autoplaySpeed: 5000,
        dots: true
    };

    /**
     *
     */
    function close() {
        $uibModalInstance.dismiss('cancel');
    }

    /**
     *
     * @param items
     * @param selectedItem
     * @returns {Array}
     */
    function prepareItems(items, selectedItem) {
        var newItems = new Array();
        newItems.push(selectedItem);
        angular.forEach(items, function (item) {
            if(item.id !== selectedItem.id && item.type === 'PHOTO') {
                newItems.push(item);
            }
        });
        return newItems;
    }
}
}());
