;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny - administracja zestawem egzaminacyjnym online
 *
 * @Controller OnlineScheduleCollectionController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineScheduleCollectionController', OnlineScheduleCollectionController);

    OnlineScheduleCollectionController.$inject = ['$scope', '$stateParams', '$uibModal', '$uibModalInstance', 'constants', 'onlineScheduleService', 'onlineStudentService', 'tableDefinitions', 'watchService', 'dataItem', 'notifyService'];
    function OnlineScheduleCollectionController($scope, $stateParams, $uibModal, $uibModalInstance, constants, onlineScheduleService, onlineStudentService, tableDefinitions, watchService, dataItem, notifyService) {
        const vm = this;
        vm.collectionId = $stateParams.id;
        vm.taskStatus = dataItem.taskStatus;

        const formParams = {
            name: 'collection',
            title: "{0} collection {1}",
            restService: onlineScheduleService,
            collectionId: vm.collectionId,
            studentId: dataItem.id,
            hidePagination: true
        };

        const customSearch = [];
        customSearch.push({key: 'examScheduleId', value: vm.collectionId});
        customSearch.push({key: 'studentId', value: dataItem.id});
        vm.customSearch = customSearch;

        const onlineScheduleCollectionTask = tableDefinitions.getTableDefinitionOnlineScheduleCollectionTask(formParams);

        vm.listingColumnDefinitions = onlineScheduleCollectionTask.listingColumnDefinitions;
        vm.tableActions = onlineScheduleCollectionTask.tableActions;
        vm.onlineScheduleService = onlineScheduleService;

        vm.approve = function approve() {
           onlineStudentService.approveTaskCollection(vm.collectionId, dataItem.id).then(function (response) {
              vm.taskStatus = 'APPROVED';
              watchService.reload();
              notifyService.success("Zaakceptowano zestaw egzaminacyjny");
           });
        }

        vm.canEdit = function() {
            if (vm.collection && dataItem.taskStatus === 'TO_APPROVE') {
                return true;
            }
            return false;
        }

        /**
         * Dodaj zadanie do egzaminu
         */
        vm.addTask = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/online/templates/schedule/task-add.html',
                controller: 'OnlineTaskController',
                controllerAs: 'vm',
                animation: true,
                size: 'lg',
                scope: $scope,
                resolve: {
                    dataItem: function () {
                        var dataTmp = {"collectionId": vm.collectionId,
                                       "studentId": dataItem.id}
                        return dataTmp;
                    }
                }
            });

            modalInstance.result.then(function() {
               watchService.reload();
            }, function() {
               watchService.reload();
            });
        };

        vm.close = function close() {
            $uibModalInstance.close();
        }

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
}());
