;(function() {
"use strict";

/**
 * Zestaw testowy - szczegóły/lista zadań
 *
 * @Controller TestDetailsController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.exams')
        .controller('TestDetailsController', TestDetailsController);

    TestDetailsController.$inject = ['$scope', '$stateParams', '$uibModal', 'constants', 'testService', 'tableDefinitions'];
    function TestDetailsController($scope, $stateParams, $uibModal, constants, testService, tableDefinitions) {
        const vm = this;
        vm.collectionId = $stateParams.id;
        vm.collection = {};


        testService.get(vm.collectionId).then(
            function (response) {
                vm.collection = response.data;
            },
            function (response, status) {
                // TODO
            }
        );

        const formParams = {
            name: 'task',
            apiCall: 'getTask',
            title: 'Zadanie',
            dictName: constants.FORM_DICT_TASK,
            restService: testService,
            collectionId: vm.collectionId
        };

        const taskTableDefinition = tableDefinitions.getTableDefinitionMgmtTestCollectionTask(formParams);

        vm.listingColumnDefinitions = taskTableDefinition.listingColumnDefinitions;
        vm.tableActions = taskTableDefinition.tableActions;
        vm.testService = testService;

        /**
         * Dodaj zadanie do zestawu
         */
        vm.addTask = function() {
            $scope.collectionId = vm.collectionId;
            $uibModal.open({
                templateUrl: 'app/exams/templates/test/task-add.html',
                controller: 'TestAddTaskController',
                controllerAs: 'vm',
                animation: true,
                size: 'lg',
                scope: $scope
            });
        };

    }

})();
}());
