;(function() {
"use strict";

/**
 * Serwis obsługujący użytkowników.
 *
 * @service userService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('userService', UserService);

UserService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function UserService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem,
        lock: lock,
        unlock: unlock,
        findByLogin: findByLogin,
        resetPassword: resetPassword
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_MANAGEMENT_USER, params);
        return http.get(url);
    }

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_USER, id);
        return http.get(url);
    }

    function findByLogin(login) {
        var url = '{0}/by-login/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_USER, login);
        return http.get(url);
    }

    function save(item) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_USER;
        if (item.id) {
            url = '{0}/{1}'.format(url, item.id);
            return http.put(url, item);
        }

        return http.post(url, item);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_USER, id);
        return http.delete(url);
    }

    function lock(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_USER, id, 'lock');
        return http.put(url);
    }

    function unlock(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_USER, id, 'unlock');
        return http.put(url);
    }

    function resetPassword(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_USER, id, 'reset-password');
        return http.put(url);
    }

}
}());
