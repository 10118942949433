;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('fileUtils', FileUtils);

function FileUtils() {

    return {
        downloadFile: downloadFile
    };

    function downloadFile(response) {
        const fileName = response.fileName;
        const contentType = 'application/octet-stream';
        const sliceSize = 512;

        const byteCharacters = atob(response.base64);
        const byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        saveAs(blob, fileName);
    }

}
}());
