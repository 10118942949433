;(function() {
"use strict";

/**
 * Serwis obsługujący korespondencje.
 *
 * @service messageService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('orgMessageService', OrgMessageService);

OrgMessageService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function OrgMessageService(http, utils, API_CONFIG) {
    var service = {
        getList: getList,
        get: get,
        save: save,
        deleteItem: deleteItem,
        markAsRead: markAsRead
    };

    return service;

    function getList(params) {
        var url = utils.updateUrl(API_CONFIG().ENDPOINT_ORG_MESSAGE, params);
        return http.get(url);
    }

    function get(id) {
        // TODO
    }

    function save(item) {
        // TODO
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ORG_MESSAGE, id);
        return http.delete(url);
    }

    function markAsRead(id) {
        var url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ORG_MESSAGE, id, 'mark-as-read');
        return http.put(url);
    }

}
}());
