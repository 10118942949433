;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('ewr.validators', validatorsService);

validatorsService.$inject = ['date'];

// żółćęąźńśŻÓŁĆĘĄŹŃŚ
function validatorsService(dateService) {
    var digitRegex = /^[0-9]+$/;
    var digitLetterRegex = /^[a-zA-Z0-9]*$/;
    var moneyRegex = /^([0-9])+(\.[0-9]{0,2})?$/;
    var nonDigitsRegex = /^[a-zA-Z0-9żółćęąźńśŻÓŁĆĘĄŹŃŚ\ \-]*$/;
    var companyNameRegex = /^[a-zA-Z0-9żółćęąźńśŻÓŁĆĘĄŹŃŚ\ \.\/]*$/;
    var phoneNumberRegex = /^[0-9\+\ ]+$/;
    var zipCodeRegex = /^[0-9\-]+$/;
    var addressNumberRegex = /^[\da-zA-Z\/\-]*$/;
    var digitDotRegex = /^\.|([0-9]+)$/;

    function allowDigits($modelValue, $viewValue, scope) {
        var maxValue = scope.to.maxValue;
        var minValue = scope.to.minValue;
        testNewValueRegex(digitRegex, $modelValue, minValue, maxValue);
    }

    function allowDigitsLetters($modelValue, $viewValue, scope) {
        testNewValueRegex(digitLetterRegex, $modelValue);
    }

    function allowMoney($modelValue, $viewValue, scope) {
        var maxValue = scope.to.maxValue;
        var minValue = scope.to.minValue;
        testNewValueRegex(moneyRegex, $modelValue, minValue, maxValue);
    }

    function allowNonDigits($modelValue, $viewValue, scope) {
        testNewValueRegex(nonDigitsRegex, $modelValue);
    }

    function allowCompanyName($modelValue, $viewValue, scope) {
        testNewValueRegex(companyNameRegex, $modelValue);
    }

    function addressNumber($modelValue, $viewValue, scope) {
        testNewValueRegex(addressNumberRegex, $modelValue);
    }

    function allowPhoneNumber($modelValue, $viewValue, scope) {
        testNewValueRegex(phoneNumberRegex, $modelValue);
    }

    function allowDateFormatFormly(model, view, scope) {
        allowDateFormat(scope.fc.$viewValue)
    }

    function allowZipCode($modelValue, $viewValue, scope) {
        testNewValueRegex(zipCodeRegex, $modelValue);
    }

    function allowDateFormatChars($event) {
        var key = String.fromCharCode($event.keyCode);
        if( !/^[0-9]|\-$/.test(key) )
            $event.preventDefault();
    }

    function allowDigitsDot($modelValue, $viewValue, scope) {
        testNewValueRegex(digitDotRegex, $modelValue);
    }

    function allowDateFormat(text, minValue, maxValue) {
        text = text || '';
        var event = window.event;
        var key = event.keyCode || event.which;
        key = String.fromCharCode(key);
        text = text + key;

        var invalid = false;
        var split = text.split('-');
        var sizes = [2, 2, 4];

        if(split.length > 0 && split.length <= 3) {
            for(var i = 0; i < split.length; ++i) {
                if(split[i].length > sizes[i] || !(split[i]==='' || digitRegex.test(split[i]))) {
                    invalid = true;
                    break;
                }
            }
            for(var j = 0; j < split.length-1; ++j) {
                if(split[j]==='') {
                    invalid = true;
                    break;
                }
            }
        }
        else {
            invalid = true;
        }

        if(invalid == true && event.preventDefault)
            event.preventDefault();
    }

    function testNewValueRegex(regex, text, minValue, maxValue) {
        text = text || '';
        var event = window.event;
        var key = event.keyCode || event.which;
        key = String.fromCharCode(key);
        text = text + key;

        var value = parseFloat(text);

        if(!regex.test(text) && event.preventDefault || ( !isNaN(value) && ((minValue && value < minValue) || (value && value > maxValue))))
            event.preventDefault();
    }

    function validateZipCode(value) {
        return !value || /^[0-9]{2}\-[0-9]{3}/.test(value)
    }

    function validateNip(nip) {
        if(!nip)
            return true;
        var nip_bez_kresek = nip.replace(/-/g, "");
        var reg = /^[0-9]{10}$/;
        if (reg.test(nip_bez_kresek) == false) {
            return false;
        }
        else {
            var dig = ("" + nip_bez_kresek).split("");
            var kontrola = (6 * parseInt(dig[0]) + 5 * parseInt(dig[1]) + 7 * parseInt(dig[2]) + 2 * parseInt(dig[3]) + 3 * parseInt(dig[4]) + 4 * parseInt(dig[5]) + 5 * parseInt(dig[6]) + 6 * parseInt(dig[7]) + 7 * parseInt(dig[8])) % 11;
            if (parseInt(dig[9]) == kontrola)
                return true;
            else
                return false;
        }
    }

    function validateMoney(money) {
        return !money || /^([0-9])+(\.[0-9]{2})?$/.test(money);
    }

    function validatePesel(pesel) {
        if(pesel==='.')
            return true;

        // PESEL:
        // 11 digits numbers. Last digit is control digit verified against expression for the first 10 digits. Exp: (1*a + 3*b + 7*c + 9*d + 1*e + 3*f + 7*g + 9*h + 1*i + 3*j) last digit of the result is substracted from 10 and compared with the control digit.
        if(!pesel)
            return true;

        var reg = /^\d{11}$/;
        if (!reg.test(pesel)) {
            return false;
        }

        var dig = ("" + pesel).split("");
        var month = 10*parseInt(dig[2]) + parseInt(dig[3]);
        var day = 10*parseInt(dig[4]) + parseInt(dig[5]);

        // this validation is only for people born in XX and XXI century
        if(day < 0 || day > 31 || month <= 0 || month > 52)
            return false;

        var control = (1 * parseInt(dig[0]) + 3 * parseInt(dig[1]) + 7 * parseInt(dig[2]) + 9 * parseInt(dig[3]) + 1 * parseInt(dig[4]) + 3 * parseInt(dig[5]) + 7 * parseInt(dig[6]) + 9 * parseInt(dig[7]) + 1 * parseInt(dig[8]) + 3 * parseInt(dig[9])) % 10;

        if (control === 0) {
            control = 10;
        }
        control = 10 - control;
        if (parseInt(dig[10]) === control) {
            return true;
        } else {
            return false;
        }
    }

    function validateRegon(regon) {

        //REGON is a 9 or 14 digit number. Last digit is control digit from equation:
        // [ sum from 1 to (9 or 14) (x[i]*w[i]) ] mod 11; where x[i] is pointed NIP digit and w[i] is pointed digit
        //from [8 9 2 3 4 5 6 7] for 9 and [2 4 8 5 0 9 7 3 6 1 2 4 8] for 14 digits.
        if(!regon)
            return true;

        var n = regon.length;
        var w;
        var cd = 0; // Control digit (last digit)
        var isOnlyDigit = /^\d+$/.test(regon);

        if (n !== 9 && n !== 14 && !isOnlyDigit) {
            //console.log("Error");
            return false;
        }

        if (n === 9) {
            w = [8, 9, 2, 3, 4, 5, 6, 7];
        } else {
            w = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
        }

        for (var i = 0; i < n - 1; i++) {
            cd += w[i] * parseInt(regon.charAt(i));
        }

        cd %= 11;

        if (cd === 10) {
            cd = 0;
        }

        if (cd !== parseInt(regon.charAt(n - 1))) {
            return false;
        } else {
            return true;
        }
    }


    return {
        validateZipCode : validateZipCode,
        validateNip : validateNip,
        validatePesel : validatePesel,
        validateRegon : validateRegon,
        validateMoney : validateMoney,

        allowDigits : allowDigits,
        allowDigitsLetters : allowDigitsLetters,
        allowMoney : allowMoney,
        allowDateFormat : allowDateFormat,
        allowDateFormatFormly : allowDateFormatFormly,
        allowDateFormatChars : allowDateFormatChars,
        allowNonDigits : allowNonDigits,
        allowCompanyName : allowCompanyName,
        allowPhoneNumber : allowPhoneNumber,
        allowZipCode : allowZipCode,
        addressNumber : addressNumber,
        allowDigitsDot : allowDigitsDot
    }
}
}());
