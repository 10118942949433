;(function() {
"use strict";

/**
 * Serwis obsługujący zmianę hasła.
 *
 * @service passwordService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('passwordService', passwordService);

passwordService.$inject = ['http', 'API_CONFIG'];

function passwordService(http, API_CONFIG) {
    var service = {
        save: save,
    };

    return service;

    function save(item) {
        return http.put(API_CONFIG().ENDPOINT_MANAGEMENT_CHANGE_PASSWORD, item);
    }
}
}());
