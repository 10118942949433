;(function() {
"use strict";

/**
 * Serwis obsługujący tooltip.
 *
 * @service tooltipService
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('tooltipService', tooltipService);

tooltipService.$inject = [];

function tooltipService() {

    var service = {
        taskTooltip: taskTooltip
    };

    return service;

    function taskTooltip(item) {
        var html = '<div class="modal-body">'
            + '<div class="modal-title">'
            + '<h3 class="modal-title">ZADANIE ' + item.id + '</h3>'
            + '</div>'
            + '<div class="row">'
            + '<div clas="row">'
            + '<label>Treść</label>'
            + '<div>'
            + '<div>'
            + item.content
            + '</div>'
            + '</div>';

        for (var i = 0; i < item.answers.length; i++) {
            html += '<div class="row">'
                + '<label>Odpowiedź ' + (item.answers[i].correct === true ? '<i>(Poprawna)</i>' : '') + '</label>'
                + '<div>'
                + item.answers[i].content
                + '</div>'
                + '</div>';
        }
        html += '</div>';

        return html;
    }

}
}());
