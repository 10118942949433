;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('onlineExamService', onlineExamService);

onlineExamService.$inject = ['http', 'API_CONFIG', 'utilsService', 'constants'];

function onlineExamService(http, API_CONFIG, utils, constants) {

    return {
        getExamStats: getExamStats,
        runExam: runExam,
        finishExam: finishExam,
        pauseStudentExam: pauseStudentExam,
        resumeStudentExam: resumeStudentExam,
        blockStudentExam: blockStudentExam,
        getExamScore: getExamScore,
        getExam: getExam
    };

    function getExamStats(examId) {
        const url = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'stats');
        return http.get(url);
    }

    function runExam(examId) {
        const url = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'run');
        return http.put(url);
    }

    function finishExam(examId) {
        const url = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'finish');
        return http.put(url);
    }

    function pauseStudentExam(examId, studentId) {
        const url = "{0}/{1}/{2}/{3}/{4}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'student', studentId, 'pause');
        return http.put(url);
    }

    function resumeStudentExam(examId, studentId) {
        const url = "{0}/{1}/{2}/{3}/{4}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'student', studentId, 'resume');
        return http.put(url);
    }

    function blockStudentExam(examId, studentId) {
        const url = "{0}/{1}/{2}/{3}/{4}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'student', studentId, 'block');
        return http.put(url);
    }

    function getExamScore(examId, studentId) {
        const url = "{0}/{1}/{2}/{3}/{4}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'student', studentId, 'score');
        return http.get(url);
    }

    function getExam(examId, studentId) {
        const url = "{0}/{1}/{2}/{3}/{4}".format(API_CONFIG().ENDPOINT_ONLINE_EXAM, examId, 'student', studentId, 'preview');
        return http.get(url);
    }

}
}());
