;(function() {
"use strict";

/**
 * Notify service
 */
angular
    .module('ewr.common')
    .service('notifyService', notifyService);

notifyService.$inject = ['$filter', 'Notification'];

function notifyService($filter, Notification) {
    return {
        error: error,
        success: success
    };

    /**
     * Creates an error notification.
     *
     * @param message
     * @returns {*}
     */
    function error(message) {
        Notification.error($filter('translate')(message));
    }

    /**
     * Creates an success notification.
     *
     * @param message
     * @returns {*}
     */
    function success(message) {
        Notification.success($filter('translate')(message));
    }

}
}());
