;(function() {
"use strict";

/**
 * Header directive
 */
angular
    .module('ewr.layout')
    .directive('header', header);

function header() {
    var directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/header/header.directive.html',
        scope: {
            title: '@',
            subTitle: '@'
        },
        controller: HeaderController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

function HeaderController() {
    var vm = this;

}
}());
