;(function() {
"use strict";

/**
 * Serwis obsługujący zasoby.
 *
 * @service resourceService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('resourceService', resourceService);

resourceService.$inject = ['http', 'utilsService', 'API_CONFIG'];

function resourceService(http, utils, API_CONFIG) {
    var service = {
        get: get,
        getFilter: getFilter,
        getList: getList,
        getResources: getResources,
        getVideos: getVideos,
        getPhotos: getPhotos,
        getPresentations: getPresentations,
        save: save,
        deleteItem: deleteItem
    };

    return service;

    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_RESOURCE, id);
        return http.get(url);
    }

    function getFilter() {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_RESOURCE, 'filter');
        return http.get(url);
    }

    function getList(params) {
        var url = "{0}/{1}".format(API_CONFIG().ENDPOINT_MANAGEMENT_RESOURCE, "list");
        url = utils.updateUrl(url, params);

        return http.get(url);
    }

    function getResources(params) {
        var url = "{0}/{1}".format(API_CONFIG().ENDPOINT_RESOURCE, "list");
        url = utils.updateUrl(url, params);

        return http.get(url);
    }

    function getPresentations(params) {
        var url = "{0}/{1}".format(API_CONFIG().ENDPOINT_RESOURCE, "presentations");
        url = utils.updateUrl(url, params);

        return http.get(url);
    }

    function getPhotos(params) {
        var url = "{0}/{1}".format(API_CONFIG().ENDPOINT_RESOURCE, "photos");
        url = utils.updateUrl(url, params);

        return http.get(url);
    }

    function getVideos(params) {
        var url = "{0}/{1}".format(API_CONFIG().ENDPOINT_RESOURCE, "videos");
        url = utils.updateUrl(url, params);

        return http.get(url);
    }

    function save(files) {
        var url = API_CONFIG().ENDPOINT_MANAGEMENT_RESOURCE;
        return http.post(url, files);
    }

    function deleteItem(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_MANAGEMENT_RESOURCE, id);
        return http.delete(url);
    }

}
}());
