;(function() {
"use strict";

/**
 * Informacje o projekcie.
 *
 * @Controller ProjectInfoController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('ProjectInfoController', ProjectInfoController);

    ProjectInfoController.$inject = ['PAGES'];
    function ProjectInfoController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }
})();
}());
