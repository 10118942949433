;(function() {
"use strict";

/**
 * Relacje z działalności egzaminacyjnych.
 *
 * @Controller NewsReportingController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('NewsReportingController', NewsReportingController);

    NewsReportingController.$inject = ['PAGES'];
    function NewsReportingController(PAGES) {
        var vm = this;
        vm.PAGES = PAGES;
    }

})();
}());
