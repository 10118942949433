;(function() {
"use strict";

/**
 * Wykaz komisji egzaminacyjnych.
 *
 * @Controller CommissionsController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('CommissionsController', CommissionsController);


    CommissionsController.$inject = ['PAGES', 'professionService', 'commissionService', 'tableDefinitions'];
    function CommissionsController(PAGES, professionService, commissionService, tableDefinitions) {
        const vm = this;
        vm.PAGES = PAGES;

        const formParams = {
            restService: commissionService,
        };

        vm.professionId = null;
        vm.professions = [];
        professionService.getAllList().then(
            function (response) {
                vm.professions = response.data;
            },
            function (response, status) {
                // TODO
            }
        );

        const commissionTableDefinition = tableDefinitions.getTableDefinitionCommission(formParams);

        vm.listingColumnDefinitions = commissionTableDefinition.listingColumnDefinitions;
        vm.tableActions = commissionTableDefinition.tableActions;
        vm.commissionService = commissionService;

        vm.search = function () {
            vm.customSearch = commissionTableDefinition.searchByFields(vm);
        };

    }
})();
}());
