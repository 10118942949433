;(function() {
"use strict";

/**
 * Lista zawodów.
 *
 * @Controller ProfessionsController
 * @module info
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.info')
        .controller('ProfessionsController', ProfessionsController);

    ProfessionsController.$inject = ['PAGES', '$filter', 'professionService'];
    function ProfessionsController(PAGES, $filter, professionService) {
        var vm = this;
        vm.PAGES = PAGES;

        vm.search = search;
        vm.professions = [];
        vm.searchResult = [];
        vm.searchKeyword = null;

        professionService.getAllList().then(
            function (response) {
                response.data.forEach(function(profession) {
                    vm.professions.push(profession.name)
                });
                var filtered = $filter('groupByFirstLetter')(vm.professions);
                filtered = $filter('arrayToArrayMap')(filtered);
                filtered = $filter('groupArray')(filtered, 2);

                vm.searchResult = filtered;
            },
            function (response, status) {
            }
        );

        function search() {
            vm.keyword = vm.searchKeyword;
            var result = vm.professions;

            result = $filter('filter')(result, vm.keyword);
            result = $filter('groupByFirstLetter')(result);
            result = $filter('arrayToArrayMap')(result);
            result = $filter('groupArray')(result, 2);
            vm.searchResult = result;
        }

    }

})();
}());
