;(function() {
"use strict";

angular
    .module('ewr.common')
    .service('date', DateService);

DateService.$inject = ['$filter'];

function DateService($filter) {
    function today() {
        return convertToCapselFormat(new Date());
    }

    function getSimpleDate(date) {
        return date.slice(0, 10);
    }

    function getFormatYYYYMMDD() {
        return 'yyyy-MM-dd';
    }

    function isValidDate(date) {
        if (!date) {
            return false;
        }
        const matches = /^(\d{2})[-](\d{2})[-](\d{4})$/.exec(date);
        if (matches == null) return false;
        const d = matches[1];
        const m = matches[2];
        const y = matches[3];
        const composedDate = new Date(y, m, d);

        return composedDate.getDate() == d &&
            composedDate.getMonth() == m &&
            composedDate.getFullYear() == y;
    }

    function convertToFormatYYYYMMDD(dateToFormat) {
        if (typeof dateToFormat === Date) {
            return $filter('date')(dateToFormat, 'yyyy-MM-dd');
        } else {
            if (!dateToFormat || dateToFormat == null)
                return undefined;
            const date = new Date(dateToFormat);
            var dd = date.getDate();
            var mm = date.getMonth() + 1; //January is 0!
            const yyyy = date.getFullYear();

            if (dd < 10) {
                dd = '0' + dd
            }

            if (mm < 10) {
                mm = '0' + mm
            }
            if (isNaN(dd) || isNaN(mm) || isNaN(yyyy))
                return undefined;
            return yyyy + '-' + mm + '-' + dd;
        }
    }

    function convertToDate(value) {
        if (value == null) {
            return null;
        }

        if (typeof value === Date) {
            return value;
        }

        const dateParts = value.split('-');

        return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }

    function isDate(value) {
        if (typeof value === Date) {
            return true;
        }

        return /^(\d{4})\-\d{2}\-\d{2}$/.test(value);
    }

    return {
        today: today,

        isDate: isDate,
        isValidDate: isValidDate,

        getSimpleDate: getSimpleDate,
        getFormatYYYYMMDD: getFormatYYYYMMDD,

        convertToDate: convertToDate,
        convertToFormatYYYYMMDD: convertToFormatYYYYMMDD
    }

}
}());
