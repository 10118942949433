;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('formInvalidField', formInvalidField)

formInvalidField.$inject = ['$compile'];

function formInvalidField($compile) {
    /**
     * Returns table items.
     *
     * @param contentType
     * @returns {string}
     */
    var getTemplate = function (scope) {
        var template = '<span ng-show="({2} && field.$error.{0}) || (field.$error.{0} && !field.$pristine)" class="help-block validation-error {3}" >{1}</span>'
            .format(scope.validation, scope.message, scope.submitted, scope.errorClass);
        return template;
    }
    /**
     * Linker function
     * @param scope
     * @param element
     * @param attrs
     */
    var linker = function (scope, element) {
        scope.$watch('submitted', function (newvalue) {
            element.html(getTemplate(scope)).show();
            $compile(element.contents())(scope);
        });
    }

    var directiveDefinitionObject = {
        restrict: 'E',
        link: linker,
        scope: {
            message: '@',
            field: '=',
            validation: '@',
            submitted: '=',
            errorClass: '@?'

        }
    };
    return directiveDefinitionObject;
}
}());
