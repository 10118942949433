;(function() {
"use strict";

/**
 * Serwis odpowiedzialny za obsługę kandydatów do egzaminów online.
 *
 * @service onlineStudentService
 * @module common
 * @project EWR
 */
angular
    .module('ewr.common')
    .factory('onlineStudentService', onlineStudentService);

onlineStudentService.$inject = ['http', 'API_CONFIG', 'utilsService', 'constants'];

/**
 *
 * @param http
 * @param API_CONFIG
 * @param utils
 * @param constants
 * @returns {{register: (function(*=, *=): *), getList: (function(*=): *), save: save}}
 */
function onlineStudentService(http, API_CONFIG, utils, constants) {

    return {
        register: register,
        get: get,
        getList: getList,
        save: save,
        verified: verified,
        blocked: blocked,
        unBlocked: unBlocked,
        deleteItem: deleteItem,
        removeFromExam: removeFromExam,
        approveTaskCollection: approveTaskCollection,
        toApproveTaskCollection: toApproveTaskCollection
    };

    /**
     * Realizuje rejestrację kandydata do uczestnictwa w egzaminie online.
     *
     * @param data
     * @returns {*}
     */
    function register(organizationId, data) {
        const endpoint = "{0}/{1}/{2}".format(API_CONFIG().ENDPOINT_ONLINE, "register", organizationId);
        return http.post(endpoint, data);
    }

    /**
     * Pobiera szcegóły kandydata.
     *
     * @param params
     * @returns {*}
     */
    function get(id) {
        var url = '{0}/{1}'.format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, id);
        return http.get(url);
    }

    /**
     * Pobiera listę kandydatów do egzaminu.
     *
     * @param params
     * @returns {*}
     */
    function getList(params) {

        if (params.id) {
            const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_STUDENT_LIST, params.id, 'student');
            return http.get(url);
        }

        const url = utils.updateUrl(API_CONFIG().ENDPOINT_ONLINE_STUDENT_LIST, params);
        return http.get(url);
    }

    /**
     * Zapisuje kandydata do uczestnictwa w egzaminach online.
     *
     * @param item
     * @returns {*}
     */
    function save(data) {
        var url = "{0}".format(API_CONFIG().ENDPOINT_ONLINE_STUDENT);
        return http.post(url, data);
    }

    /**
     * Zmienia status kandydata na zweryfikowany
     *
     * @param itemId
     */
    function verified(itemId) {
        const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, itemId, 'verified');
        return http.put(url, itemId);
    }

    /**
     * Zmienia status kandydata na zablokowany
     *
     * @param itemId
     */
    function blocked(itemId) {
        const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, itemId, 'blocked');
        return http.put(url, itemId);
    }

    /**
     * Zmienia status kandydata na odblokowany
     *
     * @param itemId
     */
    function unBlocked(itemId) {
        const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, itemId, 'un-blocked');
        return http.put(url, itemId);
    }

    /**
     * Usuwa kandydata
     *
     * @param itemId
     */
    function deleteItem(itemId) {
        const url = '{0}/{1}/{2}'.format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, itemId, 'delete');
        return http.put(url, itemId);
    }

    /**
     * Usuwa kandydata z egzaminu
     *
     * @param scheduleExamId
     * @param itemId
     */
    function removeFromExam(scheduleExamId, studentId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, 'remove-from-exam', studentId, scheduleExamId);
        return http.put(url);
    }

    /**
     * Akceptuje zestaw egzaminacyjny uczestnika
     *
     * @param scheduleExamId
     * @param itemId
     */
    function approveTaskCollection(scheduleExamId, studentId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, 'approve-collection-task', studentId, scheduleExamId);
        return http.put(url);
    }

    /**
     * Przekazuje do ponownej apcektacji zestawu egzaminacyjnego uczestnika
     *
     * @param scheduleExamId
     * @param itemId
     */
    function toApproveTaskCollection(scheduleExamId, studentId) {
        const url = "{0}/{1}/{2}/{3}".format(API_CONFIG().ENDPOINT_ONLINE_STUDENT, 'to-approve-collection-task', studentId, scheduleExamId);
        return http.put(url);
    }

}
}());
