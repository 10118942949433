;(function() {
"use strict";

/**
 * Skład komisji egzaminacyjnej
 *
 * @Controller OnlineExaminationBoardSelectController
 * @module online
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineExaminationBoardSelectController', OnlineExaminationBoardSelectController);

    OnlineExaminationBoardSelectController.$inject = ['$scope', '$stateParams', 'onlineScheduleService', '$uibModalInstance', 'tableDefinitions', 'formFactory', 'constants', 'watchService', 'notifyService'];
    function OnlineExaminationBoardSelectController($scope, $stateParams, onlineScheduleService, $uibModalInstance, tableDefinitions, formFactory, constants, watchService, notifyService) {
        const vm = this;
        vm.collectionId = $stateParams.id;

        const formParams = {
            name: 'examinationBoard',
            title: "{0} examinationBoard {1}",
            restService: onlineScheduleService,
            collectionId: $stateParams.id
        };

        const customSearch = [];
        customSearch.push({key: 'examScheduleId', value: vm.collectionId});
        customSearch.push({key: 'examScheduleMode', value: 'EXCLUDE'});
        vm.customSearch = customSearch;

        const examinationBoardTableDefinition = tableDefinitions.getTableDefinitionOnlineExaminatorBoard(formParams);

        vm.listingColumnDefinitions = examinationBoardTableDefinition.listingColumnDefinitions;
        vm.tableActions = examinationBoardTableDefinition.tableActions;
        vm.onlineScheduleService = onlineScheduleService;

        vm.search = function () {
            vm.customSearch = examinationBoardTableDefinition.searchByFields(vm);
        };

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
}());
