;(function() {
"use strict";

/**
 * Dyrektywa - karuzela
 */
angular
  .module('ewr.layout')
  .directive('latestArticles', latestArticles);

function latestArticles() {
  const directive = {
    restrict: 'E',
    templateUrl: 'app/home/directives/articles/latestArticles.directive.html',
    scope: {},
    controller: LatestArticlesController,
    controllerAs: 'vm',
    bindToController: true,
    replace: true
  };
  return directive;
}

LatestArticlesController.$inject = ['$scope', 'articleService'];

function LatestArticlesController($scope, articleService) {
  var vm = this;

  vm.articlesGroup = [];

  var params = {
    'offset': 0,
    'limit': 6,
    'sortAsc': false,
    'sortBy': 'publishDate'
  };

  articleService.getNews(params).then(
    function (response) {
      vm.articlesGroup = getArticlesGroup(response.data.items);
    },
    function (response, status) {
      // TODO
    }
  );

  function getArticlesGroup(items) {
    const maxElemCount = 3;

    const result = [];
    var itemsGroup = [];
    var itemsCounter = 0;
    var lastElemIndex = items.length - 1;

    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if (itemsCounter === maxElemCount) {
        updateResult();
      }
      if (i === lastElemIndex) {
        addItemToItemGroup();
        updateResult();
      } else {
        addItemToItemGroup();
      }
    }

    function updateResult() {
      result.push(itemsGroup);
      itemsGroup = [];
      itemsCounter = 0;
    }

    function addItemToItemGroup() {
      itemsGroup.push(item);
      ++itemsCounter;
    }

    return result;
  }

}
}());
