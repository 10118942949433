;(function() {
"use strict";

/**
 * Navigation menu directive
 */
angular
    .module('ewr.layout')
    .directive('navigation', navigation);

function navigation() {
    const directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/navigation/navigation.directive.html',
        scope: {
            name: '=',
            company: '=',
            logged: '=',
            changePractice: '&'
        },
        controller: NavigationController,
        controllerAs: 'vm',
        bindToController: true,
        replace: true
    };
    return directive;
}

NavigationController.$inject = ['$scope', '$state', '$location'];

function NavigationController($scope, $state, $location) {
    const vm = this;
    vm.collapsed = true;
    $scope.$state = $state;
    setActiveItem();

    vm.toggleMenu = function () {
        vm.collapsed = !vm.collapsed;
    }

    $scope.changeLocation = function (location) {
        $scope.locationPath = location;
    }

    $scope.$watch(function(){
        return $state.$current.name
    }, function(newVal, oldVal){
        setActiveItem();
    })

    function setActiveItem() {
        const location = $location.path();
        if(location.indexOf("home") > -1) {
            $scope.locationPath = "home";
        } else if(location.indexOf("informacje") > -1) {
            $scope.locationPath = "informacje";
        } else if(location.indexOf("zasoby") > -1) {
            $scope.locationPath = "zasoby";
        } else if(location.indexOf("egzaminy") > -1) {
            $scope.locationPath = "egzaminy";
        } else if(location.indexOf("izby") > -1) {
            $scope.locationPath = "izby";
        } else if(location.indexOf("admin") > -1) {
            $scope.locationPath = "admin";
        }
    }
}
}());
