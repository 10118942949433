;(function() {
"use strict";

/**
 * Serwis potwierdzenia wykonania akcji.
 *
 * @service confirmDialog
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('confirmDialogService', confirmDialogService)
    .controller('ConfirmDialogModalController', ConfirmDialogModalController);

confirmDialogService.$inject = ['$uibModal', 'constants'];
ConfirmDialogModalController.$inject = ['$uibModalInstance', 'params'];


function confirmDialogService($uibModal, constants) {

    var service = {
        open: open
    };

    return service;

    function open(params) {
        var result = $uibModal.open({
            templateUrl: 'app/common/services/ui/components/dialogs/templates/confirmDialog.html',
            controller: 'ConfirmDialogModalController',
            controllerAs: 'vm',
            backdrop: 'static',
            resolve: {
                params: function () {
                    return {
                        params: params
                    }
                }
            }
        }).result;
    }
}

function ConfirmDialogModalController($uibModalInstance, params) {
    var vm = this;
    vm.confirm = confirm;
    vm.cancel = cancel;
    vm.data = params.params;

    function confirm() {
        params.params.confirmationAction($uibModalInstance);
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }
}
}());
