;(function() {
"use strict";

/**
 * Admin - zarządzanie zawodami.
 *
 * @Controller AdminProfessionController
 * @module admin
 * @project EWR
 */
angular.module('ewr.admin')
    .controller('AdminProfessionController', AdminProfessionController);

AdminProfessionController.$inject = ['tableDefinitions', 'formFactory', 'mgmtProfessionService'];
function AdminProfessionController(tableDefinitions, formFactory, mgmtProfessionService) {

    const vm = this;
    const formParams = {
        name: 'profession',
        title: "{0} zawód {1}",
        restService: mgmtProfessionService,
    };

    vm.search = {};

    const professionTableDefinition = tableDefinitions.getTableDefinitionAdminProfession(formParams);

    vm.listingColumnDefinitions = professionTableDefinition.listingColumnDefinitions;
    vm.tableActions = professionTableDefinition.tableActions;
    vm.mgmtProfessionService = mgmtProfessionService;

    vm.searchProfessions = function () {
        vm.customSearch = professionTableDefinition.searchByFields(vm);
    };

    vm.addProfession = function () {
        delete formParams['objId'];
        formFactory.open(formParams);
    }

}
}());
