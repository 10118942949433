;(function() {
"use strict";

/**
 * Aktualności.
 *
 * @Controller OrgNewsController
 * @module organizations
 * @project EWR
 */
(function () {
    'use strict';

    angular
        .module('ewr.organizations')
        .controller('OrgNewsController', OrgNewsController);

    OrgNewsController.$inject = ['$sce', 'PAGES', 'articleService', 'pageService', 'pagerService'];

    function OrgNewsController($sce, PAGES, articleService, pageService, pagerService) {
        var vm = this;
        pageService.getByGuid(PAGES.ORG_NEWS).then(
            function (response) {
                vm.pageTitle = response.data.title;
                vm.pageContent = $sce.trustAsHtml(response.data.content); 
            },
            function (response, status) {
            }
        );

        var params = {
            'offset': pagerService.getOffset(),
            'limit': pagerService.getLimit(),
            'sortAsc': false,
            'sortBy': 'publishDate'
        }

        vm.ds = {};
        articleService.getOrganizationNews(params).then(
            function (response) {
                vm.ds = response.data;
            },
            function (response, status) {
                // TODO
            }
        );

    }
})();
}());
