;(function() {
"use strict";

angular
    .module('ewr.common')
    .directive('appButton', appButton);

function appButton() {
    var directiveDefinitionObject = {
        restrict: 'E',
        transclude: true,
        scope: {
            type: '@',
            label: '@',
            onClick: '&',
            form: '='
        },
        templateUrl: function () {
            return 'app/common/directives/ui/components/appButton/app-button.template.html';
        },
        controller: AppButtonController,
        controllerAs: 'vm'
    };

    return directiveDefinitionObject;
}

AppButtonController.$inject = ['$scope', '$q'];
function AppButtonController($scope, $q) {
    var vm = this;
    vm.waiting = false;
    vm.label = $scope.label;
    vm.form = $scope.form;

    vm.isHidden = function() {
        return false;
    }

    vm.handleClick = function() {
        vm.waiting = true;
        $q.when($scope.onClick())
            .then(function () {
                vm.waiting = false;
            })
            .finally(function () {
                vm.waiting = false;
            });
    }

}
}());
