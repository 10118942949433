;(function() {
"use strict";

angular
    .module('ewr.common')
    .factory('formObjectObserverService', formObjectObserverService);

formObjectObserverService.$inject = [];

function formObjectObserverService() {
    var formObject = {};

    var service = {
        setFormObject: setFormObject,
        getFormObject: getFormObject
    };

    return service;

    function setFormObject(object) {
        formObject = object;
    }

    function getFormObject() {
        return formObject;
    }
}
}());
