;(function() {
"use strict";

/**
 * Moduł Egzaminów online.
 *
 * @module online
 * @project EWR
 *
 * @param $stateProvider
 */
angular
    .module('ewr.online', [], config);


config.$inject = ['$stateProvider'];

/**
 * Funkcja reprezentujaca routing w module Egzaminów online.
 *
 * @param $stateProvider
 */
function config($stateProvider) {
    $stateProvider
        .state('onlineStudent', {
            parent: 'index',
            url: '/online/lista-kandydatow/:page',
            views: {
                'content@index': {
                    templateUrl: 'app/online/templates/student/list.html',
                    controller: 'OnlineStudentController as vm'
                }
            },
            auth: false
        })
        .state('onlineSchedule', {
            parent: 'index',
            url: '/online/terminarz-egzaminow',
            views: {
                'content@index': {
                    templateUrl: 'app/online/templates/schedule/list.html',
                    controller: 'OnlineScheduleController as vm'
                }
            },
            auth: false
        })
        .state('onlineScheduleDetails', {
            parent: 'index',
            url: '/online/terminarz-egzaminow/:id',
            views: {
                'content@index': {
                    templateUrl: 'app/online/templates/schedule/details.html',
                    controller: 'OnlineScheduleDetailsController as vm'
                }
            },
            auth: true
        })
        .state('onlineScheduleExam', {
            parent: 'index',
            url: '/online/terminarz-egzaminow/:id/egzamin',
            views: {
                'content@index': {
                    templateUrl: 'app/online/templates/schedule/exam.html',
                    controller: 'ScheduleExamController as vm'
                }
            },
            auth: true
        })
        .state('onlineExamPreview', {
            parent: 'index',
            url: '/online/egzamin/:examId/student/:studentId',
            views: {
                'content@index': {
                    templateUrl: 'app/online/templates/exam/preview.html',
                    controller: 'ExamPreviewController as vm'
                }
            },
            auth: true
        });
}
}());
