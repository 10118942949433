;(function() {
"use strict";

/**
 * Moduł Zasoby.
 *
 * @module resources
 * @project EWR
 *
 * @param $stateProvider
 */
angular
    .module('ewr.resources', [], config);


config.$inject = ['$stateProvider'];


function config($stateProvider) {
    $stateProvider
        .state('multimedia', {
            parent: 'index',
            url: '/zasoby/materialy-multimedialne',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/multimedia.html',
                    controller: 'MultimediaController as vm'
                }
            },
            auth: false
        })
        .state('video', {
            parent: 'index',
            url: '/zasoby/wideo',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/video.html',
                    controller: 'VideoController as vm'
                }
            },
            auth: false
        })
        .state('photos', {
            parent: 'index',
            url: '/zasoby/fotografie',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/photos.html',
                    controller: 'PhotosController as vm'
                }
            },
            auth: false
        })
        .state('presentations', {
            parent: 'index',
            url: '/zasoby/prezentacje',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/presentations.html',
                    controller: 'PresentationsController as vm'
                }
            },
            auth: false
        })
        .state('standards', {
            parent: 'index',
            url: '/zasoby/standardy-egzaminacyjne',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/standards.html',
                    controller: 'StandardsController as vm'
                }
            },
            auth: false
        })
        .state('examSamples', {
            parent: 'index',
            url: '/zasoby/przykladowe-zadania-egzaminacyjne',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/examSamples.html',
                    controller: 'ExamSamplesController as vm'
                }
            },
            auth: false
        })
        .state('aids', {
            parent: 'index',
            url: '/zasoby/materialy-pomocnicze',
            views: {
                'content@index': {
                    templateUrl: 'app/resources/templates/aids.html',
                    controller: 'AidsController as vm'
                }
            },
            auth: false
        })
}
}());
