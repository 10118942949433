;(function() {
"use strict";

/**
 * Zestaw egzaminacyjny - dodawanie kandydata (wybór trybu egzaminu)
 *
 * @Controller OnlineScheduleStudentAddController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.online')
        .controller('OnlineScheduleStudentAddController', OnlineScheduleStudentAddController);

    OnlineScheduleStudentAddController.$inject = ['$scope', '$stateParams', '$uibModal', '$uibModalInstance', 'constants', 'onlineScheduleService', 'watchService', 'dataItem'];
    function OnlineScheduleStudentAddController($scope, $stateParams, $uibModal, $uibModalInstance, constants, onlineScheduleService, watchService, dataItem) {
        const vm = this;
        vm.collectionId = $stateParams.id;

        vm.addStudent = function () {
            const data = {
                examProcedure: vm.examProcedure,
                registerNo: vm.registerNo
            };
            onlineScheduleService.addStudent(dataItem.id, vm.collectionId, data).then(
                function (response) {
                    watchService.reload();
                },
                function (response, status) {
                    watchService.reload();
                }
            );
            $uibModalInstance.dismiss('cancel');
        };

        vm.cancel = function cancel() {
            watchService.reload();
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
}());
