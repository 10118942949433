;(function() {
"use strict";

/**
 * Dyrektywa dodająca link w sekcji "Moduły" do formularza rejestracji uczestnictwa w egzaminie online.
 *
 * @directive onlineRegister
 * @module layout
 * @project EWR
 */
angular
    .module('ewr.layout')
    .directive('onlineRegister', onlineRegister);

function onlineRegister() {
    const directive = {
        restrict: 'E',
        templateUrl: 'app/layout/directives/online/online-register.directive.html',
        controller: OnlineRegisterController,
        bindToController: true,
        controllerAs: 'vm',
        replace: true,
    };
    return directive;
}

OnlineRegisterController.$inject = ['$scope', '$state', '$location', 'onlineStudentService', 'formFactory', 'constants', '$timeout', 'Notification'];

/**
 * Controller konfiguruje oraz otwiera formularz rejestracji uczestnictwa w egzaminie online.
 *
 * @param $scope
 * @param $state
 * @param $location
 * @param onlineStudentService
 * @param formFactory
 * @param constants
 * @param $timeout
 * @param Notification
 * @constructor
 */
function OnlineRegisterController($scope, $state, $location, onlineStudentService, formFactory, constants, $timeout, Notification) {

    /**
     * Prezentuje okno z formularzem rejestracji uczestnictwa w egzaminie online.
     */
    $scope.register = function() {
        const formParams = {
            name: 'online-register',
            title: "Rejestracja uczestnictwa w egzaminie online",
            restService: onlineStudentService,
            gotoStateAfterFinish: 'home',
            customCancelAction: function (params) {
                angular.element(".modal-backdrop").hide();
                params.uibModalInstance.dismiss('cancel');
                $state.go('home');
            },
            customSubmitAction: function (params, onFailAction) {
                onlineStudentService.register(params.model.organizationId, params.model).then(
                    function() {
                        $state.go('home');
                        Notification.success("Rejestracja uczestnictwa w egzaminie online powiodła się ...");
                        angular.element(".modal-backdrop").hide();
                        params.uibModalInstance.dismiss('cancel');
                    }, function () {
                        if (onFailAction) {
                            onFailAction();
                        }
                        Notification.error("Wystąpił problem podczas rejestracji uczestnictwa w egzaminie online ...");
                    }
                );
            },
            dictName: constants.FORM_DICT_ONLINE
        };

        $timeout(function() {
            angular.element(".modal-backdrop").show();
            formFactory.open(formParams);
        },500);

    }

}
}());
