;(function() {
"use strict";

/**
 * Zestaw testowy - dodaj zadanie do zestawu
 *
 * @Controller TestAddTaskController
 * @module exams
 * @project EWR
 */

(function () {
    'use strict';

    angular
        .module('ewr.exams')
        .controller('TestAddTaskController', TestAddTaskController);

    TestAddTaskController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'testService', 'tableDefinitions', 'watchService'];
    function TestAddTaskController($scope, $uibModalInstance, Notification, testService, tableDefinitions, watchService) {
        const vm = this;
        const formParams = {
            restService: testService
        };
        vm.dictionary = {};
        vm.collectionId = $scope.collectionId;
        vm.searchFields = {};
        vm.selectedRows = [];

        testService.getTaskFilter().then(
            function (response) {
                var dictionaries = response.data;
                angular.forEach(dictionaries, function (dictionary) {
                    if (dictionary.name === 'profession') {
                        vm.dictionary.professions = dictionary.items;
                        vm.dictionary.professions.unshift({name: 'Temat ogólny', value: '0'})
                    }
                    if (dictionary.name === 'examType') {
                        vm.dictionary.examTypes = dictionary.items;
                    }
                    if (dictionary.name === 'examMode') {
                        vm.dictionary.examModes = dictionary.items;
                    }
                    if (dictionary.name === 'taskLevel') {
                        vm.dictionary.taskLevels = dictionary.items;
                    }
                    if (dictionary.name === 'topic') {
                        vm.dictionary.topics = dictionary.items;
                    }
                });
            },
            function (response, status) {
                // TODO
            }
        );

        var taskTableDefinition = tableDefinitions.getTableDefinitionMgmtCollectionTask(formParams);

        vm.listingColumnDefinitions = taskTableDefinition.listingColumnDefinitions;
        vm.tableActions = taskTableDefinition.tableActions;
        vm.testService = testService;

        vm.search = function () {
            vm.customSearch = taskTableDefinition.searchByFields(vm);
        };

        /**
         * Akcja zamknięcia okna formularza bez zapisu danych formularza.
         */
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Akcja zapisu danych
         */
        vm.save = function() {
            if (vm.selectedRows.length > 0) {

                var data = {
                    taskIds: vm.selectedRows
                }

                testService.assignTasks(vm.collectionId, data).then(
                    function (response) {
                        $uibModalInstance.close();
                        Notification.success("Przypisano zadania do zestawu");
                        watchService.reload();
                    },
                    function (response, status) {
                        Notification.error("Wystąpił problem podczas zapisu ....");
                    }
                );
            }

        }
    }

})();
}());
