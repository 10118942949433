;(function() {
"use strict";

/**
 * Serwis obsługujący formularze.
 *
 * @service formFactory
 * @module common
 * @project EWR
 */

angular
    .module('ewr.common')
    .factory('formFactory', formFactory)
    .controller('FormModalInstanceController', FormModalInstanceController);

/**
 * Fabryka instancji formularza.
 * Inicjalizuje formularz dla wskazanego klucza.
 *
 * @param formService
 * @param $uibModal
 * @returns {{get: get, open: open, setRestService: setRestService}}
 */
formFactory.$inject = ['formService', '$uibModal', 'formDictionaryService', 'constants', 'formValidators', 'formExpressionProperties'];
function formFactory(formService, $uibModal, formDictionaryService, constants, formValidators, formExpressionProperties) {

    const dicts = [];

    const service = {
        appendFieldValidators: appendFieldValidators,
        appendFieldPropertyExpressions: appendFieldPropertyExpressions,
        appendFieldOnClickExpression: appendFieldOnClickExpression,
        appendFieldOnChangeExpression: appendFieldOnChangeExpression,
        appendSelectOptions: appendSelectOptions,
        mergeDictWithExtraValues: mergeDictWithExtraValues,
        get: get,
        open: open
    };

    return service;

    /**
     * Aktualizacja walidatorów pól formularza.
     * @param formData
     */
    function appendFieldValidators(fields) {
        angular.forEach(fields, function (object) {
            if("required" in object.templateOptions) {
                object.templateOptions.required = angular.fromJson(object.templateOptions.required);
            }

            if("disabled" in object.templateOptions) {
                object.templateOptions.disabled = angular.fromJson(object.templateOptions.disabled);
            }

            angular.forEach(object.validators, function (validator) {
                validator.expression = formValidators.get(validator.expression);
            });

            angular.forEach(object.asyncValidators, function (validator) {
                validator.expression = formValidators.get(validator.expression);
            });
        });
    }

    /**
     *
     * @param formData
     */
    function appendFieldPropertyExpressions(fields) {
        angular.forEach(fields, function (field) {
            field.expressionProperties = formExpressionProperties.get(field.expressionProperties);
        });
    }

    /**
     *
     * @param formData
     */
    function appendFieldOnClickExpression(fields) {
        angular.forEach(fields, function (field) {
            field.templateOptions.onClick = formExpressionProperties.get(field.templateOptions.onClick);
        });
    }

    /**
     *
     * @param formData
     */
    function appendFieldOnChangeExpression(fields) {
        angular.forEach(fields, function (field) {
            field.templateOptions.onChange = formExpressionProperties.get(field.templateOptions.onChange);
        });
    }

    /**
     *
     * @param formData
     */
    function appendSelectOptions(formData) {

        angular.forEach(formData.dict, function (dict) {
            dicts.push(dict);
        });

        angular.forEach(formData.fields, function (object) {
            if (object.type === "selectfile" || object.type === "select" || object.type === "checkbox" || object.type === "multiCheckbox") {
                if (formData.dict) {
                    angular.forEach(formData.dict, function (dict) {
                        if ("dict" in object.templateOptions) {
                            if (object.templateOptions.dict === dict.name) {
                                object.templateOptions.options = dict.items;
                            }
                        } else if (object.key === dict.name) {
                            object.templateOptions.options = dict.items;
                        }

                    });
                }
            }
            if (object.type === "select") {
                if ("emptyLabel" in object.templateOptions) {
                    object.templateOptions.options.unshift({
                        code: null,
                        name: object.templateOptions.emptyLabel,
                        value: object.defaultValue
                    });
                }
            }

        });
    }

    function mergeDictWithExtraValues(dict, extraValues) {
        var mergedDict = dict;
        angular.forEach(extraValues, function (extraValue, key) {
            angular.forEach(dict, function(object) {
                if(object.name === key) {
                    angular.forEach(extraValue, function (item) {
                        object.items.unshift(item);
                    });
                }
            });
        });
        dict = mergedDict;
    }

    /**
     * Pobranie definicji wskazanego formularza.
     *
     * @param name
     * @returns {*}
     */
    function get(name) {
        return formService.get(name);
    }

    /**
     * Uruchomienie instncji wskazanego formularza.
     * @param formName
     * @param objId
     */
    function open(params, dictName, size) {
        var objId = undefined;
        if('objId' in params) {
            objId = params.objId;
        }

        if('size' in params) {
            size = params.size;
        } else {
            size = constants.MODAL_SIZE_LARGE;
        }
        var fieldsTranform = null;
        if('fieldsTranform' in params) {
            fieldsTranform = params.fieldsTranform;
        }

        var submitButtonLabel = 'Zapisz';
        if('submitButtonLabel' in params) {
            submitButtonLabel = params.submitButtonLabel;
        }

        var gotoStateAfterFinish;
        if('gotoStateAfterFinish' in params) {
            gotoStateAfterFinish = params.gotoStateAfterFinish;
        }

        var onSaveErrorCallback;
        if('onSaveErrorCallback' in params) {
            onSaveErrorCallback = params.onSaveErrorCallback;
        }

        var customCancelAction;
        if('customCancelAction' in params) {
            customCancelAction = params.customCancelAction;
        }

        var showCancelButton = true ;
        if('showCancelButton' in params) {
            showCancelButton = params.showCancelButton;
        }

        var defaultExtraAction;
        if('defaultExtraAction' in params) {
            defaultExtraAction = params.defaultExtraAction;
        }

        if('dictName' in params) {

            formDictionaryService.getDict(params.dictName).then(function(response) {
                var dict = response.data;
                if('extraDictValue' in params) {
                    mergeDictWithExtraValues(dict, params.extraDictValue);
                }
                openForm(dict);

            });
        } else {
            openForm(null);
        }

        function openForm(dict) {
            var result = $uibModal.open({
                templateUrl: 'app/common/services/forms/templates/formModalInstanceController.html',
                controller: 'FormModalInstanceController',
                controllerAs: 'vm',
                size: size,
                backdrop: 'static',
                keyboard  : false,
                resolve: {
                    formData: function () {
                        return {
                            objId: objId,
                            model: params.model !== undefined ? params.model : null,
                            formName: params.name,
                            restService: params.restService,
                            callApi: params.apiCall,
                            title: params.title,
                            customSubmitAction: params.customSubmitAction,
                            defaultExtraAction: params.defaultExtraAction,
                            showCancelButton: showCancelButton,
                            customCancelAction: customCancelAction,
                            submitButtonLabel: submitButtonLabel,
                            dict: dict,
                            dicts: dicts,
                            fieldsTranform: fieldsTranform,
                            gotoStateAfterFinish: gotoStateAfterFinish,
                            onSaveErrorCallback: onSaveErrorCallback
                        }
                    }
                }
            }).result;
        }

    }
}

/**
 * Kontroler obsługujacy instancję okna formularza.
 *
 * @param $uibModalInstance
 * @param formData
 * @param formFactory
 * @param formValidators
 * @constructor
 */
FormModalInstanceController.$inject = ['$scope', '$state', '$uibModalInstance', 'formData', 'formFactory', 'constants', 'watchService', 'Notification', 'formObjectObserverService', 'date'];
function FormModalInstanceController($scope, $state, $uibModalInstance, formData, formFactory, constants, watchService, Notification, formObjectObserverService, dateService) {
    var vm = this;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.form = {};
    vm.formData = formData;
    vm.showCancelButton = formData.showCancelButton;


    $scope.$watch(function(){
        return vm.form;
    }, function(forlmyForm){
        forlmyForm["dicts"] = formData.dicts;
    });

    /**
     * Akcja potwierdzenia formularza.
     */
    function submit() {
        vm.form.$submitted = true;
        vm.formData.model = convertDataModelOnSave(vm.formData.model);
        if (formData.customSubmitAction !== undefined) {
            const params = {
                model: vm.formData.model,
                uibModalInstance: $uibModalInstance
            };
            const onFailAction = function () {
                vm.formData.model = convertDataModelOnInit(vm.formData.model);
            }
            return formData.customSubmitAction(params, onFailAction);
        } else {
            var onSaveErrorCallback = function (response, Notification) {
                Notification.error("Wystąpił problem podczas zapisu ....");
            };
            if (onSaveErrorCallback) {
                onSaveErrorCallback = formData.onSaveErrorCallback;
            }
            appendFieldsTransformationsOnSave(formData);
            return vm.formData.restService.save(vm.formData.model).then(function () {
                watchService.reload();
                Notification.success("Zapisano poprawnie ...");
                $uibModalInstance.close(vm.formData.model);
                if (formData.gotoStateAfterFinish) {
                    $state.go(formData.gotoStateAfterFinish);
                }
                if(formData.defaultExtraAction !== undefined) {
                    formData.defaultExtraAction();
                }
            }).catch(function (response) {
                onSaveErrorCallback(response, Notification)
            });
        }
    }

    /**
     * Akcja zamknięcia okna formularza bez zapisu danych formularza.
     */
    function cancel() {
        if (formData.customCancelAction !== undefined) {
            const  params = {
                model: vm.formData.model,
                uibModalInstance: $uibModalInstance
            };
            return formData.customCancelAction(params);
        } else {
            vm.formData.options.resetModel();
            $uibModalInstance.dismiss('cancel');
            if (formData.gotoStateAfterFinish) {
                $state.go(formData.gotoStateAfterFinish);
            }
        }
    }

    /**
     * Pobranie definicji oraz modelu danych formularza oraz danych.
     */
    formFactory.get(formData.formName).then(function (data) {
        if (formData.objId !== undefined) {
            var apiCall;
            if (formData.callApi !== undefined) {
                apiCall = formData.restService[formData.callApi];
            } else {
                apiCall = formData.restService['get'];
            }

            apiCall(formData.objId).then(function (object) {
                vm.formData.model = convertDataModelOnInit(object.data);
                appendFieldsTransformationsOnRead(formData);

                formObjectObserverService.setFormObject(object.data);
            });
        }
        var fields = data.data;

        formFactory.appendFieldValidators(fields);
        formFactory.appendFieldPropertyExpressions(fields);
        formFactory.appendFieldOnClickExpression(fields);
        formFactory.appendFieldOnChangeExpression(fields);

        vm.formData.fields = fields;

        formFactory.appendSelectOptions(vm.formData);
        prepareTitle();
    });

    /**
     *
     */
    function prepareTitle() {
        var title = formData.title;
        if(formData.objId) {
            title = title.format("Edytuj", formData.objId);
        } else {
            title = title.format("Dodaj", "");
        }
        vm.formData.title = title;
    }

    function convertDataModelOnInit(data) {
        var model = {};

        angular.forEach(data, function (value, key) {
            if (dateService.isDate(value)) {
                // convert string to date
                model[key] = dateService.convertToDate(value);
            } else {
                model[key] = value;
            }
        });
        return model;
    }

    function convertDataModelOnSave(data) {
        var model = {};

        angular.forEach(data, function(value, key) {
            // convert date fields to string
            if(angular.isDate(value)) {
                model[key] = dateService.convertToFormatYYYYMMDD(value);
            } else {
                model[key] = value;
            }
        });

        return model;
    }

    function appendFieldsTransformationsOnRead(params) {
        if(formData.fieldsTranform !== null) {
            angular.forEach(vm.formData.model, function (field, key) {
                angular.forEach(params.fieldsTranform.onRead, function(object) {
                    if(key in object) {
                        vm.formData.model[key] = object[key](field);
                    }
                });

            });
        }
    }

    function appendFieldsTransformationsOnSave(params) {
        if(formData.fieldsTranform !== null) {
            angular.forEach(vm.formData.model, function (field, key) {
                angular.forEach(params.fieldsTranform.onSave, function(object) {
                    if(key in object) {
                        vm.formData.model[key] = object[key](field, key, vm.formData);
                    }
                });

            });
        }
    }
}
}());
